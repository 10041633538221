import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Helmet } from "react-helmet";
import Footer from './Footer';
import { Box, Divider, Grid, LinearProgress, Typography, Container } from '@mui/material'
import { TranslateContext } from '../../../context/TranslateContext';
import Header from './Header';

const PageComp = (props) => {
    const slug = props.match.params.slug
    const [page, setPage] = React.useState("")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)

    async function getPage() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/get-page-by-slug",
            post: true,
            admin: true,
            obj: { slug: slug }
        })
        console.log(resp.data)
        if (resp.data.success) {
            setPage(resp.data.data)
        } else {
            setPage("nopage")
        }
    }

    React.useEffect(() => {
        if (!slug) {
            return
        }
        getPage()
    }, [slug])

    return (
        <Box>
            {page ?
                page === 'nopage' ?
                    <Grid container minHeight={'100vh'} alignItems='center' justifyContent={'center'}>
                        <Grid item>
                            <Typography>No Page Found</Typography>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Header />
                        <Container sx={{ minHeight: '70vh', pt: 10 }} maxWidth='lg'>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title >{page.title}</title>
                                <meta name="description" content={page.meta} />
                                <link rel="canonical" href={window.location.hostname} />
                            </Helmet>
                            <Box  >
                                <Box p={2}>
                                    <Typography sx={{ mb: 2 }} fontWeight={'bold'} align='center' variant='h3' >{page.title}</Typography>
                                    <Divider variant='middle' />
                                </Box>
                                <Typography
                                    sx={{ display: "inline-block" }}
                                    variant="body1"
                                    dangerouslySetInnerHTML={{
                                        __html: page.content
                                    }}
                                ></Typography>
                            </Box>
                        </Container>
                    </>
                : <LinearProgress />}

            <Footer data={data} />
        </Box>
    )
}

export default PageComp