import { ExpandMore, Facebook, SmartToy, Telegram, WhatsApp } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Skeleton, Typography, Container } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import GetAllFaq from '../../../utils/GetAllFaq'
import GetWebPublic from '../../../utils/GetWebPublic'

const FaQ = ({ data }) => {
    const { allFaq } = GetAllFaq()
    const { web } = GetWebPublic()

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    return (
        <Box pt={6} pb={6}
            sx={{ backgroundImage: 'url("/assets/bg2.png")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Container maxWidth='lg'>
                <Grid justifyContent={'center'} container >
                    <Grid item xs={12} lg={11} sm={10}>
                        <Stack direction={'column'} spacing={1} >
                            <Typography align='center' variant='h3'>{data.faq_title}</Typography>
                            <Typography color={'gray'} align='center'>{data.faq_des}</Typography>
                        </Stack>

                        <Stack mt={6} direction={'column'} spacing={2}>
                            {allFaq ? allFaq.map((i, key) => {
                                return (
                                    <Accordion sx={{ borderRadius: 4, padding: 2, bgcolor: (t) => convertHexToRgba(t.palette.primary.main, 1) }} >
                                        <AccordionSummary sx={{ borderRadius: 5 }} expandIcon={<ExpandMore />}>
                                            <Typography fontWeight={'bold'} >{i.que}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant='body2' >{i.ans}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }) :
                                <>
                                    <Skeleton height={50} width={'100%'} />

                                    <Skeleton height={50} width={'100%'} />

                                    <Skeleton height={50} width={'100%'} />

                                    <Skeleton height={50} width={'100%'} />
                                </>
                            }
                        </Stack>

                        <Stack alignItems={'center'} mt={4} >
                            <Grid container justifyContent={'center'} spacing={2}>
                                <Grid item>
                                    {web.whatsapp_status === 1 &&
                                        <Button
                                            variant='outlined'
                                            startIcon={<SmartToy />}
                                            onClick={() => window.open(web.wa_bot_link)}
                                            endIcon={<WhatsApp />} size='large' sx={{ textTransform: 'none', fontWeight: 'bold', borderColor: "#075E54", color: "white" }} >{data.start_chat_btn_text}</Button>}

                                </Grid>
                                <Grid item>
                                    {web.telegram_status === 1 &&
                                        <Button
                                            startIcon={<SmartToy />}
                                            onClick={() => window.open(web.tele_bot_link)}
                                            endIcon={<Telegram />} variant='outlined' size='large' sx={{ textTransform: 'none', fontWeight: 'bold', borderColor: "#0088cc", color: "white" }} >{data.start_chat_btn_text}</Button>}

                                </Grid>
                                <Grid item>
                                    {web.facebook_status === 1 &&
                                        <Button
                                            startIcon={<SmartToy />}
                                            onClick={() => window.open(web.fb_bot_link)}
                                            endIcon={<Facebook />} variant='outlined' size='large' sx={{ textTransform: 'none', fontWeight: 'bold', borderColor: '#3b5998', color: "white" }} >{data.start_chat_btn_text}</Button>}

                                </Grid>
                            </Grid>

                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default FaQ