import React from 'react'
import { Box } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import HeroSection from './components/HeroSection'
import Header from './components/Header'
import Features from './components/Features'
import BulletPoints from './components/BulletPoints'
import FaQ from './components/FaQ'
import PriceComp from './components/PriceComp'
import Footer from './components/Footer'
import EmbedChatBot from './components/EmbedChatBot'


const HomePage = () => {
    const { data } = React.useContext(TranslateContext)
    return (
        <Box sx={{ minHeight: '100vh' }} >
            <Header data={data} />
            <HeroSection data={data} />
            <Features data={data} />
            <EmbedChatBot data={data} />
            <BulletPoints data={data} />
            <FaQ data={data} />
            <PriceComp data={data} />
            <Footer data={data} />
        </Box>
    )
}

export default HomePage