import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './ChatBubble.css';
import { Box, Stack } from '@mui/material';
import { Face, Group, SmartToy } from '@mui/icons-material';

const ChatBubble = ({ content, role }) => {
    const bubbleClassName = role === 'user' ? 'chat-bubble user' : 'chat-bubble assistant';
    const bubbleRef = useRef(null);

    function extractQuestionValue(text) {
        const regex = /question is: "(.*?)"/;
        const match = text.match(regex);

        if (match && match.length > 1) {
            return match[1]; // Extracted question value
        }

        return text;
    }

    useEffect(() => {
        bubbleRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (
        <div className={bubbleClassName} ref={bubbleRef}>
            <Stack direction={'row'}>
                <Box >
                    {role === 'user' ? <Face sx={{ height: 20, width: 20, opacity: 0.5 }} /> : <SmartToy sx={{ height: 20, width: 20, opacity: 0.5 }} />}
                </Box>
                <div className="message-container">
                    <p className="message">{role === 'user' ? extractQuestionValue(content) : content}</p>
                </div>
            </Stack>
        </div>
    );
};

ChatBubble.propTypes = {
    content: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['user', 'assistant']).isRequired,
};

export default ChatBubble;
