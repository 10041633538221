import React from 'react'
import { Avatar, Box, Button, CardActionArea, Dialog, Grid, Stack, IconButton, Divider, Tab, Tabs, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { ChatTempletContext } from '../context/ChatTempletContext'
import AddNewCateDialog from './AddNewCateDialog'
import { GlobalContext } from '../../../context/GlobalContext'
import PropTypes from 'prop-types';
import { Close, Delete, DeleteOutline } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const Header = ({ data }) => {
    const CON = React.useContext(ChatTempletContext)
    const G = React.useContext(GlobalContext)
    const [dialog, setDialog] = React.useState({})
    const [state, setState] = React.useState({})
    const [bots, setBots] = React.useState([])

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function getCateg() {
        const res = await G.hitAxios({
            path: "/api/chatbot/get_all_category",
            post: false,
            admin: true,
            token_user: ""
        })
        if (res.data.success) {
            CON.setData({ ...CON.data, categoryData: res.data.data, categoryDialog: false })

            if (res.data.data?.length > 0) {
                getBotById(res.data.data[[res.data.data.length - 1]].id)
            }
        }
    }

    React.useEffect(() => {
        getCateg()
    }, [])

    async function addBot() {
        const res = await G.hitAxios({
            path: "/api/chatbot/add_chatbot_templet",
            admin: true,
            post: true,
            obj: state
        })
        if (res.data.success) {
            setDialog({ dialog, status: false })
        }
    }

    async function delCate(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/chatbot/del_category",
                post: true,
                admin: true,
                obj: { id: e }
            })
            if (res.data.success) {
                getCateg()
            }
        }
    }

    async function getBotById(e) {
        const res = await G.hitAxios({
            path: "/api/chatbot/get_cahtbot_by_category",
            post: true,
            admin: false,
            obj: { categoryId: e }
        })
        if (res.data.success) {
            setBots(res.data.data)
        }
    }

    function getColorCode(text) {
        // Generate a hash code based on the text
        let hash = 0;
        for (let i = 0; i < text.length; i++) {
            hash = text.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Convert the hash code to a positive integer
        hash = Math.abs(hash);

        // Generate the color code by taking a portion of the hash value
        const colorCode = '#' + ('00000' + (hash % 0x1000000).toString(16)).slice(-6);

        return colorCode;
    }

    async function delBot(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/chatbot/del_chatbot",
                post: true,
                admin: true,
                obj: { id: e }
            })
            if (res.data.success) {
                getCateg()
            }
        }
    }

    return (

        <div>
            <AddNewCateDialog CON={CON} G={G} data={data} />
            <Stack mt={4} alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.chatBotTemp}</Typography>
                <Button
                    onClick={() => CON.setData({ ...CON.data, categoryDialog: true })}
                    variant='contained'
                    sx={{ borderRadius: 2, textTransform: 'none' }}>{data.addNewCategory}</Button>
            </Stack>

            <Box borderRadius={4} bgcolor={'action.hover'} mt={4}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {[...CON.data.categoryData].reverse()?.map((i, key) => {
                            return (
                                <Tab
                                    onClick={() => getBotById(i.id)}
                                    key={key} sx={{ textTransform: 'none', fontWeight: 'bold' }} label={<Stack direction={'row'} alignItems={'center'}>
                                        <Typography variant='body2' >{i.name}</Typography>
                                        <IconButton
                                            onClick={() => delCate(i.id)}
                                            color='error' >
                                            <Delete sx={{ height: 20, width: 20 }} />
                                        </IconButton>
                                    </Stack>} />
                            )
                        })}
                    </Tabs>
                </Box>

                <Dialog
                    fullWidth
                    onClose={(e) => setDialog({ ...dialog, status: false })}
                    open={dialog.status}
                    PaperProps={{
                        style: {
                            borderRadius: 10
                        }
                    }} >
                    <Box p={2}  >
                        <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                            <IconButton onClick={() => setDialog({ ...dialog, status: false })} >
                                <Close />
                            </IconButton>
                            <Typography>{data.addNewBot}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction={'column'} mt={2} spacing={2}>
                            <TextField
                                onChange={(e) => setState({ ...state, title: e.target.value })}
                                placeholder={data.amazonExpert}
                                label={data.AiBotTitle} fullWidth size='small' InputProps={{ style: { borderRadius: 10 } }} />

                            <FormControl size='small' fullWidth>
                                <InputLabel id="demo-simple-select-label">{data.selectCate}</InputLabel>
                                <Select
                                    sx={{ borderRadius: 3 }}
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // value=
                                    label={data.selectCate}
                                // onChange={handleChange}
                                >
                                    {[...CON.data.categoryData].reverse()?.map((i, key) => {
                                        return (
                                            <MenuItem
                                                onClick={() => setState({ ...state, categoryId: i.id })}
                                                key={key} value={i.name}>{i.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <TextField
                                onChange={(e) => setState({ ...state, trainData: e.target.value })}
                                multiline rows={3}
                                placeholder={data.trainDataHelper}
                                label={data.trainData} fullWidth size='small' InputProps={{ style: { borderRadius: 10 } }} />

                            <LoadingButton
                                onClick={addBot}
                                loading={CON.data.loading}
                                disabled={state.title && state.categoryId && state.trainData ? false : true}
                                variant='contained'
                                sx={{ textTransform: 'none', borderRadius: 2 }}
                            >{data.submit}</LoadingButton>

                        </Stack>

                    </Box>
                </Dialog>

                <Box p={3} >
                    <Grid alignItems={'center'} container spacing={2} >
                        <Grid item xs={12} sm={4} lg={3}>
                            <Box height={'100%'}>
                                <CardActionArea
                                    onClick={() => setDialog({ ...dialog, status: true })}
                                    sx={{ borderRadius: 4, p: 1, height: "100%" }} >
                                    <Stack alignItems={'center'}>
                                        <Stack p={4} alignItems={'center'} justifyContent={'center'}>
                                            <Avatar sx={{ bgcolor: (t) => t.palette.primary.main, height: 50, width: 50 }} >
                                                {data.addNewBot?.slice(0, 1)}
                                            </Avatar>
                                        </Stack>
                                        <Typography fontWeight={'bold'} align='center' >{data.addNewBot}</Typography>
                                    </Stack>
                                </CardActionArea>
                            </Box>
                        </Grid>

                        {bots.length > 0 && bots?.map((i, key) => {
                            return (
                                <Grid key={key} item xs={12} sm={4} lg={3}>
                                    <Box height={'100%'}>
                                        <Box
                                            sx={{ borderRadius: 4, p: 1, bgcolor: 'action.hover', height: "100%" }} >
                                            <Stack alignItems={'center'}>
                                                <Stack p={4} alignItems={'center'} justifyContent={'center'}>
                                                    <Avatar sx={{ bgcolor: (t) => getColorCode(i.title), height: 50, width: 50 }} >
                                                        {i.title?.slice(0, 1)}
                                                    </Avatar>
                                                </Stack>
                                                <Stack direction={'column'} spacing={1} alignItems={'center'}>

                                                    <Typography align='center' >{i.title}</Typography>
                                                    <Stack direction={'row'} spacing={2}>
                                                        <IconButton
                                                            onClick={() => delBot(i.id)}
                                                            color='error' >
                                                            <DeleteOutline />
                                                        </IconButton>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>
        </div >
    )
}

export default Header