import { Box, CardActionArea, CircularProgress, Container, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
// import Header from './components/Header'
import { TranslateContext } from '../../context/TranslateContext'
import PaymentList from './components/PaymentList'
import { useHistory } from 'react-router-dom'
import GetAllPaymentMethod from '../../utils/GetAllPaymentMethod'
import GetWebPublic from '../../utils/GetWebPublic'
import { GlobalContext } from '../../context/GlobalContext'
import { ArrowBack } from '@mui/icons-material'
import { CheckOutProvider } from './context/CheckOutContext'

const Checkout = (props) => {
    const { data } = React.useContext(TranslateContext)
    const history = useHistory()
    const plan = props.location.state;
    const { paymentGateways } = GetAllPaymentMethod()
    const { web } = GetWebPublic()
    const G = React.useContext(GlobalContext)

    async function getFree(e) {
        const res = await G.hitAxios({
            path: "/api/plan/pay-free",
            post: true,
            admin: false,
            obj: { plan_id: e }
        })
        if (res.data.success) {
            alert("bravo")
        }
    }
    return (

        <CheckOutProvider>
            {plan?.id ?
                <Container maxWidth='lg'>
                    <Stack pt={4} mb={-8} direction={'row'}>
                        <IconButton onClick={() => history.goBack()} >
                            <ArrowBack />
                        </IconButton>
                    </Stack>
                    <Grid container minHeight={'100vh'} alignItems={'center'} justifyContent={'center'}>
                        <Grid item>
                            {paymentGateways && web ?
                                plan.price == 0 ?
                                    <Stack direction={'column'} spacing={4}>
                                        <CardActionArea onClick={() => getFree(plan.id)}>
                                            <Box border={1} p={2} bgcolor={'white'} borderRadius={2} >
                                                <Typography align='center' fontWeight={'bold'} color={'black'} >Get {plan.name} plan Free</Typography>
                                            </Box>
                                        </CardActionArea>
                                    </Stack> :
                                    <Stack direction={'column'} spacing={4}>
                                        {/* <Header web={web} plan={plan} data={data} /> */}
                                        <PaymentList web={web} paymentGateways={paymentGateways} plan={plan} data={data} />
                                    </Stack>
                                :
                                <CircularProgress />}
                        </Grid>
                    </Grid>
                </Container> : history.push("/")}
        </CheckOutProvider>
    )
}

export default Checkout