import React from 'react'
import { Box, Stack, Typography, Grid, Button, Dialog, IconButton } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { GlobalContext } from '../../context/GlobalContext'
import GetWebPublic from '../../utils/GetWebPublic'
import moment from 'moment'
import { Delete } from '@mui/icons-material'

const Orders = () => {
    const { data } = React.useContext(TranslateContext)
    const G = React.useContext(GlobalContext)
    const [orders, setOrders] = React.useState([])
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const { web } = GetWebPublic()
    const [dialog, setDialog] = React.useState(false)
    const [user, setUser] = React.useState({})

    async function returnUserName(e) {
        try {
            const res = await G.hitAxios({
                path: "/api/admin/get-user-by-uid",
                post: true,
                admin: true,
                obj: { uid: e }
            });

            if (res.data.success) {
                setUser(res.data.data)
            }
        } catch (error) {
            console.error(error);
            return "NA";
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Grid container justifyContent={'center'} spacing={2}>
                        <Grid item>
                            <GridToolbarExport />
                        </Grid>
                    </Grid>
                </Box>
            </GridToolbarContainer>
        );
    }

    async function delOrder(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/admin/del-order",
                admin: true,
                post: true,
                obj: { id: e }
            })

            if (res.data.success) {
                getData(res.data.data)
            }
        }
    }

    async function getData() {
        const res = await G.hitAxios({
            path: "/api/admin/get-all-orders",
            admin: true,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            setOrders(res.data.data)
        }
    }
    React.useEffect(() => {
        if (!token) return
        getData()
    }, [token])

    return (
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.allOrders}</Typography>
            </Stack>

            <Dialog PaperProps={{
                style: { borderRadius: 10 }
            }} fullWidth open={dialog} onClose={() => setDialog(false)}>
                <Box p={4}>
                    <Stack direction={'column'}>
                        <Typography>Name: {user.name}</Typography>
                        <Typography>Email: {user.email}</Typography>
                    </Stack>
                </Box>
            </Dialog>

            <Box mt={4}>
                <Box borderRadius={4} bgcolor={'action.hover'} >
                    {orders && web ?
                        <Box height='80vh'>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={[...orders].reverse()}
                                columns={[
                                    { headerName: data.ID, field: 'id', flex: 1 },
                                    { headerName: data.paymentMode, field: 'payment_mode', flex: 1 },
                                    { headerName: data.amount, field: 'amount', flex: 1, renderCell: data => `${web.currency_symbol}${data.row.amount}` },
                                    { headerName: data.date, field: 'createdAt', flex: 1, renderCell: data => moment(data.row.createdAt).format("DD MMMM YYYY hh:mmA") },
                                    {
                                        headerName: data.name, field: 'uid', flex: 1, renderCell: dataa => <Button
                                            onClick={() => {
                                                returnUserName(dataa.row.uid)
                                                setDialog(true)
                                            }}
                                            size='small'>{data.viewUser}</Button>
                                    },
                                    {
                                        headerName: data.delete, field: 'del', flex: 1, renderCell: dataa => <IconButton
                                            onClick={() => delOrder(dataa.row.id)}
                                            color='error'>
                                            <Delete />
                                        </IconButton>
                                    },
                                ]}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection={false}
                                components={{
                                    Toolbar: CustomToolbar
                                }}
                                sx={{
                                    boxShadow: 0,
                                    borderRadius: 4,
                                    border: 0,
                                    fontSize: 13,
                                    padding: 2
                                }}
                            />
                        </Box> : ""
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Orders