import React from 'react'
import { Box, Stack, Typography, Grid, IconButton, Dialog, Divider, TextField, Button } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext';
import { Close, Delete, Visibility } from '@mui/icons-material';

const PingAdmin = () => {
    const { data } = React.useContext(TranslateContext)
    const [ping, setPing] = React.useState([])
    const G = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const [dialog, setDialog] = React.useState(false)
    const [state, setState] = React.useState({
        dialogData: {}
    })

    async function replyMsg() {
        const res = await G.hitAxios({
            path: "/api/admin/reply-ping",
            admin: true,
            post: true,
            obj: { id: state.dialogData?.id, admin_reply: state.admin_reply }
        })
        if (res.data.success) {
            getPings()
            setDialog(false)
        }
    }

    async function getPings() {
        const res = await G.hitAxios({
            path: "/api/admin/get-all-pings",
            admin: true,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            setPing(res.data.data)
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item>
                            <Typography variant='h5' >{data.allPings}</Typography>
                        </Grid>
                        <Grid item>
                            <GridToolbarExport />
                        </Grid>
                    </Grid>
                </Box>
            </GridToolbarContainer>
        );
    }

    async function delPing(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/admin/del-ping",
                admin: true,
                post: true,
                obj: { id: e }
            })

            if (res.data.success) {
                getPings(res.data.data)
            }
        }
    }

    React.useEffect(() => {
        if (!token) return
        getPings()
    }, [token])

    return (
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.ping}</Typography>
            </Stack>

            <Dialog
                fullWidth
                onClose={(e) => setDialog(false)}
                open={dialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => setDialog(false)} >
                            <Close />
                        </IconButton>
                        <Typography>{data.sendNew}</Typography>
                    </Stack>
                    <Divider />
                    <Stack mt={2} direction={'column'} spacing={2}>
                        <Typography>{state.dialogData?.user_msg}</Typography>
                        <TextField
                            onChange={(e) => setState({ ...state, admin_reply: e.target.value })}
                            multiline
                            rows={3}
                            label={data.enterYourReply} fullWidth size='small' InputProps={{ style: { borderRadius: 10 } }} />

                        <Button
                            onClick={replyMsg}
                            disabled={state.admin_reply && state.dialogData.id ? false : true}
                            variant='contained'
                            sx={{ borderRadius: 2, textTransform: 'none', boxShadow: 0 }} >{data.submit}</Button>
                    </Stack>
                </Box>
            </Dialog>

            <Box mt={4}>
                <Box borderRadius={4} bgcolor={'action.hover'} >
                    {ping ?
                        <Box height='80vh'>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={[...ping].reverse()}
                                columns={[
                                    { headerName: data.ID, field: 'id', flex: 1 },
                                    { headerName: data.msg, field: 'user_msg', flex: 1 },
                                    {
                                        headerName: data.reply, field: 'admin_reply', flex: 1, renderCell: dataa => dataa.row.admin_reply ? <Box border={1}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => alert(dataa.row.admin_reply)}
                                            borderColor={'rgba(11, 255, 0, 1)'} bgcolor={'rgba(11, 255, 0, 0.27)'} p={0.5} borderRadius={4}>
                                            <Typography variant='caption'>{data.replied}</Typography>
                                        </Box>
                                            : <Box border={1} borderColor={'rgba(255, 202, 0, 1)'} bgcolor={'rgba(255, 202, 0, 0.24)'} p={0.5} borderRadius={4}>
                                                <Typography variant='caption'>{data.pending}</Typography>
                                            </Box>
                                    },
                                    {
                                        headerName: data.view, field: 'reply', flex: 1, renderCell: dataa => <IconButton
                                            onClick={() => {
                                                setState({ ...state, dialogData: dataa.row })
                                                setDialog(true)
                                            }}
                                        >
                                            <Visibility />
                                        </IconButton>
                                    },
                                    {
                                        headerName: data.del, field: 'del', flex: 1, renderCell: dataa => <IconButton
                                            onClick={() => delPing(dataa.row.id)}
                                            color='error'>
                                            <Delete />
                                        </IconButton>
                                    },
                                ]}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection={false}
                                components={{
                                    Toolbar: CustomToolbar
                                }}
                                sx={{
                                    boxShadow: 0,
                                    borderRadius: 4,
                                    border: 0,
                                    fontSize: 13,
                                    padding: 2
                                }}
                            />
                        </Box> : ""
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default PingAdmin