import { PaidOutlined } from '@mui/icons-material'
import { Box, Button, CardMedia, Container, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import GetAllFeatures from '../../../utils/GetAllFeatures'
import './font.css'
import { Link } from 'react-router-dom'

const Features = ({ data }) => {
    const { allFeatures } = GetAllFeatures()
    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    return (
        <Box sx={{ backgroundImage: 'url("/assets/bg2.png")', backgroundSize: 'cover', backgroundPosition: 'center' }} >
            {/* <Box sx={{ bgcolor: (t) => t.palette.primary.main }} p={0.2}>

            </Box> */}
            <Container maxWidth='lg'>
                <Box pt={4} pb={4}>
                    <Stack spacing={2} direction={'column'} alignItems={'center'}>
                        <Typography align='center' color={'#F7F7F7'} >{data.amazingFeatures}</Typography>
                        <Typography className='grad' align='center' variant='h3' >{data.feaDes}</Typography>
                    </Stack>

                    <Box mt={15} >
                        <Grid container spacing={2} >
                            {allFeatures ? allFeatures.map((i, key) => {
                                return (
                                    <Grid key={key} xs={12} sm={4} lg={3} item>
                                        <Box
                                            sx={{
                                                bgcolor: (t) => convertHexToRgba(t.palette.primary.main, 0.02),
                                                '&:hover': {
                                                    backgroundColor: (t) => convertHexToRgba(t.palette.primary.main, 0.1), // Background color on hover
                                                },
                                                height: '85%'
                                            }} p={2}>
                                            <Stack mt={5} mb={5} alignItems={'center'} spacing={2} direction={'column'}>
                                                <CardMedia src={`/images/${i.image}`} component={'img'} sx={{ height: 50, width: 50 }} />
                                                <Typography align='center' fontWeight={'bold'} variant='h5' >{i.title}</Typography>
                                                <Typography align='center' color={'gray'} >{i.des}</Typography>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                )
                            }) : <LinearProgress />}

                        </Grid>
                    </Box>
                    <Stack mt={4} alignItems={'center'}>
                        <Button
                            component={Link}
                            to="/user"
                            variant='contained' sx={{ textTransform: 'none', borderRadius: 2 }} size='large' >{data.getStarted}</Button>
                    </Stack>
                </Box>
            </Container>
        </Box>
    )
}

export default Features