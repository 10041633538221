import React from 'react'
import ChatBubble from './ChatBubble'
import LoadingBubble from './LoadingBubble'
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { DarkMode, LightMode, Send } from '@mui/icons-material'
import { TranslateContext } from '../../../context/TranslateContext'
import { GlobalContext } from '../../../context/GlobalContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'

const EmbedChatBot = (props) => {
    const [state, setState] = React.useState({})
    const [que, setQue] = React.useState("")
    const history = useHistory()
    const G = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const [chatOn, setChatOn] = React.useState(false)
    const uid = props.match.params.uid
    const bot = props.match.params.bot

    const chatId = Date.now()
    const [snackMsg, setSnackMsg] = React.useState("")
    const [snack, setSnack] = React.useState(false)

    const [load, setLoad] = React.useState(false)
    const [typing, setTyping] = React.useState(false)

    async function getChatBot() {
        const res = await G.hitAxios({
            path: "/api/embed/get_bot",
            post: true,
            admin: false,
            obj: { botId: bot, uid }
        })
        if (res.data.success) {
            setState({ ...state, botData: res.data.data })
        } else {
            setState({ ...state, botData: "false" })
        }
    }

    React.useEffect(() => {
        getChatBot()
    }, [])

    const [messages, setMessages] = React.useState([
    ]);

    function getReply() {
        if (!que) return
        setTyping(true);
        setMessages((prevMessages) => [
            ...prevMessages,
            { role: "user", content: que },
        ]);
        setQue("");
        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/api/embed/get_reply`,
                {
                    train_data: state.botData?.train_data,
                    question: que,
                    user_email: state.email,
                    uid: uid
                }
            )
            .then((res) => {
                setTyping(false);
                if (res.data.success) {
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { role: "assistant", content: res.data.data },
                    ]);
                } else {
                    setSnack(true);
                    setSnackMsg(res.data.msg);
                }
            });
    }

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    async function startChat() {
        const res = await G.hitAxios({
            path: "/api/embed/create_chat",
            post: true,
            admin: false,
            obj: {
                botId: bot,
                uid: uid,
                user_email: state.email,
                user_mobile: state.mobile,
                user_name: state.name
            }
        })
        if (res.data.success) {
            setChatOn(true)
            if (res.data.data.length > 0) {
                setMessages([...res.data.data])
            }
        }
    }


    return (
        <Box height={'100%'} p={2}>
            {state.botData ?
                state.botData === "false" || state.botData.active < 1 ? history.push('/404') :
                    <>
                        <Box mb={2} borderRadius={2} bgcolor={'action.hover'} p={1}>
                            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                <Typography variant='body2' >Chat Title</Typography>

                                {localStorage.getItem('theme') == 'dark' ?
                                    <IconButton onClick={() => {
                                        localStorage.setItem("theme", "light")
                                        window.location.reload()
                                    }} >
                                        <LightMode />
                                    </IconButton> :
                                    <IconButton onClick={() => {
                                        localStorage.setItem("theme", "dark")
                                        window.location.reload()
                                    }} >
                                        <DarkMode />
                                    </IconButton>}
                            </Stack>
                        </Box>
                        {!chatOn ?
                            <Box p={2} borderRadius={2} bgcolor='action.hover'>
                                <Stack spacing={2} direction={'column'}>
                                    <Typography align='center' variant='body2' >{data.fillFormChat}</Typography>
                                    <TextField
                                        label={data.name}
                                        variant='filled'
                                        size='small'
                                        onChange={(e) => setState({ ...state, name: e.target.value })}
                                        InputProps={{
                                            style: {
                                                borderRadius: 10
                                            },
                                            disableUnderline: true
                                        }} />

                                    <TextField
                                        label={data.email}
                                        variant='filled'
                                        size='small'
                                        onChange={(e) => setState({ ...state, email: e.target.value })}
                                        InputProps={{
                                            style: {
                                                borderRadius: 10
                                            },
                                            disableUnderline: true
                                        }} />

                                    <TextField
                                        type='number'
                                        label={data.mobile}
                                        variant='filled'
                                        size='small'
                                        onChange={(e) => setState({ ...state, mobile: e.target.value })}
                                        InputProps={{
                                            style: {
                                                borderRadius: 10
                                            },
                                            disableUnderline: true
                                        }} />

                                    <Button
                                        onClick={startChat}
                                        disabled={state.name && state.mobile && validateEmail(state.email) ? false : true}
                                        variant='contained'
                                        sx={{ borderRadius: 2 }}
                                    >{data.startChatting}</Button>

                                </Stack>
                            </Box>
                            :
                            <Stack direction={'column'} height={'90%'} justifyContent={'space-between'}>
                                <Stack overflow={'auto'} maxHeight={'90%'} direction={'column'}>
                                    {messages.map((message, index) => (
                                        <ChatBubble loading key={index} content={message.content} role={message.role} />
                                    ))}
                                    {typing && <LoadingBubble />}
                                </Stack>

                                <Box pt={2} pr={1} pl={1} >
                                    <TextField
                                        // multiline
                                        variant='filled'
                                        value={que}
                                        onChange={(e) => setQue(e.target.value)}
                                        InputProps={{
                                            style: { borderRadius: 20 },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Stack direction={'row'} spacing={1}>
                                                        <IconButton
                                                            disabled={typing}
                                                            onClick={getReply}
                                                        >
                                                            <Send />
                                                        </IconButton>

                                                    </Stack>
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                getReply();
                                            }
                                        }}
                                        label="Enter message" fullWidth />
                                </Box>
                            </Stack>
                        }</> :
                <Typography>loading...</Typography>
            }
        </Box >
    )
}

export default EmbedChatBot