import axios from 'axios'
import React from 'react'

const GetInstanceByToken = () => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [instanceByToken, setState] = React.useState("")
    function getData() {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/sessions/get-users-instances`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => setState(res.data.data))
            .catch((err) => console.log(err))
    }
    React.useEffect(() => {
        if (!token) return
        getData()
    }, [token])
    return { instanceByToken }
}

export default GetInstanceByToken