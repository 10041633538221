import React from 'react'
import { Stack, Button, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { GlobalContext } from '../../../context/GlobalContext'
import { InstanceContext } from '../context/InstanceContext'

const Header = ({ data }) => {
    const CON = React.useContext(InstanceContext)
    return (
        <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
            <Typography variant='h5' fontWeight={'bold'}>{data.manageInstance}</Typography>
            <Button
                onClick={() => CON.setData({ ...CON.data, addDialog: true })}
                startIcon={<Add />}
                sx={{ textTransform: "none", borderRadius: 2 }} variant='contained' >{data.addInstance}</Button>
        </Stack>
    )
}

export default Header