import React from 'react'
import axios from 'axios'

const GetAllFaq = () => {
    const [allFaq, setallFaq] = React.useState("")
    async function getData() {
        const plans = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/get-all-faq`)
        setallFaq(plans.data.data)
    }
    React.useEffect(() => {
        getData()
    }, [])
    return { allFaq }
}

export default GetAllFaq