import { ArrowBack, SimCardDownload, Send } from '@mui/icons-material'
import { Box, IconButton, InputAdornment, LinearProgress, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import ChatBubble from './ChatBubble'
import LoadingBubble from './LoadingBubble'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import SpeechToText from './SpeechToText'


const Chatting = ({ CON, data, G, selCate }) => {
    const history = useHistory()
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [messages, setMessages] = React.useState([
    ]);
    const [snackMsg, setSnackMsg] = React.useState("")
    const [load, setLoad] = React.useState(false)
    const [typing, setTyping] = React.useState(false)
    const [que, setQue] = React.useState("")
    const [snack, setSnack] = React.useState(false)

    const handleNewMessage = () => {
        const newMessage = { content: 'This is a new message!', role: 'assistand' };
        setMessages([...messages, newMessage]);
    };

    function getMessage() {
        setLoad(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/chatbot/get_templet_convo`, { modelId: CON.data?.chattingBotData?.id }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            setLoad(false)
            if (res.data.success) {
                setMessages(res.data.data)
            } else {
                setSnack(true)
                setSnackMsg(res.data.msg)
            }
        })
    }

    React.useEffect(() => {
        if (!token) return
        getMessage()
    }, [token])

    function getReply() {
        if (!que) return
        setTyping(true);
        setMessages((prevMessages) => [
            ...prevMessages,
            { role: "user", content: que },
        ]);
        setQue("");
        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/api/chatbot/get_reply`,
                {
                    modelId: CON.data?.chattingBotData?.id,
                    train_data: CON.data?.chattingBotData?.train_data,
                    question: que,
                },
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then((res) => {
                setTyping(false);
                if (res.data.success) {
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { role: "assistant", content: res.data.data },
                    ]);
                } else {
                    setSnack(true);
                    setSnackMsg(res.data.msg);
                }
            });
    }

    return (
        <Box p={3}>
            <Snackbar open={snack} message={snackMsg} autoHideDuration={4000} />
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                <IconButton onClick={() => history.go(0)}>
                    <ArrowBack />
                </IconButton>
                <Stack spacing={2} direction={'row'}>
                    <IconButton color='secondary' >
                        <SimCardDownload />
                    </IconButton>
                    <Stack direction={'column'}>
                        <Typography fontWeight={'bold'} variant='body2'>{CON.data.chattingBotData.title}</Typography>
                        <Typography color={'gray'} variant='caption'>{selCate}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack overflow={'auto'} direction={'column'} justifyContent={'space-between'} height={'70vh'} mt={2}>
                {load ? <LinearProgress /> :
                    <>
                        <Stack direction={'column'}>
                            {messages.map((message, index) => (
                                <ChatBubble loading key={index} content={message.content} role={message.role} />
                            ))}

                            {typing && <LoadingBubble />}
                        </Stack>

                        <Box pt={2} pr={1} pl={1} >
                            <TextField
                                // multiline
                                variant='filled'
                                value={que}
                                onChange={(e) => setQue(e.target.value)}
                                InputProps={{
                                    style: { borderRadius: 20 },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Stack direction={'row'} spacing={1}>
                                                <SpeechToText onChange={(e) => {
                                                    setQue(e)
                                                }} />
                                                <IconButton
                                                    disabled={typing}
                                                    onClick={getReply}>
                                                    <Send />
                                                </IconButton>

                                            </Stack>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        getReply();
                                    }
                                }}
                                label="Enter message" fullWidth />
                        </Box>
                    </>}
            </Stack>
        </Box >
    )
}

export default Chatting