import { Box, Grid, Stack, Typography, CircularProgress, Chip, LinearProgress, Divider } from '@mui/material'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import axios from 'axios'
import { Group } from '@mui/icons-material'


const Dashboard = () => {
    const { data } = React.useContext(TranslateContext)
    const [dash, setDash] = React.useState("")
    const [state, setState] = React.useState({
        genImgType: 'daily',
        wpType: 'daily',
        sttType: 'daily',
        ttsType: 'daily'
    })

    async function getDash() {
        const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get_dash`, {
            headers: {
                AuthorizatioN: "Bearer " + token
            }
        }).then((res) => {
            if (res.data.success) {
                setDash(res.data)
            } else {
                alert("Sever error")
            }
        }).catch((err) => console.log(err))
    }

    React.useEffect(() => {
        getDash()
    }, [])

    return (
        <Box mt={4}>
            <Stack direction={'column'} spacing={4}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6} lg={6} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.totalUsers}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.plan ? dash.plan : data.na : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid xs={12} sm={6} lg={6} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.instance}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.instance : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid xs={12} sm={6} lg={6} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.bots}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.bots : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid xs={12} sm={6} lg={6} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.pendingPings}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.pendingPings : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>


                {dash ?
                    <Box>
                        <Stack mb={2} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <Typography>{data.AiImgDetails}</Typography>
                            <Stack spacing={1} direction={'row'}>
                                <Chip variant={state.genImgType == 'total' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, genImgType: 'total' })} label={data.total} />
                                <Chip variant={state.genImgType == 'daily' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, genImgType: 'daily' })} label={data.daily} />
                                <Chip variant={state.genImgType == 'monthly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, genImgType: 'monthly' })} label={data.monthly} />
                                <Chip variant={state.genImgType == 'yearly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, genImgType: 'yearly' })} label={data.yearly} />
                            </Stack>
                        </Stack>
                        <Stack direction='column' spacing={2}>
                            <div style={{ position: 'relative' }} >
                                {state.genImgType == 'total' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allGenImg / dash.allGenImg }} />}
                                {state.genImgType == 'daily' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allGenImg / dash.dailyGenImg }} />}
                                {state.genImgType == 'monthly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allGenImg / dash.monthlyGenImg }} />}
                                {state.genImgType == 'yearly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allGenImg / dash.yearlyGenImg }} />}
                                <Divider />
                                <Typography align='center' variant="caption" sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, alignItems: 'center', justifyContent: "center", display: 'flex' }} >
                                    {data.allGenImg} {state.genImgType == 'daily' && dash.dailyGenImg}{state.genImgType == 'monthly' && dash.monthlyGenImg}{state.genImgType == 'yearly' && dash.yearlyGenImg}{state.genImgType == 'total' && dash.allGenImg}
                                </Typography>
                            </div>
                        </Stack>
                    </Box> :
                    <LinearProgress />}


                {dash ?
                    <Box>
                        <Stack mb={2} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <Typography>{data.AutoBlogs}</Typography>
                            <Stack spacing={1} direction={'row'}>
                                <Chip variant={state.wpType == 'total' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, wpType: 'total' })} label={data.total} />
                                <Chip variant={state.wpType == 'daily' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, wpType: 'daily' })} label={data.daily} />
                                <Chip variant={state.wpType == 'monthly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, wpType: 'monthly' })} label={data.monthly} />
                                <Chip variant={state.wpType == 'yearly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, wpType: 'yearly' })} label={data.yearly} />
                            </Stack>
                        </Stack>
                        <Stack direction='column' spacing={2}>
                            <div style={{ position: 'relative' }} >
                                {state.wpType == 'total' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allAutoBlog / dash.allAutoBlog }} />}
                                {state.wpType == 'daily' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allAutoBlog / dash.dailyAutoBlog }} />}
                                {state.wpType == 'monthly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allAutoBlog / dash.monthlyAutoBlog }} />}
                                {state.wpType == 'yearly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allAutoBlog / dash.yearlyAutoBlog }} />}
                                <Divider />
                                <Typography align='center' variant="caption" sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, alignItems: 'center', justifyContent: "center", display: 'flex' }} >
                                    {data.allAutoBlog} {state.wpType == 'daily' && dash.dailyAutoBlog}{state.wpType == 'monthly' && dash.monthlyAutoBlog}{state.wpType == 'yearly' && dash.yearlyAutoBlog}{state.wpType == 'total' && dash.allAutoBlog}
                                </Typography>
                            </div>
                        </Stack>
                    </Box> :
                    <LinearProgress />}


                {dash ?
                    <Box>
                        <Stack mb={2} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <Typography>{data.speechToText}</Typography>
                            <Stack spacing={1} direction={'row'}>
                                <Chip variant={state.sttType == 'total' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, sttType: 'total' })} label={data.total} />
                                <Chip variant={state.sttType == 'daily' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, sttType: 'daily' })} label={data.daily} />
                                <Chip variant={state.sttType == 'monthly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, sttType: 'monthly' })} label={data.monthly} />
                                <Chip variant={state.sttType == 'yearly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, sttType: 'yearly' })} label={data.yearly} />
                            </Stack>
                        </Stack>
                        <Stack direction='column' spacing={2}>
                            <div style={{ position: 'relative' }} >
                                {state.sttType == 'total' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allStt / dash.allStt }} />}
                                {state.sttType == 'daily' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allStt / dash.dailyStt }} />}
                                {state.sttType == 'monthly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allStt / dash.monthlyStt }} />}
                                {state.sttType == 'yearly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allStt / dash.yearlyStt }} />}
                                <Divider />
                                <Typography align='center' variant="caption" sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, alignItems: 'center', justifyContent: "center", display: 'flex' }} >
                                    {data.allStt} {state.sttType == 'daily' && dash.dailyStt}{state.sttType == 'monthly' && dash.monthlyStt}{state.sttType == 'yearly' && dash.yearlyStt}{state.sttType == 'total' && dash.allStt}
                                </Typography>
                            </div>
                        </Stack>
                    </Box> :
                    <LinearProgress />}


                {dash ?
                    <Box>
                        <Stack mb={2} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <Typography>{data.textToSpeech}</Typography>
                            <Stack spacing={1} direction={'row'}>
                                <Chip variant={state.ttsType == 'total' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, ttsType: 'total' })} label={data.total} />
                                <Chip variant={state.ttsType == 'daily' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, ttsType: 'daily' })} label={data.daily} />
                                <Chip variant={state.ttsType == 'monthly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, ttsType: 'monthly' })} label={data.monthly} />
                                <Chip variant={state.ttsType == 'yearly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, ttsType: 'yearly' })} label={data.yearly} />
                            </Stack>
                        </Stack>
                        <Stack direction='column' spacing={2}>
                            <div style={{ position: 'relative' }} >
                                {state.ttsType == 'total' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allTTs / dash.allTTs }} />}
                                {state.ttsType == 'daily' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allTTs / dash.dailyTTs }} />}
                                {state.ttsType == 'monthly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allTTs / dash.monthlyTTs }} />}
                                {state.ttsType == 'yearly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.allTTs / dash.yearlyTTs }} />}
                                <Divider />
                                <Typography align='center' variant="caption" sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, alignItems: 'center', justifyContent: "center", display: 'flex' }} >
                                    {data.allTTs} {state.ttsType == 'daily' && dash.dailyTTs}{state.ttsType == 'monthly' && dash.monthlyTTs}{state.ttsType == 'yearly' && dash.yearlyTTs}{state.ttsType == 'total' && dash.allTTs}
                                </Typography>
                            </div>
                        </Stack>
                    </Box> :
                    <LinearProgress />}
            </Stack>
        </Box>
    )
}

export default Dashboard