import React from 'react'
import { ManageUserProvider } from './context/ManageUserContext'
import { Box, Typography, Stack } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import ManageUserDialog from './components/ManageUserDialog';
import UserList from './components/UserList';

const ManageUser = () => {
    const { data } = React.useContext(TranslateContext)
    return (
        <ManageUserProvider>
            <Box mt={4}>
                <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                    <Typography variant='h5' fontWeight={'bold'}>{data.manageUsers}</Typography>
                </Stack>

                <ManageUserDialog data={data} />

                <UserList data={data} />
            </Box>
        </ManageUserProvider>
    )
}

export default ManageUser