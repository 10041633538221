import {
    Dialog, FormControl, InputLabel,
    Box, Stack, IconButton, Typography, Divider,
    LinearProgress, MenuItem, Select, Switch, TextField, InputAdornment, Button
} from '@mui/material'
import React from 'react'
import { AiBotContext } from './AiBotContext'
import { Close, EmojiEmotions } from '@mui/icons-material'
import GetInstanceByToken from '../../../utils/GetInstanceByToken'
import EmojiPicker from 'emoji-picker-react';
import { LoadingButton } from '@mui/lab'
import { GlobalContext } from '../../../context/GlobalContext'

const AddDialog = ({ data }) => {
    const CON = React.useContext(AiBotContext)
    const G = React.useContext(GlobalContext)
    const [state, setState] = React.useState({})
    const inputProps = { style: { borderRadius: 10 } }
    const { instanceByToken } = GetInstanceByToken()
    const [emo, setEmo] = React.useState(false)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")

    async function getBots() {
        const res = await G.hitAxios({
            path: "/api/bot/get-bots",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.success) {
            CON.setData({ ...CON, botData: res.data.data })
        }
    }

    React.useEffect(() => {
        if (!token) return
        getBots()
    }, [token])

    async function handleSend() {
        const res = await G.hitAxios({
            path: "/api/bot/add-aibot",
            post: true,
            admin: false,
            obj: state
        })
        if (res.data.success) {
            CON.setData({ ...CON.data, addDialog: false })
            getBots()
        }
    }

    return (
        <Dialog
            fullWidth
            onClose={() => CON.setData({ ...CON.data, addDialog: false })}
            open={CON.data.addDialog}
            PaperProps={{
                style: {
                    borderRadius: 10
                }
            }} >

            <Dialog open={emo} onClose={() => setEmo(false)}>
                <EmojiPicker onEmojiClick={(e) => {
                    setState({ ...state, reaction: e.emoji })
                    setEmo(false)
                }} />
            </Dialog>


            <Box p={2}  >
                <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                    <IconButton onClick={(e) => CON.setData({ ...CON.data, addDialog: false })} >
                        <Close />
                    </IconButton>
                    <Typography>{data.addNew}</Typography>
                </Stack>
                <Divider />

                <Stack direction={'column'} mt={2} spacing={2} >
                    {instanceByToken ?
                        <FormControl fullWidth>
                            <InputLabel size='small' id="demo-simple-select-label">{data.selectInstance}</InputLabel>
                            <Select
                                sx={{ borderRadius: 2 }}
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={data.selectInstance}
                                label={data.selectInstance}
                                onChange={(e) => setState({ ...state, client_id: e.target.value })}
                            >
                                {instanceByToken.map((i, key) => {
                                    return (
                                        <MenuItem key={key} value={i.client_id}>{i.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl> : <LinearProgress />}

                    <TextField
                        onChange={(e) => setState({ ...state, name: e.target.value })}
                        label={data.botName} fullWidth size='small' InputProps={inputProps} />

                    <TextField
                        multiline
                        rows={4}
                        placeholder={data.insText}
                        onChange={(e) => setState({ ...state, train_data: e.target.value })}
                        label={data.trainData} fullWidth size='small' InputProps={inputProps} />

                    <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'} spacing={1}>
                        <Typography variant='caption' color={'gray'} >{data.enableTyping}</Typography>
                        <Switch
                            onClick={(e) => setState({ ...state, enable_typing: e.target.checked })}
                            checked={state.enable_typing} />
                    </Stack>

                    <TextField
                        InputLabelProps={{ shrink: true }}
                        disabled
                        value={state.reaction}
                        helperText={data.leaveItBlack}
                        label={data.addReaction} InputProps={{
                            ...inputProps, endAdornment: (
                                <InputAdornment position='end'>
                                    {state.reaction ?
                                        <IconButton color='error' onClick={() => setState({ ...state, reaction: "" })} >
                                            <Close />
                                        </IconButton> :
                                        <IconButton onClick={() => setEmo(true)} >
                                            <EmojiEmotions />
                                        </IconButton>}
                                </InputAdornment>
                            )
                        }} fullWidth size='small' />


                    <LoadingButton
                        loading={G.data.loading}
                        onClick={handleSend}
                        disabled={state.client_id && state.name && state.train_data ? false : true}
                        fullWidth
                        variant='contained'
                        sx={{ borderRadius: 2, textTransform: 'none' }}
                    >{data.submit}</LoadingButton>

                </Stack>
            </Box>
        </Dialog>
    )
}

export default AddDialog

