import React, { useCallback } from 'react'
import { GlitchAnimation } from './GlitchAnimation'
import { Box, Button, CardMedia, Container, Grid, Stack, Typography, ImageList } from '@mui/material'
import './float.css'
import { Link } from 'react-router-dom'


const HeroSection = ({ data }) => {

    return (

        <Box
            sx={{
                backgroundImage: 'url("/assets/bg3.png")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                minHeight: '100vh', // Adjust the height as needed
            }}>
            <Container sx={{ pt: 10 }} maxWidth='lg' >
                <Grid justifyContent={'space-between'} alignItems={'center'} container minHeight={'80vh'}>
                    <Grid item xs={12} sm={12} lg={6}>
                        <Stack spacing={1} direction={'column'} alignItems={'flex-start'}>
                            <GlitchAnimation data={data} isActive={true} animationDurationMS={5000} text={data.frontTitle} />
                            <Button
                                component={Link}
                                to="/user"
                                sx={{ textTransform: "none", padding: 2, borderRadius: 5 }}
                                variant='outlined'
                            >{data.getStarted}</Button>
                            <Typography fontWeight={'bold'} >{data.heroBullet}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                        <CardMedia className='floating' src='/assets/ai.png' component={'img'} height={'100%'} />
                    </Grid>
                </Grid>

            </Container>



            {/* <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', }} >{data.AiImgGen}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', width: }} >{data.InformativeDash}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', wid}} >{data.TrainYourAI}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828}} >{data.waBot}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', }} >{data.multiBot}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', width: }} >{data.letYourBusiness}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828',}} >{data.support}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', widt}} >{data.speechToText}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', wid}} >{data.multiVoices}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', widt}} >{data.speechToText}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', wi}} >{data.wpAutoBlog}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828'}} >{data.bardAi}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', wid}} >{data.consultWith}</Typography>
                    <Typography sx={{ borderRadius: 2, p: 1, bgcolor: '#282828', width: da}} >{data.plandForYourNeeds}</Typography> */}



        </Box>
    )
}

export default HeroSection