import { Button, Box, Grid, Stack, Typography, Chip, Divider, CircularProgress, LinearProgress } from '@mui/material'
import { Group } from '@mui/icons-material'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import axios from 'axios'

const Dashboard = () => {
    const { data } = React.useContext(TranslateContext)
    const [dash, setDash] = React.useState("")
    const [state, setState] = React.useState({
        orderType: 'daily',
        ttsType: 'daily',
        sttType: 'daily'
    })

    async function getDash() {
        const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/get_dashboard`, {
            headers: {
                AuthorizatioN: "Bearer " + token
            }
        }).then((res) => {
            if (res.data.success) {
                setDash(res.data)
            } else {
                alert("Sever error")
            }
        }).catch((err) => console.log(err))
    }

    React.useEffect(() => {
        getDash()
    }, [])


    return (
        <Box mt={4}>
            <Stack direction={'column'} spacing={4}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6} lg={4} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.totalUsers}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.totalUsers : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid xs={12} sm={6} lg={4} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.paidUsers}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.paidUserCount : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid xs={12} sm={6} lg={4} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.activeIns}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.activeInsatnce : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid xs={12} sm={6} lg={4} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.activeWaBot}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.activeWaBot : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid xs={12} sm={6} lg={4} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.pendingPings}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.pendingPings : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid xs={12} sm={6} lg={4} item>
                        <Box borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                            <Stack spacing={2} direction={'column'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontWeight={'bold'} >{data.totalGenWP}</Typography>
                                    <Group />
                                </Stack>
                                <Typography >{dash ? dash.totalGenWP : <CircularProgress size={20} />}</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                </Grid>

                {dash ?
                    <Box>
                        <Stack mb={2} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <Typography>{data.orderDetails}</Typography>
                            <Stack spacing={1} direction={'row'}>
                                <Chip variant={state.orderType == 'total' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, orderType: 'total' })} label={data.total} />
                                <Chip variant={state.orderType == 'daily' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, orderType: 'daily' })} label={data.daily} />
                                <Chip variant={state.orderType == 'monthly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, orderType: 'monthly' })} label={data.monthly} />
                                <Chip variant={state.orderType == 'yearly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, orderType: 'yearly' })} label={data.yearly} />
                            </Stack>
                        </Stack>
                        <Stack direction='column' spacing={2}>
                            <div style={{ position: 'relative' }} >
                                {state.orderType == 'total' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totalOrders / dash.totalOrders }} />}
                                {state.orderType == 'daily' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totalOrders / dash.dailyOrdersData }} />}
                                {state.orderType == 'monthly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totalOrders / dash.monthBasedOrder }} />}
                                {state.orderType == 'yearly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totalOrders / dash.yearBasedOrders }} />}
                                <Divider />
                                <Typography align='center' variant="caption" sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, alignItems: 'center', justifyContent: "center", display: 'flex' }} >
                                    {data.totalOrders} {state.orderType == 'daily' && dash.dailyOrdersData}{state.orderType == 'monthly' && dash.monthBasedOrder}{state.orderType == 'yearly' && dash.yearBasedOrders}{state.orderType == 'total' && dash.totalOrders}
                                </Typography>
                            </div>
                        </Stack>
                    </Box> :
                    <LinearProgress />}


                {dash ?
                    <Box>
                        <Stack mb={2} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <Typography>{data.totalTxtToSpeech}</Typography>
                            <Stack spacing={1} direction={'row'}>
                                <Chip variant={state.ttsType == 'total' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, ttsType: 'total' })} label={data.total} />
                                <Chip variant={state.ttsType == 'daily' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, ttsType: 'daily' })} label={data.daily} />
                                <Chip variant={state.ttsType == 'monthly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, ttsType: 'monthly' })} label={data.monthly} />
                                <Chip variant={state.ttsType == 'yearly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, ttsType: 'yearly' })} label={data.yearly} />
                            </Stack>
                        </Stack>
                        <Stack direction='column' spacing={2}>
                            <div style={{ position: 'relative' }} >
                                {state.ttsType == 'total' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totaltts / dash.totaltts }} />}
                                {state.ttsType == 'daily' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totaltts / dash.dailyDatatts }} />}
                                {state.ttsType == 'monthly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totaltts / dash.monthBasedOrdertts }} />}
                                {state.ttsType == 'yearly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totaltts / dash.yearBasedOrderstts }} />}
                                <Divider />
                                <Typography align='center' variant="caption" sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, alignItems: 'center', justifyContent: "center", display: 'flex' }} >
                                    {data.totaltts} {state.ttsType == 'daily' && dash.dailyDatatts}{state.ttsType == 'monthly' && dash.monthBasedOrdertts}{state.ttsType == 'yearly' && dash.yearBasedOrderstts}{state.ttsType == 'total' && dash.totaltts}
                                </Typography>
                            </div>
                        </Stack>
                    </Box> :
                    <LinearProgress />}


                {dash ?
                    <Box>
                        <Stack mb={2} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <Typography>{data.speechToTextDetails}</Typography>
                            <Stack spacing={1} direction={'row'}>
                                <Chip variant={state.sttType == 'total' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, sttType: 'total' })} label={data.total} />
                                <Chip variant={state.sttType == 'daily' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, sttType: 'daily' })} label={data.daily} />
                                <Chip variant={state.sttType == 'monthly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, sttType: 'monthly' })} label={data.monthly} />
                                <Chip variant={state.sttType == 'yearly' ? 'filled' : 'outlined'} sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, sttType: 'yearly' })} label={data.yearly} />
                            </Stack>
                        </Stack>
                        <Stack direction='column' spacing={2}>
                            <div style={{ position: 'relative' }} >
                                {state.sttType == 'total' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totalttssst / dash.totalttssst }} />}
                                {state.sttType == 'daily' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totalttssst / dash.dailyDatattsstt }} />}
                                {state.sttType == 'monthly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totalttssst / dash.monthBasedOrderttssst }} />}
                                {state.sttType == 'yearly' &&
                                    <Box p={2} sx={{ borderTopRightRadius: 25, bgcolor: (t) => t.palette.primary.main, opacity: 0.1, width: dash.totalttssst / dash.yearBasedOrdersttssst }} />}
                                <Divider />
                                <Typography align='center' variant="caption" sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, alignItems: 'center', justifyContent: "center", display: 'flex' }} >
                                    {data.totalttssst} {state.sttType == 'daily' && dash.dailyDatattsstt}{state.sttType == 'monthly' && dash.monthBasedOrderttssst}{state.sttType == 'yearly' && dash.yearBasedOrdersttssst}{state.sttType == 'total' && dash.totalttssst}
                                </Typography>
                            </div>
                        </Stack>
                    </Box> :
                    <LinearProgress />}

            </Stack>
        </Box>
    )
}

export default Dashboard