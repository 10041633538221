import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { CheckOutContext } from '../context/CheckOutContext'
import { CardActionArea, CardMedia, Box, Stack, Typography } from '@mui/material'

const UsingInstamojo = ({ data, paymentGateways, plan, web }) => {
    const G = React.useContext(GlobalContext)
    const CON = React.useContext(CheckOutContext)

    async function payWithInstamojo() {
        const res = await G.hitAxios({
            path: "/api/plan/pay-with-instamojo",
            post: true,
            admin: false,
            obj: { plan }
        })
        if (res.data.url && res.data.success) {
            window.location.href = res.data.url
        } else {
            alert("Something went wrong with your keys")
        }
    }

    return (
        paymentGateways?.payment_id &&
        <CardActionArea onClick={payWithInstamojo} >
            <Box border={1} style={{ cursor: 'pointer' }} bgcolor={'white'} borderRadius={1} p={1.3}>
                <Stack sx={{ mr: 1.5, ml: 1.5 }} direction={'row'} spacing={2} alignItems={'center'} >
                    <CardMedia component={'img'} src='/assets/instamojo.png' height={29} />
                    {/* <Typography sx={{ textTransform: 'none' }} fontWeight={'bold'} color='black' >Zarinpal</Typography> */}
                </Stack>
            </Box>
        </CardActionArea>
    )
}

export default UsingInstamojo