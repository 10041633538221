import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { CheckOutContext } from '../context/CheckOutContext'
import { CardActionArea, CardMedia, Box } from '@mui/material'
import PaystackPop from '@paystack/inline-js'

const UsingPayStack = ({ data, paymentGateways, plan, web }) => {
    const G = React.useContext(GlobalContext)
    const CON = React.useContext(CheckOutContext)

    function payWithPaystack() {
        const finalamt = parseInt(plan.cost) / parseInt(web.exchange_rate) * 13
        const paystack = new PaystackPop()
        paystack.newTransaction({
            key: paymentGateways?.payment_id,
            amount: finalamt * 100,
            email: "john@gmail.com",
            firstname: "John" || "",
            lastname: "",
            async onSuccess(a) {

                var res = await G.hitAxios({
                    path: "/api/plan/pay-with-paystack",
                    obj: {
                        plan: plan,
                        trans_id: a.trans,
                    },
                    admin: false,
                    post: true
                })

                if (res.data.success) {
                    CON.setData({ ...CON.data, paymentDone: true })
                }
            },
            onCancel() {
                alert("Payment was canceled ")
            }
        })

    }

    return (
        paymentGateways?.payment_id &&
        <CardActionArea onClick={payWithPaystack} >
            <Box border={1} bgcolor={'white'} borderRadius={1.2} p={1.5} pr={2.1} pl={2.1}>
                <CardMedia
                    height={20}
                    src='/assets/Paystack.png' component={'img'} />
            </Box>
        </CardActionArea>
    )
}

export default UsingPayStack