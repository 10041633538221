import React from 'react'
import { Box, Button, CardMedia, Container, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import Testimonial from './Testimonial'
import GetAllTesi from '../../../utils/GetAllTesti'
import { Link } from 'react-router-dom'

const BulletPoints = ({ data }) => {
    const { aalTesti } = GetAllTesi()
    return (
        <Box sx={{ backgroundImage: 'url("/assets/bg3.png")', backgroundSize: 'cover', backgroundPosition: 'center' }} >
            <Container maxWidth='lg'>
                <Box pt={4} pb={4}>
                    <Grid alignItems={'center'} container spacing={2} justifyContent={'space-between'}>
                        <Grid xs={12} sm={6} lg={6} >
                            <CardMedia src='/assets/aiimg1.png' component={'img'} height={'100%'} />
                        </Grid>

                        <Grid xs={12} sm={6} lg={6} >
                            {window.innerWidth < 600 ?
                                <Stack alignItems={'center'} direction={'column'} spacing={2}>
                                    <Typography align='center' variant='h2' >{data.easyToUse}</Typography>
                                    <Button
                                        component={Link}
                                        to="/user"
                                        variant='contained'
                                        sx={{ textTransform: 'none', borderRadius: 4, p: 1.5, boxShadow: 0 }}
                                    >{data.sayHi}</Button>
                                </Stack> :
                                <Stack alignItems={'flex-start'} direction={'column'} spacing={2}>
                                    <Typography variant='h2' >{data.easyToUse}</Typography>
                                    <Button
                                        component={Link}
                                        to="/user"
                                        variant='contained'
                                        sx={{ textTransform: 'none', borderRadius: 4, p: 1.5, boxShadow: 0 }}
                                    >{data.sayHi}</Button>
                                </Stack>}
                        </Grid>
                    </Grid>
                </Box>

                {aalTesti ? <Testimonial aalTesti={aalTesti} data={data} /> : <LinearProgress />}
            </Container>
        </Box>
    )
}

export default BulletPoints