import React from 'react'

export const AiBotContext = React.createContext(null)

export const AiBotProvider = (props) => {
    const [data, setData] = React.useState({
        addDialog: false,
        botData: []
    })

    return (
        <AiBotContext.Provider value={{ data, setData }}>
            {props.children}
        </AiBotContext.Provider>
    )
}

