import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { AiBotContext } from '../contetxt/AiBotContext'


const Header = ({ data }) => {
    const CON = React.useContext(AiBotContext)
    return (
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.aiBot}</Typography>
                <Button
                    onClick={() => CON.setData({ ...CON.data, addDialog: true })}
                    startIcon={<Add />}
                    variant='contained'
                    sx={{ textTransform: "none", borderRadius: 2 }}
                >{data.addNew}</Button>
            </Stack>
        </Box>
    )
}

export default Header