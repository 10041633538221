import React from 'react'
import { Box, Typography, Stack, TextField, Button } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'

const ApiKeys = () => {
    const { data } = React.useContext(TranslateContext)
    const G = React.useContext(GlobalContext)
    const [apikeys, setApiKeys] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")

    async function getAPI() {
        const res = await G.hitAxios({
            path: "/api/admin/get-apikeys",
            admin: true,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            setApiKeys({ ...res.data.data })
        }
    }

    React.useEffect(() => {
        if (!token) return
        getAPI()
    }, [token])

    async function handleSubmit() {
        const res = await G.hitAxios({
            path: "/api/admin/edit-apikey",
            admin: true,
            post: true,
            obj: apikeys
        })
    }

    const inputProps = { style: { borderRadius: 10 } }
    return (
        apikeys &&
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.manageUsers}</Typography>
            </Stack>

            <Stack direction={'column'} spacing={2} mt={4}>
                <TextField
                    onChange={(e) => setApiKeys({ ...apikeys, openai_keys: e.target.value })}
                    value={apikeys.openai_keys}
                    InputProps={inputProps}
                    label={data.openAiKeys} fullWidth size='small' />

                <TextField
                    onChange={(e) => setApiKeys({ ...apikeys, aws_polly_id: e.target.value })}
                    value={apikeys.aws_polly_id}
                    InputProps={inputProps}
                    label={data.awsPollyId} fullWidth size='small' />

                <TextField
                    onChange={(e) => setApiKeys({ ...apikeys, aws_polly_keys: e.target.value })}
                    value={apikeys.aws_polly_keys}
                    InputProps={inputProps}
                    label={data.awsPollyKeys} fullWidth size='small' />

                <TextField
                    onChange={(e) => setApiKeys({ ...apikeys, bard_ai_api: e.target.value })}
                    value={apikeys.bard_ai_api}
                    InputProps={inputProps}
                    helperText={data.barAiCookieDes}
                    label={data.barAiCookie} fullWidth size='small' />


                <Button
                    onClick={handleSubmit}
                    variant='contained'
                    sx={{ borderRadius: 2, boxShadow: 0 }} fullWidth>{data.submit}</Button>

            </Stack>

        </Box>
    )
}

export default ApiKeys