import { Box, Button, Stack, Switch, TextField, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'

const RazorpayComp = ({ data, json }) => {
    const inputProps = { style: { borderRadius: 10 } }
    const [state, setState] = React.useState({})
    const G = React.useContext(GlobalContext)

    async function updatePayment() {
        const res = await G.hitAxios({
            path: "/api/web/update-payment-method",
            post: true,
            admin: true,
            obj: state
        })
    }

    React.useEffect(() => {
        setState({ ...json })
    }, [json])
    return (
        <Box bgcolor={'action.hover'} borderRadius={4} p={4}>
            <Stack direction={'column'} spacing={2}>
                <Typography variant='h5' >{data.razorpay}</Typography>
                <TextField
                    onChange={(e) => setState({ ...state, payment_id: e.target.value })}
                    value={state.payment_id} label={data.id} fullWidth InputProps={inputProps} size='small' />

                <TextField
                    onChange={(e) => setState({ ...state, payment_keys: e.target.value })}
                    value={state.payment_keys} label={data.keys} fullWidth InputProps={inputProps} size='small' />

                <Switch
                    onChange={(e) => setState({ ...state, active: e.target.checked ? 1 : 0 })}
                    checked={state.active == 1 ? true : false} />
                <Button
                    onClick={updatePayment}
                    fullWidth sx={{ borderRadius: 2, textTransform: 'none' }} variant='contained'>{data.submit}</Button>
            </Stack>
        </Box>
    )
}

export default RazorpayComp