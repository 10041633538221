import React from 'react'
import { Box, Stack, Button, Typography, TextField } from '@mui/material'
import { AddPhotoAlternate, Save } from '@mui/icons-material'
import { GlobalContext } from '../../context/GlobalContext'
import GetWebPublic from '../../utils/GetWebPublic';
import { TranslateContext } from '../../context/TranslateContext';

const AppConfig = () => {
    const [state, setState] = React.useState({
    })
    const G = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)

    const { web } = GetWebPublic()

    React.useEffect(() => {
        if (!web) return
        setState({ ...web })
    }, [web])

    async function updateWeb(e) {
        e.preventDefault()
        const fd = new FormData()
        fd.append('app_name', state.app_name)
        fd.append('youtube_video', state.youtube_video)
        fd.append('file', state.logo_image)
        fd.append('currency_symbol', state.currency_symbol)
        fd.append('meta', state.meta)
        fd.append('exchange_rate', state.exchange_rate)
        fd.append('smtp_host', state.smtp_host)
        fd.append('smtp_port', state.smtp_port)
        fd.append('smtp_email', state.smtp_email)
        fd.append('smtp_pass', state.smtp_pass)

        await G.hitAxios({
            path: '/api/web/update-app-config',
            admin: true,
            post: true,
            obj: fd
        })
    }


    return (
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>App Configuration</Typography>
                <Button
                    disabled={state.app_name ? false : true}
                    onClick={updateWeb}
                    startIcon={<Save />} sx={{ boxShadow: 0, borderRadius: 2, textTransform: "none" }} variant='contained' >Save Settings</Button>
            </Stack>
            {web &&
                <Box mt={4}>


                    <Stack direction={'column'} spacing={2}>

                        <Stack direction={'row'} justifyContent={'flex-end'} spacing={4}>

                            <Stack spacing={2} alignItems='center' border={1} justifyContent={'center'} borderRadius={2} p={2}>
                                <Button variant='outlined' component="label">
                                    <input
                                        accept="image/png, image/jpeg, image/jpg"
                                        type="file"
                                        hidden
                                        onChange={(e) => setState({ ...state, logo_image: e.target.files[0] })}
                                    />
                                    {
                                        state.logo_image ? (
                                            <img src={URL.createObjectURL(state.logo_image)} style={{ height: 100, width: 100, borderRadius: 5 }} />
                                        ) : (
                                            web.logo ? (
                                                <img src={`/images/${web.logo}`} style={{ height: 100, width: 100, borderRadius: 5 }} />
                                            ) : <AddPhotoAlternate sx={{ height: 100, width: 100 }} />
                                        )
                                    }
                                </Button>
                                <Typography fontWeight={'bold'} color={state.color} >LOGO</Typography>

                            </Stack>
                        </Stack>


                        <TextField
                            value={state.app_name}
                            onChange={(e) => setState({ ...state, app_name: e.target.value })} label={data.appName} fullWidth size='small' InputProps={{
                                style: {
                                    borderRadius: 10
                                }
                            }} />

                        <TextField
                            value={state.youtube_video}
                            onChange={(e) => setState({ ...state, youtube_video: e.target.value })} label={data.youtubeVideo} fullWidth size='small' InputProps={{
                                style: {
                                    borderRadius: 10
                                }
                            }} />

                        <TextField
                            multiline
                            rows={3}
                            value={state.meta}
                            onChange={(e) => setState({ ...state, meta: e.target.value })} label={data.metaDescription} fullWidth size='small' InputProps={{
                                style: {
                                    borderRadius: 10
                                }
                            }} />

                        <TextField
                            value={state.currency_symbol}
                            onChange={(e) => setState({ ...state, currency_symbol: e.target.value })} label={data.currencySymbol} fullWidth size='small' InputProps={{
                                style: {
                                    borderRadius: 10
                                }
                            }} />

                        <TextField
                            value={state.exchange_rate}
                            onChange={(e) => setState({ ...state, exchange_rate: e.target.value })} label={data.exchangeRate} fullWidth size='small' InputProps={{
                                style: {
                                    borderRadius: 10
                                }
                            }} />




                        <Box p={2} borderRadius={2} bgcolor={'action.hover'}>
                            <Stack direction={'column'} spacing={2}>
                                <Box borderRadius={2} p={2} bgcolor={'orange'}>
                                    <Typography color={'black'} variant='body2' >{data.smtpWar}</Typography>
                                </Box>
                                <TextField
                                    value={state.smtp_email}
                                    onChange={(e) => setState({ ...state, smtp_email: e.target.value })} label={data.smtpEmail} fullWidth size='small' InputProps={{
                                        style: {
                                            borderRadius: 10
                                        }
                                    }} />
                                <TextField
                                    value={state.smtp_host}
                                    onChange={(e) => setState({ ...state, smtp_host: e.target.value })} label={data.smtpHost} fullWidth size='small' InputProps={{
                                        style: {
                                            borderRadius: 10
                                        }
                                    }} />
                                <TextField
                                    value={state.smtp_port}
                                    onChange={(e) => setState({ ...state, smtp_port: e.target.value })} label={data.smtpPort} fullWidth size='small' InputProps={{
                                        style: {
                                            borderRadius: 10
                                        }
                                    }} />
                                <TextField
                                    value={state.smtp_pass}
                                    onChange={(e) => setState({ ...state, smtp_pass: e.target.value })} label={data.smtpPass} fullWidth size='small' InputProps={{
                                        style: {
                                            borderRadius: 10
                                        }
                                    }} />
                            </Stack>
                        </Box>



                    </Stack>
                </Box>}
        </Box>
    )
}

export default AppConfig