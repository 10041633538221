import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { ManageUserContext } from '../context/ManageUserContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, Grid, IconButton, CircularProgress, Typography } from '@mui/material'
import moment from 'moment'
import { Visibility, Login } from '@mui/icons-material'


const UserList = ({ data }) => {
    const G = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const MU = React.useContext(ManageUserContext)

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item>
                            <Typography variant='h5' >{data.allUsers}</Typography>
                        </Grid>
                        <Grid item>
                            <GridToolbarExport />
                        </Grid>
                    </Grid>
                </Box>
            </GridToolbarContainer>
        );
    }


    async function getUsers() {
        const users = await G.hitAxios({
            path: "/api/admin/get-users",
            post: false,
            admin: true,
            token_user: token
        })
        console.log(users.data.data)
        MU.setData({ ...MU.data, userData: users.data.data })
    }

    React.useEffect(() => {
        if (!token) return
        getUsers()
    }, [token])

    function daysLeft(timestampString) {
        const timestamp = new Date(Date.parse(timestampString));
        if (isNaN(timestamp.getTime())) {
            return 'NA';
        } else {
            const now = new Date();
            const diffInMs = timestamp - now;
            const oneDayInMs = 24 * 60 * 60 * 1000;
            const diffInDays = Math.floor(diffInMs / oneDayInMs);
            return `${diffInDays > 0 ? diffInDays : 0} ${data.days}`;
        }
    }

    async function direcLogin(e) {
        const res = await G.hitAxios({
            path: "/api/admin/direct-user-login",
            post: true,
            admin: true,
            obj: { uid: e }
        })

        if (res.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_user", res.data.token)
            window.open('/user')
        }
    }

    return (
        <Box mt={6}>
            <Box borderRadius={4} bgcolor={'action.hover'} >
                {MU.data.userData ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...MU.data.userData].reverse()}
                            columns={[
                                {
                                    headerName: "Login", field: 'uid', flex: 1, renderCell: dataa =>
                                        G.data.loading ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            <IconButton
                                                onClick={() => direcLogin(dataa.row.uid)}
                                            >
                                                <Login />
                                            </IconButton>
                                        )
                                },
                                { headerName: data.email, field: 'email', flex: 1 },
                                { headerName: data.name, field: 'name', flex: 1 },
                                {
                                    headerName: data.plan, field: 'plan', flex: 1, renderCell: dataa => {
                                        return (
                                            dataa.row.plan ? JSON.parse(dataa.row.plan).plan_name : data.na
                                        )
                                    }
                                },
                                {
                                    headerName: data.planExpiry, field: 'planexpire', flex: 1, renderCell: dataa => {
                                        return (
                                            daysLeft(dataa.row.planexpire)
                                        )
                                    }
                                },
                                {
                                    headerName: data.createdAt, field: 'createdAt', flex: 1, renderCell: dataa => {
                                        return (
                                            moment(dataa.row.createdAt).format("DD/MM/YY")
                                        )
                                    }
                                },
                                {
                                    headerName: data.manage, field: 'id', flex: 1, renderCell: data => {
                                        return (
                                            <IconButton onClick={() => MU.setData({ ...MU.data, manageUserDialog: true, editUserData: data.row })} >
                                                <Visibility />
                                            </IconButton>
                                        )
                                    }
                                },
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            components={{
                                Toolbar: CustomToolbar
                            }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>
        </Box>
    )
}

export default UserList