import { Box, CardActionArea, Dialog, Divider, Stack, Typography } from '@mui/material'
import React from 'react'

const UsingOffline = ({ data, web, plan, paymentGateways }) => {
    const [dialog, setDialog] = React.useState(false)
    return (
        <>
            <Dialog
                PaperProps={{
                    style: { borderRadius: 10 }
                }}
                onClose={() => setDialog(false)} open={dialog} fullWidth >
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography align='center' fontWeight={'bold'} variant='h6' >{paymentGateways?.payment_id}</Typography>
                        <Divider />
                        <Typography dangerouslySetInnerHTML={{ __html: paymentGateways?.payment_keys }} ></Typography>
                    </Stack>
                </Box>
            </Dialog>
            <CardActionArea onClick={() => setDialog(true)} >
                <Box border={1} bgcolor={'white'} borderRadius={2} p={1.6} pr={4.1} pl={4.1}>
                    <Typography color='black' fontWeight={'bold'} >{data.offline}</Typography>
                </Box>
            </CardActionArea>
        </>
    )
}

export default UsingOffline