import React from 'react'
import { Box, Button, Dialog, IconButton, Stack, Typography, Divider, TextField, Accordion, AccordionSummary, AccordionDetails, Switch, CardActionArea } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext';
import { Add, Close, CopyAll, Delete, ExpandMore, OpenInNew } from '@mui/icons-material';
import { GlobalContext } from '../../context/GlobalContext';
import GetUserByToken from '../../utils/GetsUserToken'

const ChatbotEmbid = () => {
    const { userData } = GetUserByToken()
    const { data } = React.useContext(TranslateContext);
    const G = React.useContext(GlobalContext)
    const [dialog, setDialog] = React.useState(false)
    const [list, setList] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [state, setState] = React.useState({
    })

    const inputProps = {
        style: {
            borderRadius: 10
        }
    }

    async function addBot() {
        const res = await G.hitAxios({
            path: "/api/embed/add",
            post: true,
            admin: false,
            obj: state
        })
        if (res.data.success) {
            setDialog(false)
            getBot()
        }
    }

    async function getBot() {
        const res = await G.hitAxios({
            path: "/api/embed/get",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.success) {
            setList(res.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) return
        getBot()
    }, [token])

    async function delBot(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/embed/del_bot",
                post: true,
                admin: false,
                obj: { id: e }
            })
            if (res.data.success) {
                getBot()
            }
        }
    }


    return (
        <div>
            <Box mb={4} mt={4}>
                <Stack alignItems="center" spacing={2} direction="row" justifyContent="space-between">
                    <Stack direction="column" justifyContent="space-between">
                        <Typography variant="h5" fontWeight="bold">
                            {data.embidChatbot}
                        </Typography>
                        <Typography variant="caption" color="gray">
                            {data.embidchatbotDes}
                        </Typography>
                    </Stack>

                    <Button
                        onClick={() => setDialog(true)}
                        startIcon={<Add />}
                        sx={{ textTransform: "none", borderRadius: 2 }} variant='contained' >{data.addEmbedBot}</Button>
                </Stack>
            </Box>

            <Dialog
                fullWidth
                onClose={(e) => setDialog(false)}
                open={dialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => setDialog(false)} >
                            <Close />
                        </IconButton>
                        <Typography>{data.addEmbedBot}</Typography>
                    </Stack>
                    <Divider />

                    <Stack direction={'column'} mt={2} spacing={2}>
                        <TextField
                            onChange={(e) => setState({ ...state, title: e.target.value })}
                            size='small' InputProps={inputProps} label={data.botTitle} fullWidth />


                        <TextField
                            placeholder={data.insText}
                            multiline
                            rows={3}
                            onChange={(e) => setState({ ...state, train_data: e.target.value })}
                            size='small' InputProps={inputProps} label={data.trainData} fullWidth />


                        <Button
                            onClick={addBot}
                            disabled={state.title && state.train_data ? false : true}
                            variant='contained'
                            sx={{ borderRadius: 2, boxShadow: 0 }}
                        >{data.submit}</Button>

                    </Stack>


                </Box>
            </Dialog>

            <Box mt={4}>
                <Stack direction={'column'} spacing={2}>
                    {list && userData &&
                        list.map((i, key) => {
                            return (
                                <Accordion key={key} >
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography>{i.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack direction={'column'} spacing={2}>
                                            <Stack direction={'column'} spacing={2}>
                                                <Box p={2} borderRadius={2} sx={{ bgcolor: 'action.hover' }}>
                                                    <Typography
                                                        fontStyle={'italic'}
                                                        variant='caption'
                                                    >{i.train_data}</Typography>
                                                </Box>

                                                <CardActionArea
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(`
                                                    <iframe style="height: 100%; width: 100%;"  src="${`${window.location.protocol}//${window.location.host}/chat/${userData.uid}/${i.bot_id}`}"></iframe>
                                                    `).then(() => alert(data.codeCopied))
                                                    }}
                                                    sx={{ borderRadius: 2, p: 1 }} >
                                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                                        <CopyAll />
                                                        <pre style={{ color: 'orange' }} >
                                                            {'<' + '>'} {data.clickToCopyCode} {'</' + '>'}
                                                        </pre>
                                                    </Stack>
                                                </CardActionArea>
                                            </Stack>
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                <Typography color={'gray'} variant='caption' >{data.botId} {i.bot_id}</Typography>
                                                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                                    <Switch />
                                                    <IconButton
                                                        onClick={() => delBot(i.id)}
                                                        color='error' >
                                                        <Delete />
                                                    </IconButton>
                                                    <IconButton onClick={() => {
                                                        window.open(`/chat/${userData.uid}/${i.bot_id}`)
                                                    }} >
                                                        <OpenInNew />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                </Stack>
            </Box>


        </div>
    )
}


// get all embed chats 


export default ChatbotEmbid