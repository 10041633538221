import React from 'react'
import Dashboard from './dashboard/Dashboard'
import ManageInstance from './manage-instance/ManageInstance'
import AiBot from './ai-bot/AiBot'
import AiImages from './ai-img/AiImages'
import WpAutoBlog from './wp-auto-blog/WpAutoBlog'
import AiChatbot from './ai-chatbot/AiChatbot'
import TextToSpeech from './text-speech/TextToSpeech'
import SpeechToText from './speech-to-text/SpeechToText'
import ChatWithBard from './bart-chat/ChatWithBard'
import PingUser from './ping/PingUser'
import Subscription from './subscription/Subscription'
import ChatbotEmbid from './embid-chatbot/ChatbotEmbid'
import EmbitChatHisroy from './embed-history/EmbitChatHisroy'

const DashboardPage = (props) => {
    return (
        <div>
            {props.page === 0 && <Dashboard />}
            {props.page === 1 && <ManageInstance />}
            {props.page === 2 && <AiBot />}
            {props.page === 3 && <AiImages />}
            {props.page === 4 && <WpAutoBlog />}
            {props.page === 5 && <AiChatbot />}
            {props.page === 6 && <TextToSpeech />}
            {props.page === 7 && <SpeechToText />}
            {props.page === 8 && <ChatWithBard />}
            {props.page === 9 && <PingUser />}
            {props.page === 10 && <Subscription />}
            {props.page === 11 && <ChatbotEmbid />}
            {props.page === 12 && <EmbitChatHisroy />}
        </div>
    )
}

export default DashboardPage