import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack, Box } from '@mui/material';
import { Face, Group, SmartToy } from '@mui/icons-material';
import './style.css'

const ChatBubble = ({ content, role }) => {
    const bubbleClassName = role === 'user' ? 'chat-bubble user' : 'chat-bubble assistant';
    const bubbleRef = useRef(null);

    useEffect(() => {
        bubbleRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (
        <div ref={bubbleRef}>

            {role === "assistant" ?
                <Box borderRadius={3} p={2} sx={{ bgcolor: (t) => t.palette.background.default }}>
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <span class="four-point-star"></span>
                        <Typography>{content}</Typography>
                    </Stack>
                </Box> :
                <Stack p={2} direction={'row'} spacing={2} >
                    <Face />
                    <Typography>{content}</Typography>
                </Stack>}
        </div>
    );
};

ChatBubble.propTypes = {
    content: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['user', 'assistant']).isRequired,
};

export default ChatBubble;
