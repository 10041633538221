import React from 'react';
import './style.css';

interface Props {
	text?: string;
	animationDurationMS?: number;
	isActive?: boolean;
}

export const GlitchAnimation: React.FC<Props> = ({
	text = '',
	animationDurationMS = 0,
	isActive = true,
	data = {}
}) => {
	const [active, setActive] = React.useState(isActive)
	React.useEffect(() => {
		if (animationDurationMS) {
			setTimeout(() => {
				setActive(false);
			}, animationDurationMS);
		}
	}, []);
	return (
		<div >
			<div
				className={active ? 'rect-glitch-text' : 'rect-glitch-text-clear-animation'} data-text={`${data.appName} ${text}`}>
				<a className='grad' >{data.appName} </a>{text}
			</div>
		</div>
	)
}
