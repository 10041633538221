import React from 'react'
import axios from 'axios'

const GetPlansPublic = () => {
    const [plansPublic, setPlansPublic] = React.useState("")
    async function getData() {
        const plans = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/plan/get-all`)
        setPlansPublic(plans.data.data)
    }
    React.useEffect(() => {
        getData()
    }, [])
    return { plansPublic }
}

export default GetPlansPublic