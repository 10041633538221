import React from 'react'
import { Dialog, Box, Stack, IconButton, Typography, Divider, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

const AddNewCateDialog = ({ data, CON, G }) => {
    const [state, setState] = React.useState({})

    async function addCate() {
        const res = await G.hitAxios({
            path: "/api/chatbot/add_chatbot_category",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            getCateg()
        }

    }

    async function getCateg() {
        const res = await G.hitAxios({
            path: "/api/chatbot/get_all_category",
            post: false,
            admin: true,
            token_user: ""
        })
        if (res.data.success) {
            CON.setData({ ...CON.data, categoryData: res.data.data, categoryDialog: false })
        }
    }

    return (
        <div>
            <Dialog
                fullWidth
                onClose={(e) => CON.setData({ ...CON.data, categoryDialog: false })}
                open={CON.data.categoryDialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => CON.setData({ ...CON.data, categoryDialog: false })} >
                            <Close />
                        </IconButton>
                        <Typography>{data.addNewCategory}</Typography>
                    </Stack>
                    <Divider />
                    <Stack direction={'column'} mt={2} spacing={2}>
                        <TextField
                            onChange={(e) => setState({ ...state, categoryName: e.target.value })}
                            size='small'
                            label={data.categoryName}
                            fullWidth InputProps={{ style: { borderRadius: 10 } }} />

                        <LoadingButton
                            loading={G.data.loading}
                            onClick={addCate}
                            disabled={state.categoryName ? false : true}
                            fullWidth
                            variant='contained'
                            sx={{ textTransform: 'none', borderRadius: 2 }}
                        >{data.submit}</LoadingButton>
                    </Stack>

                </Box>
            </Dialog>
        </div>
    )
}

export default AddNewCateDialog