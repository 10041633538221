import React from 'react'
import { Box, Button, Dialog, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Add, Close, Save, Delete } from '@mui/icons-material'
import { GlobalContext } from '../../context/GlobalContext'
import { TranslateContext } from '../../context/TranslateContext'
import moment from 'moment'

const Testimonial = () => {
    const LANG = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        dialog: false
    })
    const [data, setData] = React.useState([])
    const G = React.useContext(GlobalContext)
    const [testi, setTesti] = React.useState({})

    async function addNew() {
        const data = await G.hitAxios({
            path: "/api/admin/add-testimonial",
            admin: true,
            post: true,
            obj: testi
        })
        if (data.data.success) {
            setState({ ...state, dialog: false })
            setTesti({})
            getData()
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    async function delOne(e) {
        if (window.confirm("Are you sure?")) {
            const data = await G.hitAxios({
                path: "/api/admin/del-testimonial",
                post: true,
                admin: true,
                obj: { id: e }
            })
            getData()
        }
    }

    async function getData() {
        const data = await G.hitAxios({
            path: "/api/admin/get-all",
            post: false,
            admin: false
        })
        if (data.data.success) {
            setData(data.data.data)
        }
    }

    return (
        <div>

            <Dialog open={state.dialog} onClose={() => setState({ ...state, dialog: false })} fullWidth PaperProps={{
                style: {
                    borderRadius: 10
                }
            }} >
                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} alignItems={'center'} p={2}>
                    <IconButton onClick={() => setState({ ...state, dialog: false })} >
                        <Close />
                    </IconButton>
                    <Typography>{LANG.data.addTesti}</Typography>
                </Stack>
                <Divider />

                <Box p={2} >
                    <Stack direction={'column'} spacing={2}>
                        <TextField value={testi.name} onChange={(e) => setTesti({ ...testi, name: e.target.value })} label={LANG.data.name} fullWidth size='small' InputProps={{
                            style: {
                                borderRadius: 10
                            }
                        }} />

                        <TextField value={testi.position} onChange={(e) => setTesti({ ...testi, position: e.target.value })} label={LANG.data.position} fullWidth size='small' InputProps={{
                            style: {
                                borderRadius: 10
                            }
                        }} />

                        <TextField value={testi.description} onChange={(e) => setTesti({ ...testi, description: e.target.value })} label={LANG.data.description} multiline rows={3} fullWidth size='small' InputProps={{
                            style: {
                                borderRadius: 10
                            }
                        }} />

                        <Button
                            onClick={addNew}
                            disabled={testi.name && testi.position && testi.description ? false : true}
                            startIcon={<Save />} variant="contained" size='small' sx={{ borderRadius: 2, boxShadow: 0 }} >{LANG.data.submit}</Button>
                    </Stack>
                </Box>

            </Dialog>

            <Box mt={4}>
                <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                    <Typography variant='h5' fontWeight={'bold'}>{LANG.data.manageTesti}</Typography>
                    <Button onClick={() => setState({ ...state, dialog: true })} startIcon={<Add />} sx={{ textTransform: "none", boxShadow: 0, borderRadius: 2 }} variant="contained" >{LANG.data.addNew}</Button>
                </Stack>

                <Box mt={4}>
                    {data.length > 0 &&
                        <Stack direction={'column'} spacing={2}>
                            {data?.map((i, key) => {
                                return (
                                    <Box key={key} borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                                        <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} spacing={2}>
                                            <Stack direction={'column'}>
                                                <Typography fontWeight={'bold'} >{i.name}</Typography>
                                                <Typography color='gray' variant='caption'>{moment(i.createdAt).format("DD/MM/YY")}</Typography>
                                            </Stack>
                                            <IconButton onClick={() => delOne(i.id)} >
                                                <Delete color='error' />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                )
                            })}
                        </Stack>}
                </Box>
            </Box>
        </div>
    )
}

export default Testimonial