import React from 'react'
import { Box, AppBar, CardActionArea, CardMedia, Popover, Typography, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material'
// import './font.css'
import { GlobalContext } from '../../../context/GlobalContext'
import { AutoStories, DarkMode, Home, LightMode, Login, Menu, Translate } from '@mui/icons-material'
import { Link, useHistory } from 'react-router-dom'
import GetAllPages from '../../../utils/GetAllPages'
import GetWebPublic from '../../../utils/GetWebPublic'

const Header = () => {
    const { web } = GetWebPublic()
    const G = React.useContext(GlobalContext)
    const [langs, setLangs] = React.useState([])

    async function getLangs() {
        const res = await G.hitAxios({
            path: "/api/web/get-all-translation-name",
            admin: false,
            post: false,
        })
        if (res.data.success) {
            setLangs(res.data.data)
        }
    }

    React.useEffect(() => {
        getLangs()
    }, [])

    const history = useHistory()
    const [state, setState] = React.useState({
        drawer: false
    })
    const { allPages } = GetAllPages()

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <AppBar
            component="div"
            position="fixed"
            indicatorColor="primary"
            sx={{ bgcolor: (e) => convertHexToRgba(e.palette.primary.main, 0.03), backdropFilter: "blur(7px)", p: 1.5 }}
        >
            <Drawer
                onClose={() => setState({ ...state, drawer: false })}
                anchor='top' open={state.drawer}>

                <AppBar
                    component="div"
                    position="fixed"
                    indicatorColor="primary"
                    sx={{ bgcolor: (e) => convertHexToRgba(e.palette.primary.main, 0.03), backdropFilter: "blur(7px)" }}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to={`/`} onClick={() => setState({ ...state, drawer: false })} >
                                <ListItemIcon>
                                    <Home />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        {allPages && allPages.map((i, key) => {
                            return (
                                <ListItem key={key} disablePadding>
                                    <ListItemButton component={Link} to={`/page/${i.slug}`} onClick={() => setState({ ...state, drawer: false })} >
                                        <ListItemIcon>
                                            <AutoStories />
                                        </ListItemIcon>
                                        <ListItemText primary={i.title} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </AppBar>
            </Drawer>

            {web &&
                <Grid container direction={'row'} alignItems="center" justifyContent={'space-between'} spacing={2}>
                    <Grid item>
                        <CardActionArea sx={{ borderRadius: 2 }} onClick={() => history.push('/')} >
                            <CardMedia src={`/images/${web.logo}`} component={'img'} sx={{ height: 50, width: 50, borderRadius: 2 }} />
                        </CardActionArea>
                    </Grid>
                    <Grid item>
                        <Stack direction={'row'} spacing={2}>
                            <Popover
                                sx={{ bgcolor: (e) => convertHexToRgba(e.palette.primary.main, 0.03), backdropFilter: "blur(7px)" }}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Stack
                                    p={1} borderRadius={2} direction={'column'} spacing={2}>
                                    {langs.map((i, key) => {
                                        return (
                                            <CardActionArea
                                                onClick={() => {
                                                    localStorage.setItem('language', i.replace(".json", ""))
                                                    window.location.reload()
                                                }}
                                                key={key} >
                                                <Typography sx={{ p: 1 }} >{i.replace(".json", "")}</Typography>
                                            </CardActionArea>
                                        )
                                    })}
                                </Stack>
                            </Popover>

                            <IconButton aria-describedby={id} onClick={handleClick} >
                                <Translate />
                            </IconButton>

                            <IconButton onClick={() => history.push("/user")} >
                                <Login />
                            </IconButton>

                            {localStorage.getItem('theme') == 'dark' ?
                                <IconButton onClick={() => {
                                    localStorage.setItem("theme", "light")
                                    window.location.reload()
                                }} >
                                    <LightMode />
                                </IconButton> :
                                <IconButton onClick={() => {
                                    localStorage.setItem("theme", "dark")
                                    window.location.reload()
                                }} >
                                    <DarkMode />
                                </IconButton>}
                            <IconButton
                                onClick={() => setState({ ...state, drawer: true })}
                                size='large'>
                                <Menu sx={{ height: 35, width: 35 }} />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>}
        </AppBar>
    )
}

export default Header