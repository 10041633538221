import React from 'react'
import axios from 'axios'

const GetPlansPublic = () => {
    const [paymentGateways, setpaymentGateways] = React.useState("")
    async function getData() {
        const plans = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get-all-payment-method`)
        setpaymentGateways(plans.data.data)
    }
    React.useEffect(() => {
        getData()
    }, [])
    return { paymentGateways }
}

export default GetPlansPublic