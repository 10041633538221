import React from 'react'
import { Switch } from 'react-router-dom'
import { Alert, Snackbar, Box, LinearProgress } from '@mui/material'
import { GlobalContext } from './context/GlobalContext'
import { Helmet } from "react-helmet";
import PublicRoute from './routing/PublicRoute'
import AdminRoute from './routing/AdminRoute'
import UserRoute from './routing/UserRoute'
import AdminLogin from './admin/login/AdminLogin'
import AdminDashboard from './admin/AdminDashboard.js'
import UserLogin from './user/login/UserLogin'
import UserDashboard from './user/UserDashboard.js'
import { TranslateContext } from './context/TranslateContext';
import axios from 'axios';
import Checkout from './frontend/checkout/Checkout';
import GetWebPublic from './utils/GetWebPublic';
import PageComp from './frontend/home/components/PageComp';
import HomePage from './frontend/home/HomePage';
import PasswordRecoveryUser from './user/PasswordRecoveryUser'
import PasswordRecoveryAdmin from './admin/PasswordREcoveryAdmin';
import Error404 from './frontend/Error404';
import EmbedChatBot from './user/embid-chatbot/chatting/EmbedChatBot';

const HomeMainPage = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const LANGUAGE = React.useContext(TranslateContext)
    const { web } = GetWebPublic()

    React.useEffect(() => {
        const code = localStorage.getItem('language')
        // const localcode = local.slice(0, local.indexOf('.'))
        if (code) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get-one-translation?code=${code}`)
                .then((res) => {
                    if (res.data.notfound) {
                        localStorage.removeItem('language')
                        alert("Maybe translate file issue.")
                        window.location.reload()
                        return
                    }
                    LANGUAGE.setData({ ...res.data.data })
                })
                .catch((err) => console.log(err))
        } else {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get-all-translation-name`,)
                .then((lan) => {
                    localStorage.setItem('language', lan.data.data[0]?.replace(".json", ""))
                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get-one-translation?code=${lan.data.data[0]?.replace(".json", "")}`)
                        .then((res) => {
                            LANGUAGE.setData({ ...res.data.data })
                        })
                        .catch((err) => console.log(err))
                })
        }
    }, [])


    return (
        <Box sx={{ position: 'relative' }} color='text.primary' minHeight={'100vh'} bgcolor='background.default'>
            <div style={{ position: "absolute", top: 0, left: 0, right: 0, display: GLOBAL_CONTEXT.data.loading ? 'block' : 'none' }} >
                <LinearProgress
                    sx={{
                        background: "linear-gradient(to right, #ad5389, #3c1053)",
                        fontSize: "50px"
                    }}
                />
            </div>

            {web ?
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{web.app_name}</title>
                    <link rel="canonical" href={window.location.hostname} />
                    <meta name="description" content={web.meta} />
                    <link rel="icon" href={`/images/${web.logo}`} />

                </Helmet>
                :
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>loading...</title>
                </Helmet>}


            <Snackbar open={GLOBAL_CONTEXT.data.snack} autoHideDuration={4000} >
                <Alert severity={GLOBAL_CONTEXT.data.snack_success ? 'success' : 'error'}>{GLOBAL_CONTEXT.data.snack_msg}</Alert>
            </Snackbar>

            <div style={{
                opacity: GLOBAL_CONTEXT.data.loading ? 0.25 : 1,
                pointerEvents: GLOBAL_CONTEXT.data.loading ? "none" : "initial"
            }}>
                <Switch>
                    <PublicRoute path='/' exact component={HomePage} />
                    <AdminRoute path='/admin' exact component={AdminDashboard} />
                    <PublicRoute path='/admin/login' exact component={AdminLogin} />
                    <UserRoute path='/user' exact component={UserDashboard} />
                    <PublicRoute path='/user/login' exact component={UserLogin} />

                    <PublicRoute path='/user/checkout' exact component={Checkout} />
                    <PublicRoute path='/page/:slug' exact component={PageComp} />


                    <PublicRoute path='/chat/:uid/:bot' exact component={EmbedChatBot} />

                    <PublicRoute exact path="/recovery-user/:id" component={PasswordRecoveryUser} />
                    <PublicRoute exact path="/recovery-admin/:id" component={PasswordRecoveryAdmin} />

                    <PublicRoute path='*' component={Error404} />
                </Switch>
            </div>
        </Box>
    )
}

export default HomeMainPage

