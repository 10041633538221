import React from 'react'

export const ChatTempletContext = React.createContext(null)

export const ChatTempletProvider = (props) => {
    const [data, setData] = React.useState({
        categoryDialog: false,
        categoryData: []
    })

    return (
        <ChatTempletContext.Provider value={{ data, setData }}>
            {props.children}
        </ChatTempletContext.Provider>
    )
}

