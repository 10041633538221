import React from 'react'
import axios from 'axios'

const GetWebPublic = () => {
    const [web, setweb] = React.useState("")
    async function getData() {
        const plans = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get-web-public`)
        setweb(plans.data.data)
    }
    React.useEffect(() => {
        getData()
    }, [])
    return { web }
}

export default GetWebPublic 