import React from 'react'

export const ManageUserContext = React.createContext(null)

export const ManageUserProvider = (props) => {
    const [data, setData] = React.useState({
        userData: [],
        manageUserDialog: false,
        editUserData: {}
    })

    return (
        <ManageUserContext.Provider value={{ data, setData }}>
            {props.children}
        </ManageUserContext.Provider>
    )
}

