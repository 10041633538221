import React from 'react'
import { Box, Container, LinearProgress, Stack, Typography, Divider, Button } from '@mui/material'
import GetPlansPublic from '../../../user/subscription/GetPlansPublic'
import Slider from "react-slick";
import { Cancel, CheckCircleOutline, ShoppingCartCheckoutOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom'
import GetWebPublic from '../../../utils/GetWebPublic';
import './font.css'

const PriceComp = ({ data }) => {
    const { plansPublic } = GetPlansPublic()
    const { web } = GetWebPublic()

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: plansPublic.length > 3 ? 3 : plansPublic.length,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: null, // Remove the previous arrow
        nextArrow: null, // Remove the next arrow
        responsive: [
            {
                breakpoint: 800, // Set the breakpoint for mobile devices
                settings: {
                    slidesToShow: 1, // Set the number of slides to show at a time to 1
                    slidesToScroll: 1, // Set the number of slides to scroll at a time to 1
                }
            }
        ]
    };

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }


    function calculateDaysLeft(date) {
        const currentDate = new Date();
        const targetDate = new Date(date);
        const differenceMs = targetDate - currentDate;
        if (differenceMs < 0) {
            return "expired";
        }
        const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
        return daysLeft.toString() || "";
    }

    return (
        <Box pt={6} pb={6}
            sx={{ backgroundImage: 'url("/assets/bg2.png")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Container maxWidth='lg'>
                <Typography align='center' variant='h3' >{data.planForYourNeed}</Typography>
                <Box mt={4}>
                    {plansPublic ?
                        <Slider {...settings}>
                            {plansPublic.map((i, key) => (
                                <Box >
                                    <Box mr={2} key={key} borderRadius={6} sx={{ bgcolor: (e) => convertHexToRgba(e.palette.primary.main, 0.04) }} >
                                        <Box p={5} >
                                            <Stack direction={'column'} spacing={3}>
                                                <Stack alignItems={'center'} direction={'column'} spacing={0.5}>
                                                    <Typography className='grad' fontWeight={'bold'} variant='h5' >{i.plan_name}</Typography>
                                                    <Typography variant='body2' >{web?.currency_symbol}{i.cost}</Typography>
                                                    <Typography variant='caption' >{i.planexpire} {data.daysLeft}</Typography>
                                                </Stack>


                                                <Divider />
                                                <Stack direction={'column'} spacing={1}>
                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} />
                                                        <Typography variant='caption' >{i.gpt_words_limit} {data.gptWordsLimit}</Typography>
                                                    </Stack>



                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        {i.bard_access === 1 ?
                                                            <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                            <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                        <Typography variant='caption' >{i.bard_access === 1 ? null : data.no} {data.bardAccess}</Typography>
                                                    </Stack>

                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        {i.embed_chatbot_limit > 0 ?
                                                            <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                            <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                        <Typography variant='caption' >{i.embed_chatbot_limit > 0 ? i.embed_chatbot_limit : data.no} {data.embitaiBot}</Typography>
                                                    </Stack>

                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        {i.dalle_limit > 0 ?
                                                            <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                            <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                        <Typography variant='caption' >{i.dalle_limit > 0 ? i.dalle_limit : data.no} {data.dallEWordsLImit}</Typography>
                                                    </Stack>


                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        {i.wa_bot === 1 ?
                                                            <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                            <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                        <Typography variant='caption' >{i.wa_bot === 1 ? null : data.no} {data.aiBot}</Typography>
                                                    </Stack>

                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        {i.chat_in_app === 1 ?
                                                            <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                            <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                        <Typography variant='caption' >{i.chat_in_app === 1 ? null : data.no} {data.aiBotChat}</Typography>
                                                    </Stack>

                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        {i.wp_auto_bloging === 1 ?
                                                            <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                            <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                        <Typography variant='caption' >{i.wp_auto_bloging === 1 ? null : data.no} {data.wordpressAutoBlog}</Typography>
                                                    </Stack>

                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        {i.tts_words_limit > 0 ?
                                                            <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                            <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                        <Typography variant='caption' >{i.tts_words_limit > 1 ? i.tts_words_limit : data.no} {data.ttsLimit}</Typography>
                                                    </Stack>

                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                        {i.speech_to_text === 1 ?
                                                            <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                            <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                        <Typography variant='caption' >{i.speech_to_text === 1 ? null : data.no} {data.speechToText}</Typography>
                                                    </Stack>
                                                </Stack>

                                                <Stack spacing={1} direction={'column'}>
                                                    <Button
                                                        component={Link}
                                                        to={{
                                                            pathname: "/user/checkout",
                                                            state: i
                                                        }} sx={{ borderRadius: 2, textTransform: "none", boxShadow: 0 }} startIcon={<ShoppingCartCheckoutOutlined />} variant='contained' fullWidth >{data.subscribe}</Button>
                                                </Stack>

                                            </Stack>
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                            }
                        </Slider > : <LinearProgress />}
                </Box>
            </Container>
        </Box>
    )
}

export default PriceComp