import React from 'react'

export const WpContext = React.createContext(null)

export const WpProvider = (props) => {
    const [data, setData] = React.useState({

    })

    return (
        <WpContext.Provider value={{ data, setData }}>
            {props.children}
        </WpContext.Provider>
    )
}

