import React from 'react'
import GetAllPaymentMethodAdmin from '../../utils/GetAllPaymentMethodAdmin'
import { Box, LinearProgress, Stack, Typography } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import PaypalComp from './components/PaypalComp'
import PayStackComp from './components/PayStackComp'
import RazorpayComp from './components/RazorpayComp'
import ZarnipalComp from './components/ZarnipalComp'
import OfflineComp from './components/OfflineComp'
import InstamojoComp from './components/InstamojoComp'

const PaymentGateways = () => {
    const { paymentGateways } = GetAllPaymentMethodAdmin()
    const { data } = React.useContext(TranslateContext)
    function returnData(e) {
        const dta = paymentGateways.filter((i) => i.code == e)
        return dta
    }
    return (
        paymentGateways ?
            <Box mt={4}>
                <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                    <Typography variant='h5' fontWeight={'bold'}>{data.paymentGateways}</Typography>
                </Stack>
                <Stack direction={'column'} spacing={3} mt={4}>
                    <PaypalComp json={returnData('paypal')[0]} data={data} />
                    <PayStackComp json={returnData('paystack')[0]} data={data} />
                    <RazorpayComp json={returnData('razorpay')[0]} data={data} />
                    <ZarnipalComp json={returnData('zarnipal')[0]} data={data} />
                    <InstamojoComp json={returnData('instamojo')[0]} data={data} />
                    <OfflineComp json={returnData('offline')[0]} data={data} />
                </Stack>
            </Box> : <LinearProgress />
    )
}

export default PaymentGateways