import React from 'react'
import Dashboard from './dashboard/Dashboard'
import ManagePlans from './manage-plan/ManagePlans'
import PaymentGateways from './payment-gateways/PaymentGateways'
import ManageUser from './manage-users/ManageUser'
import ApiKeys from './api-keys/ApiKeys'
import ChatBotTemplet from './chat-templets/ChatBotTemplet'
import PingAdmin from './ping/PingAdmin'
import Orders from './orders/Orders'
import ManagePage from './manage-pages/ManagePage'
import Testimonial from './testimonial/Testimonial'
import FaqComp from './faq/FaqComp'
import Features from './features/Features'
import AppConfig from './app-config/AppConfig'
import Translation from './translation/Translation'


const DashboardPage = (props) => {
    return (
        <div>
            {props.page === 0 && <Dashboard />}
            {props.page === 1 && <ManageUser />}
            {props.page === 2 && <ManagePlans />}
            {props.page === 3 && <PaymentGateways />}
            {props.page === 4 && <ApiKeys />}
            {props.page === 5 && <ChatBotTemplet />}
            {props.page === 6 && <PingAdmin />}
            {props.page === 7 && <Orders />}
            {props.page === 8 && <ManagePage />}
            {props.page === 9 && <Testimonial />}
            {props.page === 10 && <FaqComp />}
            {props.page === 11 && <Features />}
            {props.page === 12 && <AppConfig />}
            {props.page === 13 && <Translation />}
        </div>
    )
}

export default DashboardPage