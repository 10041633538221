import React from 'react';
import { Box, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { TranslateContext } from '../../context/TranslateContext';
import { RecordVoiceOver, Save, VoiceOverOff } from '@mui/icons-material';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './style.css';
import GetsUserToken from '../../utils/GetsUserToken';
import { GlobalContext } from '../../context/GlobalContext';
import SpeechList from './SpeechList';
import { useHistory } from 'react-router-dom';

const SpeechToText = () => {
    const G = React.useContext(GlobalContext);
    const history = useHistory()
    const { data } = React.useContext(TranslateContext);
    const { userData } = GetsUserToken();
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    async function uploadText() {
        const res = await G.hitAxios({
            path: '/api/stt/save_text',
            admin: false,
            post: true,
            obj: { text: transcript }
        });
        if (res.data.success) {
            history.go(0)
        }
    }



    return (
        <div>
            <Box mb={4} mt={4}>
                <Stack alignItems="center" spacing={2} direction="row" justifyContent="space-between">
                    <Stack direction="column" justifyContent="space-between">
                        <Typography variant="h5" fontWeight="bold">
                            {data.textToSpeech}
                        </Typography>
                        <Typography variant="caption" color="gray">
                            {data.sttDes}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
            {userData?.speech_to_text}
            {userData ? (
                userData?.plan && JSON.parse(userData.plan).speech_to_text === 1 ? (
                    <Box p={1}>
                        <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                            {listening ? (
                                <IconButton className="recording-effect" onClick={SpeechRecognition.stopListening} size="large" sx={{ p: 4 }}>
                                    <VoiceOverOff sx={{ height: 50, width: 50, color: 'red' }} />
                                </IconButton>
                            ) : (
                                <IconButton color='info' onClick={SpeechRecognition.startListening} size="large" sx={{ p: 4 }}>
                                    <RecordVoiceOver sx={{ height: 60, width: 60 }} />
                                </IconButton>
                            )}
                            <Typography variant="caption" color="gray">
                                {data.speak}
                            </Typography>
                            <Typography variant="caption" color="orange">
                                {data.speakDes}
                            </Typography>

                            <Stack direction="row" spacing={2}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        bgcolor: 'rgba(136, 136, 136, 0.06)',
                                        padding: transcript.length > 0 ? 1 : 0,
                                        borderRadius: 2
                                    }}
                                >
                                    {transcript}
                                </Typography>

                                {transcript && (
                                    <IconButton onClick={uploadText}>
                                        <Save />
                                    </IconButton>
                                )}
                            </Stack>
                        </Stack>
                    </Box>
                ) : (
                    <Box p={1}>
                        <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                            {listening ? (
                                <IconButton disabled className="recording-effect" size="large" sx={{ p: 4 }}>
                                    <VoiceOverOff sx={{ height: 50, width: 50, color: 'red' }} />
                                </IconButton>
                            ) : (
                                <IconButton disabled size="large" sx={{ p: 4 }}>
                                    <RecordVoiceOver sx={{ height: 60, width: 60 }} />
                                </IconButton>
                            )}
                            <Typography variant="caption" color="orange">
                                {data.speakDisabled}
                            </Typography>
                        </Stack>
                    </Box>
                )
            ) : (
                <LinearProgress />
            )}

            <SpeechList data={data} G={G} />
        </div>
    );
};

export default SpeechToText;
