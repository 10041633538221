import React from 'react'
import { Box, Button, Chip, Dialog, Divider, IconButton, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { Add, Close, Delete } from '@mui/icons-material'
import { GlobalContext } from '../../context/GlobalContext'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ManagePlans = () => {
    const { data } = React.useContext(TranslateContext)
    const G = React.useContext(GlobalContext)
    const [dialog, setDialog] = React.useState(false)
    const [state, setState] = React.useState({
        dalle: false,
        dalle_size: "250x250",
        wa_bot: false,
        wp_auto_bloging: false,
        chat_in_app: false,
        text_to_speech: false,
        speech_to_text: false,
        allow_text_to_speech_api: false,
        embed_chatbot: false
    })
    const [plan, setPlan] = React.useState([])

    function daysFromTodayToDate(date) {
        if (!date) {
            return 0;
        }

        const today = new Date();
        const targetDate = new Date(date);
        const timeDiff = targetDate.getTime() - today.getTime();
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return daysDiff;
    }

    async function addPLan() {
        const res = await G.hitAxios({
            path: "/api/plan/add",
            admin: true,
            post: true,
            obj: state
        })
        console.log(res.data)
        if (res.data.success) {
            getData()
            setDialog(false)
        }
    }

    async function getData() {
        const res = await G.hitAxios({
            path: "/api/plan/get_plans",
            admin: false,
            post: false,
            token_user: ""
        })
        if (res.data.success) {
            setPlan(res.data.data)
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    async function delFin(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/plan/del_plan",
                admin: true,
                post: true,
                obj: { id: e }
            })
            if (res.data.success) {
                getData()
            }
        }
    }


    const inputProps = {
        style: {
            borderRadius: 10
        }
    }

    return (
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.managePlans}</Typography>
                <Button
                    onClick={() => setDialog(true)}
                    startIcon={<Add />}
                    sx={{ textTransform: "none", borderRadius: 2 }} variant='contained' >{data.addAPlan}</Button>
            </Stack>

            <Dialog
                fullWidth
                onClose={(e) => setDialog(false)}
                open={dialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => setDialog(false)} >
                            <Close />
                        </IconButton>
                        <Typography>{data.addAPlan}</Typography>
                    </Stack>
                    <Divider />
                    <Stack direction={'column'} mt={2} spacing={2}>
                        <TextField
                            onChange={(e) => setState({ ...state, plan_name: e.target.value })}
                            size='small' InputProps={inputProps} label={data.planName} fullWidth />

                        <TextField
                            onChange={(e) => setState({ ...state, cost: e.target.value })}
                            size='small' type='number' InputProps={inputProps} label={data.cost} fullWidth />


                        <TextField
                            onChange={(e) => setState({ ...state, gpt_words_limit: e.target.value })}
                            size='small' type='number' InputProps={inputProps} label={data.gptWordsLimit} fullWidth />

                        <Stack direction={'column'} spacing={1}>
                            <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography variant='caption' >{data.allowDallE}</Typography>
                                <Switch checked={state.dalle}
                                    onChange={(e) => setState({ ...state, dalle: e.target.checked })}
                                />
                            </Stack>
                            {state.dalle &&
                                <Stack direction={'column'} spacing={1}>
                                    <TextField
                                        onChange={(e) => setState({ ...state, dalle_limit: e.target.value })}
                                        size='small' type='number' InputProps={inputProps} label={data.dallELimit} fullWidth />
                                    <Typography color='gray' variant='caption' >{data.dalleSize}</Typography>
                                    <Stack direction={'row'} spacing={2}>
                                        <Chip
                                            variant={state.dalle_size == "250x250" ? 'filled' : 'outlined'}
                                            onClick={() => setState({ ...state, dalle_size: "250x250" })}
                                            label="250x250" />
                                        <Chip
                                            variant={state.dalle_size == "512x512" ? 'filled' : 'outlined'}
                                            onClick={() => setState({ ...state, dalle_size: "512x512" })}
                                            label="512x512" />
                                        <Chip
                                            variant={state.dalle_size == "1024x1024" ? 'filled' : 'outlined'}
                                            onClick={() => setState({ ...state, dalle_size: "1024x1024" })}
                                            label="1024x1024" />
                                    </Stack>
                                </Stack>}
                        </Stack>

                        <Divider />

                        <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                            <Typography variant='caption' >{data.allowWABot}</Typography>
                            <Switch checked={state.wa_bot}
                                onChange={(e) => setState({ ...state, wa_bot: e.target.checked })}
                            />
                        </Stack>

                        <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                            <Typography variant='caption' >{data.wordpressAutoBlog}</Typography>
                            <Switch checked={state.wp_auto_bloging}
                                onChange={(e) => setState({ ...state, wp_auto_bloging: e.target.checked })}
                            />
                        </Stack>


                        <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                            <Typography variant='caption' >{data.allowChatInApp}</Typography>
                            <Switch checked={state.chat_in_app}
                                onChange={(e) => setState({ ...state, chat_in_app: e.target.checked })}
                            />
                        </Stack>

                        <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                            <Typography variant='caption' >{data.bardAccess}</Typography>
                            <Switch checked={state.bard_access}
                                onChange={(e) => setState({ ...state, bard_access: e.target.checked })}
                            />
                        </Stack>

                        <Stack direction={'column'} spacing={1}>
                            <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography variant='caption' >{data.textToSpeech}</Typography>
                                <Switch checked={state.text_to_speech}
                                    onChange={(e) => setState({ ...state, text_to_speech: e.target.checked })}
                                />
                            </Stack>
                            {state.text_to_speech &&
                                <TextField
                                    onChange={(e) => setState({ ...state, tts_words_limit: e.target.value })}
                                    size='small' type='number' InputProps={inputProps} label={data.ttsLimit} fullWidth />}
                        </Stack>

                        <Stack direction={'column'} spacing={1}>
                            <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography variant='caption' >{data.embidChatbot}</Typography>
                                <Switch checked={state.embed_chatbot}
                                    onChange={(e) => setState({ ...state, embed_chatbot: e.target.checked })}
                                />
                            </Stack>
                            {state.embed_chatbot &&
                                <TextField
                                    onChange={(e) => setState({ ...state, embed_chatbot_limit: e.target.value })}
                                    size='small' type='number' InputProps={inputProps} label={data.embitBotLimit} fullWidth />}
                        </Stack>

                        <Stack direction={'column'} spacing={1}>
                            <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography variant='caption' >{data.speechToText}</Typography>
                                <Switch checked={state.speech_to_text}
                                    onChange={(e) => setState({ ...state, speech_to_text: e.target.checked })}
                                />
                            </Stack>
                        </Stack>



                        <TextField
                            onChange={(e) => setState({ ...state, planexpire: e.target.value })}
                            size='small' type='number' InputProps={inputProps} label={data.planExpInDays} fullWidth />


                        <Button
                            onClick={addPLan}
                            disabled={state.plan_name && state.cost && parseInt(state.gpt_words_limit) > 0 && state.planexpire ? false : true}
                            variant='contained'
                            sx={{ borderRadius: 2, boxShadow: 0 }}
                        >{data.submit}</Button>

                    </Stack>
                </Box>
            </Dialog>



            <Box mt={4}>
                {plan &&
                    <TableContainer
                        sx={{ borderRadius: 4, bgcolor: 'action.hover', p: 2 }}
                    >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.planName}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.cost}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.gptWordsLimit}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.dallELimit}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.dallESize}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.waBot}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.wordpressAutoBlog}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.allowChatInApp}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.ttsLimit}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.sttLimit}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.bardAccess}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.embidChatbot}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.embitBotLimit}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.planExpInDays}</TableCell>

                                    <TableCell sx={{ fontWeight: 'bold' }}>{data.del}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {plan.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.plan_name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.cost}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.gpt_words_limit}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.dalle_limit > 1 ? row.dalle_limit : data.no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.dalle_size}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.wa_bot == 1 ? data.yes : data.no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.wp_auto_bloging == 1 ? data.yes : data.no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.chat_in_app == 1 ? data.yes : data.no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.tts_words_limit || data.no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.speech_to_text === 1 ? data.yes : data.no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.bard_access === 1 ? data.yes : data.no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.embed_chatbot_limit > 1 ? row.embed_chatbot_limit : data.no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.planexpire}
                                        </TableCell>


                                        <TableCell component="th" scope="row">
                                            <IconButton
                                                onClick={() => delFin(row.id)} color='error' >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}

            </Box>

        </Box>

    )
}

export default ManagePlans