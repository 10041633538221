import { Box, Button, Checkbox, Dialog, Divider, OutlinedInput, Grid, Chip, FormControl, IconButton, InputLabel, LinearProgress, ListItemText, MenuItem, Select, Slider, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { Error, Close, Delete, Edit, Launch } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { GlobalContext } from '../../../context/GlobalContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import moment from 'moment'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const AutoBlog = ({ data }) => {
    const [dialog, setDialog] = React.useState(false)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const G = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        blog_count: 2,
        catArr: [],
        postStatus: 'publish'
    })
    const [cats, setCates] = React.useState([])
    const inputPrompt = { style: { borderRadius: 10 } }
    const [wPPost, setWpPost] = React.useState([])

    async function getCate() {
        const res = await G.hitAxios({
            path: "/api/wp/get_categories",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            setCates(res.data.data.length > 0 ? res.data.data : [])
        }
    }

    React.useEffect(() => {
        if (!token) return
        getCate()
        getWP()
    }, [token])

    async function sendBlogs() {
        const res = await G.hitAxios({
            path: "/api/wp/write_blogs",
            admin: false,
            post: true,
            obj: state
        })
    }

    async function getWP() {
        const res = await G.hitAxios({
            path: "/api/wp/get_wp_post",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            setWpPost(res.data.data)
        }
    }

    async function delPost(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/wp/del_post",
                post: true,
                admin: false,
                obj: { id: e }
            })
            if (res.data.success) {
                getWP()
            }
        }
    }



    return (
        <Box>
            <Dialog
                fullWidth
                onClose={(e) => setDialog(false)}
                open={dialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => setDialog(false)} >
                            <Close />
                        </IconButton>
                        <Typography>{data.writeBlog}</Typography>
                    </Stack>
                    <Divider />

                    <Stack direction={'column'} mt={2} spacing={2}>
                        <TextField
                            onChange={(e) => setState({ ...state, topic: e.target.value })}
                            multiline
                            rows={3}
                            placeholder={data.enterTopicHelper}
                            fullWidth label={data.enterTopic} size='small' InputProps={inputPrompt} />


                        {cats ?
                            <FormControl fullWidth >
                                <InputLabel size='small' id="demo-multiple-checkbox-label">{data.selectCategories}</InputLabel>
                                <Select
                                    sx={{ borderRadius: 2 }}
                                    size='small'
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={state.catArr}
                                    onChange={(e) => {
                                        setState({ ...state, catArr: e.target.value, });
                                    }}
                                    input={<OutlinedInput label={data.selectCategories} />}
                                    renderValue={(selected) => selected?.map((i => <Chip sx={{ mr: 1 }} label={i.name} />))}
                                    MenuProps={MenuProps}
                                >
                                    {cats?.map((i, key) => (
                                        <MenuItem key={key} value={i}>
                                            <Checkbox checked={state.catArr.indexOf(i) > -1} />
                                            <ListItemText primary={i.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : <LinearProgress />}

                        <TextField
                            onChange={(e) => setState({ ...state, words_limit: e.target.value })}
                            type='number'
                            helperText={data.enterWordsHelper}
                            fullWidth label={data.enterWords} size='small' InputProps={inputPrompt} />

                        <TextField
                            onChange={(e) => setState({ ...state, language: e.target.value })}
                            placeholder={data.english}
                            fullWidth label={data.enterlangauge} size='small' InputProps={inputPrompt} />

                        <Stack direction={'column'} spacing={1}>
                            <Typography variant='caption' >{data.postStatus}</Typography>
                            <Stack direction={'row'} spacing={3}>
                                <Chip
                                    color='info'
                                    onClick={() => setState({ ...state, postStatus: 'publish' })}
                                    variant={state.postStatus === 'publish' ? 'filled' : 'outlined'}
                                    label={data.publish} sx={{ width: '100%' }} />
                                <Chip
                                    color='secondary'
                                    onClick={() => setState({ ...state, postStatus: 'draft' })}
                                    variant={state.postStatus === 'draft' ? 'filled' : 'outlined'}
                                    label={data.draft} sx={{ width: '100%' }} />
                            </Stack>
                        </Stack>

                        <Stack direction={'column'}>
                            <Typography variant='caption' color='gray' >{data.howManyBlogsYouWant} ({state.blog_count || 0})</Typography>
                            <Slider
                                aria-label="Temperature"
                                defaultValue={state.blog_count}
                                value={state.blog_count}
                                onChange={(e) => setState({ ...state, blog_count: e.target.value })}
                                valueLabelDisplay="auto"
                                step={1}
                                mark
                                min={1}
                                max={50}
                            />
                        </Stack>

                        <LoadingButton
                            loading={G.data.loading}
                            onClick={sendBlogs}
                            disabled={state.topic && state.words_limit > 0 && state.language && state.catArr.length > 0 ? false : true}
                            variant='contained'
                            sx={{ borderRadius: 2, textTransform: 'none' }}
                        >{data.startWriting}</LoadingButton>
                    </Stack>
                </Box>
            </Dialog>

            <Stack mb={2} direction={'row'} justifyContent={'flex-end'}>
                <Button
                    onClick={() => setDialog(true)}
                    startIcon={<Edit />}
                    variant='contained'
                    sx={{ textTransform: "none", borderRadius: 2 }}
                >{data.writeBlog}</Button>
            </Stack>

            <Stack alignItems={'center'} direction={'row'} spacing={1} mt={2}>
                <Error sx={{ color: 'orange', height: 20, width: 20 }} />
                <Typography variant='caption' color={'orange'} >{data.linkWar}</Typography>
            </Stack>

            <Box borderRadius={4} bgcolor={'action.hover'} mt={2}>
                {wPPost ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...wPPost].reverse()}
                            columns={[
                                { headerName: data.title, field: 'title', flex: 1 },
                                { headerName: data.wpPostId, field: 'post_id', flex: 1 },
                                {
                                    headerName: data.link, field: 'link', flex: 1, renderCell: dataa => <IconButton
                                        onClick={() => window.open(dataa.row.link)}>
                                        <Launch />
                                    </IconButton>
                                },
                                {
                                    headerName: data.link, field: 'id', flex: 1, renderCell: dataa => <IconButton color='error'
                                        onClick={() => delPost(dataa.row.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                },
                                {
                                    headerName: data.date, field: 'uid', flex: 1, renderCell: dataa => moment(dataa.row.createdAt).format("DD MMMM YYYY hh:mm:A")
                                },
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>
        </Box>
    )
}

export default AutoBlog