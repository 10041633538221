import React from 'react'
import { Typography, Box, Button, Dialog, Toolbar, IconButton, AppBar, Stack, TextField, Container } from '@mui/material'
import { Add, Close, Delete, OpenInNew } from '@mui/icons-material'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab';
import { GlobalContext } from '../../context/GlobalContext';
import { TranslateContext } from '../../context/TranslateContext';
import moment from 'moment'

const ManagePage = () => {
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        dialog: false
    })
    const [blogs, setBlogs] = React.useState("")
    const G = React.useContext(GlobalContext)

    function createSlug(title) {
        const slug = title
            .toLowerCase() // Convert to lowercase
            .replace(/\s+/g, '-') // Replace spaces with dashes
            .replace(/[^a-z0-9-]/g, '') // Remove non-alphanumeric characters and dashes
            .replace(/--+/g, '-') // Replace multiple dashes with a single dash
            .replace(/^-+|-+$/g, ''); // Remove leading and trailing dashes

        return slug;
    }

    async function addBlog() {
        const data = await G.hitAxios({
            path: "/api/admin/add-page",
            post: true,
            admin: true,
            obj: state
        })
        if (data.data.success) {
            getPages()
            setState({ ...state, dialog: false })
        }
    }

    async function getPages() {
        const data = await G.hitAxios({
            path: "/api/admin/get-all-page",
            post: false,
            admin: false,

        })
        setBlogs(data.data.data)
    }

    React.useEffect(() => {
        getPages()
    }, [])

    async function delPage(e) {
        if (window.confirm("Are you sure?")) {
            const resp = await G.hitAxios({
                path: "/api/admin/del-page",
                post: true,
                admin: true,
                obj: { id: e }
            })
            if (resp.data.success) {
                getPages()
            }
        }
    }


    return (
        <Box mt={4}>

            <Dialog fullScreen open={state.dialog} >
                <AppBar position="static">
                    <Toolbar>
                        <Stack alignItems='center' direction='row' spacing={2}>
                            <IconButton onClick={() => setState({ ...state, dialog: false })} >
                                <Close />
                            </IconButton>
                            <Typography fontWeight='bold' >{data.addNewPage}</Typography>
                        </Stack>
                    </Toolbar>
                </AppBar>


                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={2} mt={2}>
                        <TextField
                            onChange={(e) => setState({ ...state, title: e.target.value, slug: createSlug(e.target.value) })}
                            helperText={state.slug}
                            size='small' label={data.enterTitle} fullWidth InputProps={{
                                style: {
                                    borderRadius: 10
                                }
                            }} />

                        <TextField
                            onChange={(e) => setState({ ...state, meta: e.target.value })}
                            size='small' multiline rows={2} label={data.meta} fullWidth InputProps={{
                                style: {
                                    borderRadius: 10
                                }
                            }} />

                        <ReactQuill
                            style={{ backgroundColor: "white", color: 'black' }}
                            theme="snow" onChange={(e) => setState({ ...state, content: e })} />


                        <LoadingButton
                            onClick={addBlog}
                            loading={G.data.loading}
                            disabled={state.title && state.meta && state.content ? false : true}
                            size='small'
                            variant='contained'
                            sx={{ borderRadius: 2, boxShadow: 0 }} >{data.submit}</LoadingButton>

                    </Stack>
                </Container>
            </Dialog>

            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.managePage}</Typography>
                <Button
                    onClick={() => setState({ ...state, dialog: true })}
                    startIcon={<Add />} sx={{ boxShadow: 0, borderRadius: 2, textTransform: "none" }} variant='contained' >{data.addNewPage}</Button>
            </Stack>

            <Box mt={4}>
                <Stack direction={'column'} spacing={2}>
                    {blogs && blogs.map((i, key) => {
                        return (
                            <Box key={key} borderRadius={4} p={3} sx={{ background: "linear-gradient(90deg, rgba(203, 16, 224, 0.1951374299719888) 0%, rgba(40, 50, 157, 0.29037552521008403) 50%, rgba(35, 189, 196, 0.49037552521008403) 100%)" }}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                    <Stack direction={'column'}>
                                        <Typography fontWeight={'bold'} >{i.title.length > 20 ? i.title.slice(0, 20) + "..." : i.title}</Typography>
                                        <Typography variant='caption' color='gray' >{moment(i.createdAt).format("DD/MM/YY")}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} spacing={2}>
                                        <IconButton onClick={() => window.open(`/page/${i.slug}`)}>
                                            <OpenInNew />
                                        </IconButton>
                                        <IconButton onClick={() => delPage(i.id)}>
                                            <Delete color='error' />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>
            </Box>
        </Box>
    )
}

export default ManagePage