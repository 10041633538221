import React from 'react'
import { Box, Stack, Typography, LinearProgress, Divider, Button } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import GetPlansPublic from '../../utils/GetPlansPublic'
import Slider from "react-slick";
import GetUserToken from '../../utils/GetsUserToken';
import GetWebPublic from '../../utils/GetWebPublic';
import { Cancel, CheckCircleOutline, ShoppingCartCheckoutOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom';


const Subscription = () => {
    const { data } = React.useContext(TranslateContext)
    const { plansPublic } = GetPlansPublic()
    const { userData } = GetUserToken()
    const { web } = GetWebPublic()

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: plansPublic.length > 3 ? 3 : plansPublic.length,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: null, // Remove the previous arrow
        nextArrow: null, // Remove the next arrow
        responsive: [
            {
                breakpoint: 800, // Set the breakpoint for mobile devices
                settings: {
                    slidesToShow: 1, // Set the number of slides to show at a time to 1
                    slidesToScroll: 1, // Set the number of slides to scroll at a time to 1
                }
            }
        ]
    };

    function calculateDaysLeft(date) {
        const currentDate = new Date();
        const targetDate = new Date(date);
        const differenceMs = targetDate - currentDate;
        if (differenceMs < 0) {
            return "expired";
        }
        const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
        return daysLeft.toString() || "";
    }

    return (
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.subscription}</Typography>
            </Stack>

            <Box mt={4}>
                {plansPublic ?
                    <Slider {...settings}>
                        {plansPublic.map((i, key) => (
                            <Box pr={2}>
                                <Box key={key} borderRadius={6} bgcolor={'action.hover'}>
                                    <Box p={5} >
                                        <Stack direction={'column'} spacing={3}>
                                            <Stack alignItems={'center'} direction={'column'} spacing={0.5}>
                                                <Typography fontWeight={'bold'} variant='h5' >{i.plan_name}</Typography>
                                                <Typography variant='body2' >{web?.currency_symbol}{i.cost}</Typography>
                                                <Typography variant='caption' >{i.planexpire} {data.daysLeft}</Typography>
                                            </Stack>


                                            <Divider />
                                            <Stack direction={'column'} spacing={1}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} />
                                                    <Typography variant='caption' >{i.gpt_words_limit} {data.gptWordsLimit}</Typography>
                                                </Stack>

                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    {i.bard_access === 1 ?
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                    <Typography variant='caption' >{i.bard_access === 1 ? null : data.no} {data.bardAccess}</Typography>
                                                </Stack>



                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    {i.embed_chatbot_limit > 0 ?
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                    <Typography variant='caption' >{i.embed_chatbot_limit > 0 ? i.embed_chatbot_limit : data.no} {data.embitaiBot}</Typography>
                                                </Stack>

                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    {i.dalle_limit > 0 ?
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                    <Typography variant='caption' >{i.dalle_limit > 0 ? i.dalle_limit : data.no} {data.dallEWordsLImit}</Typography>
                                                </Stack>


                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    {i.wa_bot === 1 ?
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                    <Typography variant='caption' >{i.wa_bot === 1 ? null : data.no} {data.aiBot}</Typography>
                                                </Stack>

                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    {i.chat_in_app === 1 ?
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                    <Typography variant='caption' >{i.chat_in_app === 1 ? null : data.no} {data.aiBotChat}</Typography>
                                                </Stack>

                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    {i.wp_auto_bloging === 1 ?
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                    <Typography variant='caption' >{i.wp_auto_bloging === 1 ? null : data.no} {data.wordpressAutoBlog}</Typography>
                                                </Stack>

                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    {i.tts_words_limit > 0 ?
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                    <Typography variant='caption' >{i.tts_words_limit > 1 ? i.tts_words_limit : data.no} {data.ttsLimit}</Typography>
                                                </Stack>

                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    {i.speech_to_text === 1 ?
                                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                                    <Typography variant='caption' >{i.speech_to_text === 1 ? null : data.no} {data.speechToText}</Typography>
                                                </Stack>
                                            </Stack>

                                            <Stack spacing={1} direction={'column'}>
                                                <Button
                                                    component={Link}
                                                    to={{
                                                        pathname: "/user/checkout",
                                                        state: i
                                                    }} sx={{ borderRadius: 2, textTransform: "none", boxShadow: 0 }} startIcon={<ShoppingCartCheckoutOutlined />} variant='contained' fullWidth >{data.subscribe}</Button>
                                            </Stack>

                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>
                        ))
                        }
                    </Slider > : <LinearProgress />}
            </Box>

            <Box mt={4} mb={4}>
                <Divider />
            </Box>



            {userData ?
                userData.plan ?
                    <Box width={350} mt={2} p={4} borderRadius={5} border={1}>
                        <Stack direction={'column'} spacing={3}>
                            <Stack alignItems={'center'} direction={'column'} spacing={0.5}>
                                <Typography fontWeight={'bold'} variant='h5' >{JSON.parse(userData.plan).plan_name}</Typography>
                                <Typography variant='body2' >{web?.currency_symbol}{JSON.parse(userData.plan).cost}</Typography>
                                <Typography variant='caption' >{calculateDaysLeft(userData.planexpire)} {data.daysLeft}</Typography>
                            </Stack>

                            <Divider />
                            <Stack direction={'column'} spacing={1}>
                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} />
                                    <Typography variant='caption' >{JSON.parse(userData.plan).gpt_words_limit} {data.gptWordsLimit}</Typography>
                                </Stack>



                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    {JSON.parse(userData.plan).bard_access === 1 ?
                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                    <Typography variant='caption' >{JSON.parse(userData.plan).bard_access === 1 ? null : data.no} {data.bardAccess}</Typography>
                                </Stack>

                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    {JSON.parse(userData.plan).embed_chatbot_limit > 0 ?
                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                    <Typography variant='caption' >{JSON.parse(userData.plan).embed_chatbot_limit > 0 ? JSON.parse(userData.plan).embed_chatbot_limit : data.no} {data.embitaiBot}</Typography>
                                </Stack>

                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    {JSON.parse(userData.plan).dalle_limit > 0 ?
                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                    <Typography variant='caption' >{JSON.parse(userData.plan).dalle_limit > 0 ? JSON.parse(userData.plan).dalle_limit : data.no} {data.dallEWordsLImit}</Typography>
                                </Stack>


                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    {JSON.parse(userData.plan).wa_bot === 1 ?
                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                    <Typography variant='caption' >{JSON.parse(userData.plan).wa_bot === 1 ? null : data.no} {data.aiBot}</Typography>
                                </Stack>

                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    {JSON.parse(userData.plan).chat_in_app === 1 ?
                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                    <Typography variant='caption' >{JSON.parse(userData.plan).chat_in_app === 1 ? null : data.no} {data.aiBotChat}</Typography>
                                </Stack>

                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    {JSON.parse(userData.plan).wp_auto_bloging === 1 ?
                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                    <Typography variant='caption' >{JSON.parse(userData.plan).wp_auto_bloging === 1 ? null : data.no} {data.wordpressAutoBlog}</Typography>
                                </Stack>

                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    {JSON.parse(userData.plan).tts_words_limit > 0 ?
                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                    <Typography variant='caption' >{JSON.parse(userData.plan).tts_words_limit > 1 ? JSON.parse(userData.plan).tts_words_limit : data.no} {data.ttsLimit}</Typography>
                                </Stack>

                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    {JSON.parse(userData.plan).speech_to_text === 1 ?
                                        <CheckCircleOutline color='success' sx={{ height: 18, width: 18 }} /> :
                                        <Cancel color='warning' sx={{ height: 18, width: 18 }} />}
                                    <Typography variant='caption' >{JSON.parse(userData.plan).speech_to_text === 1 ? null : data.no} {data.speechToText}</Typography>
                                </Stack>
                            </Stack>


                            <Divider />
                            <Stack direction={'column'} spacing={1}>
                                <Typography variant='caption' >{userData.gpt_words_limit > 0 ? userData.gpt_words_limit : 0} {data.gptWordsLimit}</Typography>
                                <Typography variant='caption' >{userData.dalle_limit > 0 ? userData.dalle_limit : 0} {data.dallELimit}</Typography>
                                <Typography variant='caption' >{userData.tts_words_limit > 0 ? userData.tts_words_limit : 0} {data.ttsLimit}</Typography>
                            </Stack>

                        </Stack>
                    </Box> : <>
                        <Typography color={'gray'} >{data.youDontHavePlanYet}</Typography>
                    </> :
                <LinearProgress />}
        </Box>
    )
}

export default Subscription