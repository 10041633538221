import React from 'react'
import { Box, Container, Typography, Stack, Button } from '@mui/material'
import './font.css'
import { Code } from '@mui/icons-material';
import { Link } from 'react-router-dom'

const EmbedChatBot = ({ data }) => {

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }


    return (
        <Box pt={6} pb={6}
            sx={{ backgroundImage: 'url("/assets/bg2.png")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Container maxWidth='lg'>
                <Stack spacing={2} direction={'column'} alignItems={'center'}>
                    <Typography className='grad' align='center' variant='h3' >{data.embedWebTitle}</Typography>

                    <Code sx={{ height: 200, width: 200 }} />

                    <Button
                        component={Link}
                        to="/user"
                        variant='contained'
                        sx={{ textTransform: 'none', borderRadius: 4, p: 1.5, boxShadow: 0 }}
                    >{data.checkNow}</Button>
                </Stack>

            </Container>
        </Box>
    )
}

export default EmbedChatBot