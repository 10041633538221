import React from 'react'
import { Box, Typography, Button, Stack, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Grid, CardActionArea, CardMedia, Tooltip, Dialog, IconButton } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { AutoFixHigh, CropOriginal, Download } from '@mui/icons-material'
import { GlobalContext } from '../../context/GlobalContext'
import GetsUserToken from '../../utils/GetsUserToken'

const AiImages = () => {
    const G = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        aS: false,
    })
    const { userData } = GetsUserToken()

    const [dialog, setDialog] = React.useState(false)
    const [imgs, setImgs] = React.useState([])

    async function getImages() {
        const res = await G.hitAxios({
            path: "/api/aiimg/get_aiimage",
            admin: false,
            post: false,
            token_user: token
        })
        setImgs(res.data.data)
    }

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }


    const artStyles = [
        'none',
        '3D Render',
        'Pixel Art',
        'Sticker Art',
        'Realistic',
        'Impressionist',
        'Abstract',
        'Surreal',
        'Minimalistic',
        'Pop Art',
        'Watercolor',
        'Comic Book',
        'Vintage',
        'Cyberpunk',
        'Gothic',
        'Graffiti'
    ];
    const lightingStyles = [
        'none',
        'Warm',
        'Cold',
        'Golden Hour',
        'Blue Hour',
        'Studio Lighting',
        'Neon',
        'Natural Light',
        'Soft Light',
        'Harsh Light',
        'Backlighting',
        'Low Key',
        'High Key',
        'Candlelight',
        'Firelight',
        'Moonlight'
    ];
    const moods = [
        'none',
        'Aggressive',
        'Angry',
        'Boring',
        'Bright',
        'Calm',
        'Cheerful',
        'Dark',
        'Energetic',
        'Gloomy',
        'Happy',
        'Mysterious',
        'Peaceful',
        'Romantic',
        'Serene',
        'Spooky'
    ];

    React.useEffect(() => {
        if (!token) return
        getImages()
    }, [token])

    async function genImages() {
        const res = await G.hitAxios({
            path: "/api/aiimg/genrate",
            post: true,
            admin: false,
            obj: state
        })
        if (res.data.success) {
            getImages()
        }
    }

    return (
        <Box mt={4} >
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Typography variant='h5' fontWeight={'bold'}>{data.aiImages}</Typography>
                    <Box
                        sx={{ bgcolor: (t) => convertHexToRgba(t.palette.primary.main, 0.1) }}
                        borderRadius={3} p={1} bgcolor={'action.hover'}>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <CropOriginal sx={{ height: 20, width: 20, color: (t) => t.palette.primary.main }} />
                            {userData &&
                                <Typography fontSize={12} >{userData?.dalle_limit?.toLocaleString()} / {userData.plan ? JSON.parse(userData.plan).dalle_limit?.toLocaleString() : 0} <a style={{ fontWeight: 'bold' }} >{data.imagesLeft}</a>
                                </Typography>}
                        </Stack>
                    </Box>
                </Stack>
            </Stack>

            <Box mt={4}>
                <TextField
                    onChange={(e) => setState({ ...state, prompt: e.target.value })}
                    InputProps={{
                        style: { borderRadius: 20 },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    disabled={state.prompt ? false : true}
                                    onClick={genImages}
                                    startIcon={<AutoFixHigh />}
                                    size='large'
                                    color='inherit'
                                    variant='contained'
                                    sx={{
                                        borderRadius: 10, boxShadow: 0,
                                        textTransform: 'none',
                                        background: "linear-gradient(90deg, rgba(40,50,157,0.4) 0%, rgba(203,16,224,0.3) 100%)"
                                    }}
                                >{data.generate}</Button>
                            </InputAdornment>
                        ),
                    }}
                    helperText={window.innerWidth < 400 && data.genAiImgLabel}
                    label={window.innerWidth > 400 && data.genAiImgLabel} fullWidth size='large' />

                <Box mt={1} p={1}>
                    {state.aS ?
                        <>
                            <Grid container spacing={2} direction={'row'}>
                                <Grid item xs={6} sm={3} lg={3}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">{data.artStyle}</InputLabel>
                                        <Select
                                            sx={{ borderRadius: 2 }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={data.artStyle}
                                            value={state.artStyles}
                                            onChange={(e) => setState({ ...state, artStyles: e.target.value })}
                                        >
                                            {artStyles.map((i, key) => {
                                                return (
                                                    <MenuItem key={key} value={i}>{i}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3} lg={3}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">{data.lightningStyle}</InputLabel>
                                        <Select
                                            sx={{ borderRadius: 2 }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={data.lightningStyle}
                                            value={state.lightingStyles}
                                            onChange={(e) => setState({ ...state, lightingStyles: e.target.value })}
                                        >
                                            {lightingStyles.map((i, key) => {
                                                return (
                                                    <MenuItem key={key} value={i}>{i}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3} lg={3}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">{data.mood}</InputLabel>
                                        <Select
                                            sx={{ borderRadius: 2 }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={data.mood}
                                            value={state.moods}
                                            onChange={(e) => setState({ ...state, moods: e.target.value })}
                                        >
                                            {moods.map((i, key) => {
                                                return (
                                                    <MenuItem key={key} value={i}>{i}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3} lg={3}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">{data.numOfImg}</InputLabel>
                                        <Select
                                            sx={{ borderRadius: 2 }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={data.numOfImg}
                                            value={state.numOfImage}
                                            onChange={(e) => setState({ ...state, numOfImage: e.target.value })}
                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Typography
                                onClick={() => setState({ ...state, aS: !state.aS })}
                                variant='caption' fontWeight={'bold'} sx={{ color: (t) => t.palette.primary.main, cursor: 'pointer' }} >-{data.advanceSet}</Typography>
                        </>
                        :
                        <Typography
                            onClick={() => setState({ ...state, aS: !state.aS })}
                            variant='caption' fontWeight={'bold'} sx={{ color: (t) => t.palette.primary.main, cursor: 'pointer' }} >+{data.advanceSet}</Typography>}
                </Box>
            </Box>

            <Dialog
                sx={{ bgcolor: (e) => convertHexToRgba(e.palette.primary.main, 0.03), backdropFilter: "blur(7px)" }}
                open={dialog} onClose={() => setDialog(false)}>
                <Box sx={{ position: 'relative' }} >
                    <CardMedia component={'img'} src={`/aiimages/${state.viewImage}`} sx={{ height: "100%", width: "100%" }} />
                    <IconButton
                        onClick={() => window.open(`/aiimages/${state.viewImage}`)}
                        sx={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }} >
                        <Download />
                    </IconButton>
                </Box>
            </Dialog>

            <Box borderRadius={4} mt={2} bgcolor={'action.hover'} p={4}>
                <Grid container spacing={2} >
                    {imgs.map((i, key) => {
                        return (
                            <Tooltip title={i.prompt} >
                                <Grid key={key} item xs={12} sm={4} lg={3}>
                                    <CardActionArea
                                        onClick={() => {
                                            setState({ ...state, viewImage: i.image })
                                            setDialog(true)
                                        }}
                                        sx={{ borderRadius: 2 }}>
                                        <CardMedia
                                            component={'img'} src={`/aiimages/${i.image}`} sx={{ height: '100%', width: '100%', borderRadius: 2 }} />
                                    </CardActionArea>
                                </Grid>
                            </Tooltip>
                        )
                    })}
                </Grid>
            </Box>
        </Box>
    )
}

export default AiImages