import React from 'react'
import axios from 'axios'

const GetAllPages = () => {
    const [allPages, setallPages] = React.useState("")
    async function getData() {
        const plans = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/get-all-page`)
        setallPages(plans.data.data)
    }
    React.useEffect(() => {
        getData()
    }, [])
    return { allPages }
}

export default GetAllPages