import React from 'react'
import axios from 'axios'

const GetAllFeatures = () => {
    const [allFeatures, setallFeatures] = React.useState("")
    async function getData() {
        const plans = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/get-all-features`)
        setallFeatures(plans.data.data)
    }
    React.useEffect(() => {
        getData()
    }, [])
    return { allFeatures }
}

export default GetAllFeatures