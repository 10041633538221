import React from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { CheckCircle, Delete } from '@mui/icons-material'
import { GlobalContext } from '../../../context/GlobalContext'
import { InstanceContext } from '../context/InstanceContext'
import axios from 'axios'
import OneIns from './OneIns'

const InstanceList = ({ data }) => {
    const G = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const CON = React.useContext(InstanceContext)

    async function getList() {
        const res = await G.hitAxios({
            path: "/api/sessions/get-users-instances",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            CON.setData({ ...CON.data, instanceList: res.data.data })
        }
    }

    React.useEffect(() => {
        if (!token) return
        getList()
    }, [token])

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                {CON.data.instanceList?.map((i, key) => {
                    return (
                        <OneIns key={key} i={i} data={data} />
                    )
                })}
            </Stack>
        </div>
    )
}

export default InstanceList