import React from 'react'
import { PayPalScriptProvider, PayPalButtons, plan } from '@paypal/react-paypal-js'
import { GlobalContext } from '../../../context/GlobalContext'
import { CheckOutContext } from '../context/CheckOutContext'

const UsingPaypal = ({ data, paymentGateways, plan, web }) => {
    const G = React.useContext(GlobalContext)
    const CON = React.useContext(CheckOutContext)

    async function paywithPaypal(order_id) {
        const res = await G.hitAxios({
            path: `/api/plan/pay-with-paypal`,
            obj: {
                order_id: order_id,
                plan: plan
            },
            admin: false,
            post: true
        })
        if (res.data.success) {
            CON.setData({ ...CON.data, paymentDone: true })
        }
    }

    return (
        paymentGateways?.payment_id &&
        < PayPalScriptProvider options={{ "client-id": paymentGateways?.payment_id }
        } >
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            description: "plan",
                            amount: {
                                value: parseInt(plan.cost) / parseInt(web.exchange_rate)
                            }
                        }]
                    })
                }}
                onApprove={async (data, actions) => {
                    const order = await actions.order.capture()
                    paywithPaypal(order.id)
                }}
                onError={(err) => {
                    console.log("err: ", err)
                }}
                onCancel={(cancel) => {
                    alert('Ops.. Your order was canceled.\nPlease contact support if you are feeling trouble.')
                }}
                onClick={(data, actions) => {

                }}
                style={{
                    color: 'white',
                    layout: 'horizontal',
                    height: 50,
                    tagline: false
                }} />
        </PayPalScriptProvider >
        // <div>{JSON.stringify(paymentGateways)}</div>
    )
}

export default UsingPaypal