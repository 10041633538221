import { Button, Grid } from '@mui/material'
import React from 'react'
import UsingPaypal from './UsingPaypal'
import UsingPayStack from './UsingPayStack'
import PayWithRz from './PayWithRz'
import UsingZarnipal from './UsingZarnipal'
import UsingInstamojo from './UsingInstamojo'
import UsingOffline from './UsingOffline'
import { CheckOutContext } from '../context/CheckOutContext'
import { useHistory } from 'react-router-dom'

const PaymentList = ({ data, paymentGateways, plan, web }) => {
    function returnData(e) {
        return paymentGateways?.filter((i) => i.code === e)
    }
    const history = useHistory()
    const CON = React.useContext(CheckOutContext)
    return (
        CON.data.paymentDone ?
            <Grid alignItems={'center'} container spacing={2}>
                <Grid item>
                    <Button
                        onClick={() => history.push('/user')}
                        variant='contained'
                        color='success'
                        size='large'
                        sx={{ borderRadius: 2, textTransform: 'none' }}
                    >{data.paymentDone}</Button>
                </Grid>
            </Grid>

            :
            paymentGateways &&
            <Grid alignItems={'center'} direction={'column'} container spacing={2}>
                <Grid xs={6} sm={4} lg={3} item> <UsingPaypal web={web} plan={plan} paymentGateways={returnData('paypal')[0]} data={data} /></Grid>
                <Grid xs={6} sm={4} lg={3} item> <UsingPayStack web={web} plan={plan} paymentGateways={returnData('paystack')[0]} data={data} /></Grid>
                <Grid xs={6} sm={4} lg={3} item> <PayWithRz web={web} plan={plan} paymentGateways={returnData('razorpay')[0]} data={data} /></Grid>
                <Grid xs={6} sm={4} lg={3} item> <UsingZarnipal web={web} plan={plan} paymentGateways={returnData('zarnipal')[0]} data={data} /></Grid>
                <Grid xs={6} sm={4} lg={3} item> <UsingInstamojo web={web} plan={plan} paymentGateways={returnData('instamojo')[0]} data={data} /></Grid>
                <Grid xs={6} sm={4} lg={3} item> <UsingOffline web={web} plan={plan} paymentGateways={returnData('offline')[0]} data={data} /></Grid>
            </Grid>
    )
}

export default PaymentList