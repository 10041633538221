import React from 'react'
import { GlobalContext } from '../../context/GlobalContext';
import { TranslateContext } from '../../context/TranslateContext';
import { Button, Stack, Typography, Box, Grid, IconButton, Dialog, AppBar, Toolbar, Container } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Add, Close, Delete, Visibility } from '@mui/icons-material';
import ChatBubble from './ChatBubble'

const EmbitChatHisroy = () => {
    const { data } = React.useContext(TranslateContext);
    const G = React.useContext(GlobalContext)
    const [chats, setChats] = React.useState([])
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [state, setState] = React.useState({
        dialogChats: []
    })

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item>
                            <Typography variant='h5' >{data.allCHats}</Typography>
                        </Grid>
                        {/* <Grid item>
                            <GridToolbarExport />
                        </Grid> */}
                    </Grid>
                </Box>
            </GridToolbarContainer>
        );
    }

    async function getChats() {
        const res = await G.hitAxios({
            path: "/api/embed/get_embed_chats",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.data) {
            setChats(res.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) return
        getChats()
    }, [token])

    async function delChatt(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/embed/del_chat",
                post: true,
                admin: false,
                obj: { email: e }
            })
            if (res.data.success) {
                getChats()
            }
        }
    }

    async function getChatsList(e) {
        const res = await G.hitAxios({
            path: "/api/embed/read_chat",
            post: true,
            admin: false,
            obj: { email: e }
        })
        if (res.data.success) {
            setState({ ...state, dialog: true, dialogChats: res.data.data })
        }
    }


    return (
        <div>
            <Box mb={4} mt={4}>
                <Stack alignItems="center" spacing={2} direction="row" justifyContent="space-between">
                    <Stack direction="column" justifyContent="space-between">
                        <Typography variant="h5" fontWeight="bold">
                            {data.embidChats}
                        </Typography>
                        <Typography variant="caption" color="gray">
                            {data.embidChatsDes}
                        </Typography>
                    </Stack>

                </Stack>
            </Box>

            <Box mt={6}>
                <Box borderRadius={4} bgcolor={'action.hover'} >
                    {chats ?
                        <Box height='80vh'>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={[...chats].reverse()}
                                columns={[
                                    { headerName: data.submitemail, field: 'user_email', flex: 1 },
                                    { headerName: data.submitmobile, field: 'user_mobile', flex: 1 },
                                    { headerName: data.submitname, field: 'user_name', flex: 1 },
                                    { headerName: data.botId, field: 'bot_id', flex: 1 },
                                    {
                                        headerName: data.view, field: 'id', flex: 1, renderCell: dataa => {
                                            return (
                                                <IconButton
                                                    onClick={() => getChatsList(dataa.row.user_email)}
                                                >
                                                    <Visibility />
                                                </IconButton>
                                            )
                                        }
                                    },
                                    {
                                        headerName: data.view, field: 'del', flex: 1, renderCell: dataa => {
                                            return (
                                                <IconButton
                                                    onClick={() => delChatt(dataa.row.user_email)}
                                                    color='error' >
                                                    <Delete />
                                                </IconButton>
                                            )
                                        }
                                    },
                                ]}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection={false}
                                components={{
                                    Toolbar: CustomToolbar
                                }}
                                sx={{
                                    boxShadow: 0,
                                    borderRadius: 4,
                                    border: 0,
                                    fontSize: 13,
                                    padding: 2
                                }}
                            />
                        </Box> : ""
                    }
                </Box>
            </Box>


            <Dialog fullScreen open={state.dialog}>
                <AppBar position='static' >
                    <Toolbar>
                        <Stack alignItems={'center'} direction={'row'} spacing={2}>
                            <IconButton onClick={() => setState({ ...state, dialog: false })} >
                                <Close />
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </AppBar>


                <Box mt={2}>
                    <Container maxWidth='lg'>
                        <Stack overflow={'auto'} maxHeight={'90%'} direction={'column'}>
                            {state.dialogChats.length > 0 && state.dialogChats.map((message, index) => (
                                <ChatBubble loading key={index} content={message.content} role={message.role} />
                            ))}
                        </Stack>
                    </Container>
                </Box>
            </Dialog>

        </div>
    )
}

export default EmbitChatHisroy