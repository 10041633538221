import { Box, CardActionArea, CardMedia, Container, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import GetAllPages from '../../../utils/GetAllPages'
import { useHistory } from 'react-router-dom'
import GetWebPublic from '../../../utils/GetWebPublic'
import './font.css'

const Footer = ({ data }) => {
    const history = useHistory()
    const { allPages } = GetAllPages()
    const { web } = GetWebPublic()
    const currentYear = new Date().getFullYear();

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    return (
        <Box
            sx={{ backgroundImage: 'url("/assets/bg3.png")', backgroundSize: 'cover', backgroundPosition: 'center' }} >
            <Box position={'relative'}>
                <Box minHeight={'60vh'} >
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <CardMedia className='floating' sx={{ height: "20%", width: "20%" }} src="/assets/graph.png" component={'img'} />
                        <CardMedia className='floating' sx={{ height: "20%", width: "20%" }} src="/assets/graph2.png" component={'img'} />
                    </Stack>
                </Box>

                <Box sx={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, }} >
                    <Container maxWidth='lg'>
                        <Grid container alignItems={'center'} minHeight={'40vh'} >
                            <Grid xs={12} sm={6} lg={6} item>
                                {web && <CardMedia src={`/images/${web.logo}`} component={'img'} sx={{ height: 100, width: 100, borderRadius: 2 }} />}
                                <Box mt={3}>
                                    {window.innerWidth < 700 ?
                                        <Typography variant='h3' align='center' className='grad' >{data.foterLine}</Typography> :
                                        <Typography variant='h3' className='grad' >{data.foterLine}</Typography>}
                                </Box>
                            </Grid>

                            <Grid xs={12} sm={6} lg={6} item>
                                <Box bgcolor={'action.hover'} borderRadius={3} p={3}>
                                    {allPages ?
                                        <Stack direction={'row'} spacing={2}>
                                            {allPages?.map((i, key) => {
                                                return (
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant='body2'
                                                        onClick={() => history.push(`/page/${i.slug}`)}
                                                        sx={{ cursor: 'pointer' }} key={key} >{i.title} |</Typography>
                                                )
                                            })}
                                        </Stack> :
                                        <LinearProgress />}
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box sx={{ bgcolor: (t) => convertHexToRgba(t.palette.primary.main, 0.05) }} p={2} >
                    {web && <Typography variant='body2' align='center' >&copy; {currentYear} {web.app_name}. {data.allRightRec}.</Typography>}
                </Box>
            </Box>
        </Box>
    )
}

export default Footer