import { AccountCircle, DarkMode, Home, LightMode, Logout, Close } from '@mui/icons-material'
import { Dialog, IconButton, Typography, Divider, TextField, Button } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { TranslateContext } from '../context/TranslateContext'
import { GlobalContext } from '../context/GlobalContext'
import getUserByToken from '../utils/GetsUserToken'

const HeaderUser = ({ title }) => {
    const { data } = React.useContext(TranslateContext)
    const inputPorps = { style: { borderRadius: 10 } }
    const history = useHistory()
    const [state, setState] = React.useState({
        dialog: false
    })
    const G = React.useContext(GlobalContext)
    const [user, setUser] = React.useState("")
    const { userData } = getUserByToken()

    async function updateUser() {
        const res = await G.hitAxios({
            path: "/api/user/update-profile",
            post: true,
            admin: false,
            obj: user
        })
    }

    React.useEffect(() => {
        if (!userData) return
        setUser({ ...userData })
    }, [userData])

    const logout = () => {
        if (window.confirm(data.AUS)) {
            localStorage.removeItem(process.env.REACT_APP_TOKEN + "_user")
            history.push('/')
        }
    }



    return (
        <Box p={2}>

            <Dialog
                fullWidth
                onClose={(e) => setState({ ...state, dialog: false })}
                open={state.dialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => setState({ ...state, dialog: false })} >
                            <Close />
                        </IconButton>
                        <Typography>{data.accountManage}</Typography>
                    </Stack>
                    <Divider />

                    {user &&
                        <Stack direction={'column'} spacing={2} mt={2}>


                            <TextField
                                onChange={(e) => setUser({ ...user, email: e.target.value })}
                                value={user.email}
                                InputProps={inputPorps}
                                size='small' label={data.email} fullWidth />

                            <TextField
                                onChange={(e) => setUser({ ...user, newpass: e.target.value })}
                                InputProps={inputPorps}
                                size='small' label={data.password} fullWidth />

                            <Button
                                onClick={updateUser}
                                variant='contained'
                                sx={{ borderRadius: 2, textTransform: 'none', boxShadow: 0 }} fullWidth >{data.submit}</Button>
                        </Stack>}

                </Box>

            </Dialog>



            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between'>
                <IconButton onClick={() => {
                    history.push("/")
                }} >
                    <Home />
                </IconButton>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>

                    <IconButton onClick={() => setState({ ...state, dialog: true })} >
                        <AccountCircle />
                    </IconButton>

                    {localStorage.getItem('theme') == 'dark' ?
                        <IconButton onClick={() => {
                            localStorage.setItem("theme", "light")
                            window.location.reload()
                        }} >
                            <LightMode />
                        </IconButton> :
                        <IconButton onClick={() => {
                            localStorage.setItem("theme", "dark")
                            window.location.reload()
                        }} >
                            <DarkMode />
                        </IconButton>}
                    <IconButton onClick={logout} >
                        <Logout />
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    )
}
export default HeaderUser