import React from 'react'
import { ChatTempletProvider } from './context/ChatTempletContext'
import Header from './components/Header'
import { TranslateContext } from '../../context/TranslateContext'

const ChatBotTemplet = () => {
    const { data } = React.useContext(TranslateContext)
    return (
        <ChatTempletProvider>
            <Header data={data} />
        </ChatTempletProvider>
    )
}

export default ChatBotTemplet