import React from 'react';
import './LoadingBubble.css';
import { Box, Stack } from '@mui/material';

const LoadingBubble = () => {
    return (
        <Stack direction={'row'}>
            <div className="gradient-container">
                <div className="gradient">
                    <Box p={2} borderRadius={3} boxShadow={1} >
                        <div className="loader">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                        </div>
                    </Box>
                </div>
            </div>
        </Stack>
    );
};


export default LoadingBubble;
