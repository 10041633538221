import { Mic, RadioButtonChecked } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const SpeechToText = ({ onChange }) => {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    React.useEffect(() => {
        onChange(transcript)
    }, [transcript])

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    return (
        <div>
            {listening}
            {listening ?
                <IconButton onClick={SpeechRecognition.stopListening} >
                    <RadioButtonChecked sx={{ color: 'red' }} />
                </IconButton> :
                <IconButton onClick={SpeechRecognition.startListening}>
                    <Mic />
                </IconButton>
            }
        </div>
    );
};
export default SpeechToText;