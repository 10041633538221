import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Link from '@mui/material/Link';
import { CardMedia, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Dashboard as DashboardIcon, FeaturedPlayList, Translate, Forum, Group, LiveHelp, MenuBook, Paid, Password, Payment, Settings, ShoppingCartCheckout, StarBorder, SupportAgent, } from '@mui/icons-material'
import DashboardPage from './DashboardPage';
import { useHistory } from 'react-router-dom';
import { Stack } from '@mui/system';
import HeaderAdmin from './HeaderAdmin';
import { TranslateContext } from '../context/TranslateContext'

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://codeyon.com/">
                Codeyon
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 290;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


function AdminDashboard() {
    const [open, setOpen] = React.useState(window.innerWidth < 950 ? false : true);
    const history = useHistory()
    const web = ""

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('page');

    const T = React.useContext(TranslateContext)

    React.useEffect(() => {
        setPage(parseInt(foo) || 0)
    }, [foo])

    const [page, setPage] = React.useState(null)
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const menudata = [
        {
            name: T.data.dashboard,
            icon: <DashboardIcon />,
        },
        {
            name: T.data.manageUsers,
            icon: <Group />,
        },
        {
            name: T.data.managePlans,
            icon: <Paid />,
        },
        {
            name: T.data.paymentGateway,
            icon: <Payment />,
        },
        {
            name: T.data.apiKeys,
            icon: <Password />,
        },
        {
            name: T.data.chatBotTemp,
            icon: <Forum />,
        },
        {
            name: T.data.ping,
            icon: <SupportAgent />,
        },
        {
            name: T.data.allOrders,
            icon: <ShoppingCartCheckout />,
        },
        {
            name: T.data.managePage,
            icon: <MenuBook />,
        },
        {
            name: T.data.addTesti,
            icon: <StarBorder />,
        },
        {
            name: T.data.addFaq,
            icon: <LiveHelp />,
        },
        {
            name: T.data.features,
            icon: <FeaturedPlayList />,
        },
        {
            name: T.data.AppConfig,
            icon: <Settings />,
        },
        {
            name: T.data.webTranslation,
            icon: <Translate />,
        }
    ]



    return (
        <Box
            sx={{ backgroundImage: 'url("/assets/bg2.png")', backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex' }}>
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        px: [1],
                    }}
                >
                    {open && web && <Stack direction='row'>
                        <CardMedia sx={{ borderRadius: 4 }} component='img' height={40} src={`/images/${web.logo}`} /></Stack>}
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    {
                        menudata.map((item, key) => {
                            return (
                                <ListItemButton sx={{ bgcolor: page === key ? "action.selected" : null }} onClick={() => {
                                    history.push(`/admin?page=${key}`)
                                    setPage(key)
                                }} key={key} >
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            )
                        })
                    }
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    // backgroundColor: (theme) =>
                    //     theme.palette.mode === 'light'
                    //         ? theme.palette.grey[100]
                    //         : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >


                <HeaderAdmin title={T.data.welcome_admin} />
                <Container maxWidth="xl">
                    <DashboardPage page={parseInt(page)} />
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>
    );
}

export default function Dashboard() {
    return <AdminDashboard />;
}