import { Close } from '@mui/icons-material'
import { AppBar, Box, Button, Container, Dialog, Grid, IconButton, Stack, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { InstanceContext } from '../context/InstanceContext'
import { GlobalContext } from '../../../context/GlobalContext'
import GetUserToken from '../../../utils/GetsUserToken'
import { Image } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import buffer from 'buffer';

const AddInsDialog = ({ data }) => {
    const CON = React.useContext(InstanceContext)
    const [step, setStep] = React.useState(1)
    const G = React.useContext(GlobalContext)
    const [qr, setQr] = React.useState("")
    const { userData } = GetUserToken()
    const [clientName, setClientName] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [status, setStatus] = React.useState(false)

    function encodeObject(obj) {
        const jsonString = JSON.stringify(obj);
        const base64String = buffer.Buffer.from(jsonString).toString('base64');
        return base64String;
    }


    async function getQR() {
        const res = await G.hitAxios({
            path: "/api/sessions/add",
            admin: false,
            post: true,
            obj: {
                id: encodeObject({
                    uid: userData.uid,
                    client_id: clientName
                }),
                name: clientName,
                isLegacy: false
            }
        })
        if (res.data.success) {
            setQr(res.data.data.qr)
            getInsStatus()
        }
    }


    async function getList() {
        const res = await G.hitAxios({
            path: "/api/sessions/get-users-instances",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            CON.setData({ ...CON.data, instanceList: res.data.data, addDialog: false })
        }
    }

    async function getInsStatus() {
        const timer = setInterval(async () => {
            const res = await G.hitAxios({
                path: `/api/sessions/status/${encodeObject({
                    uid: userData.uid,
                    client_id: clientName
                })}`,
                admin: false,
                post: false,
                token_user: token
            })

            if (res.data.data?.loginStatus) {
                setStatus(true)
                clearInterval(timer)
                getList()
            }
        }, 3000);
    }

    return (
        <Dialog fullScreen open={CON.data.addDialog}>
            <AppBar position='static'>
                <Toolbar>
                    <Stack direction={'row'} spacing={2}>
                        <IconButton onClick={() => CON.setData({ ...CON, addDialog: false })} >
                            <Close />
                        </IconButton>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box mt={3}>
                <Container maxWidth='lg' >
                    {step == 1 &&
                        <Grid alignItems={'center'} container spacing={3}>
                            <Grid xs={12} md={12} sm={12} item>
                                <Typography align='center' fontWeight={'bold'}  >{data.getPhoneReady}</Typography>
                            </Grid>
                            <Grid xs={12} md={12} sm={12} item>
                                <Stack alignItems={'center'}>
                                    <video
                                        style={{ width: "70%", borderRadius: 20 }}
                                        controlsList="nodownload"
                                        controls
                                        autoPlay
                                        src='/video/demowa.mp4' />
                                </Stack>
                            </Grid>
                            <Grid xs={12} md={12} sm={12} item>
                                <Stack alignItems={'center'}>
                                    <Button
                                        onClick={() => setStep(2)}
                                        sx={{ boxShadow: 0, borderRadius: 3, textTransform: 'none', width: '70%' }}
                                        variant='contained'
                                    >
                                        {data.next}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>}

                    {step == 2 &&
                        <Stack alignItems={'center'} direction={'column'} spacing={4}>

                            {!qr ?
                                <Image sx={{ height: 300, width: 300, opacity: 0.1 }} /> :
                                <img src={`${qr}`} />}

                            <Stack alignItems={'center'} direction={'column'} spacing={2}>
                                <TextField
                                    onChange={(e) => setClientName(e.target.value)}
                                    helperText={data.addInsHelper}
                                    label={data.instanceName} fullWidth size='small' InputProps={{
                                        style: {
                                            borderRadius: 10
                                        }
                                    }} />

                                <LoadingButton
                                    disabled={clientName ? false : true}
                                    loading={G.data.loading}
                                    onClick={getQR}
                                    sx={{ boxShadow: 0, borderRadius: 3, textTransform: 'none', width: '70%' }}
                                    variant='contained'
                                >
                                    {data.genQR}
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    }

                </Container>
            </Box>
        </Dialog>
    )
}

export default AddInsDialog