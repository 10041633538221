import React from 'react'
import { AiChatbotProvider } from './context/AiChatboContext'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'
import Header from './components/Header'

const AiChatbot = () => {
    const { data } = React.useContext(TranslateContext)
    const G = React.useContext(GlobalContext)
    return (
        <AiChatbotProvider>
            <Header data={data} G={G} />
        </AiChatbotProvider>
    )
}

export default AiChatbot