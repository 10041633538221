import React from 'react'
import { Box, Stack, Typography, IconButton, CircularProgress } from '@mui/material'
import { Delete, CheckCircle } from '@mui/icons-material'
import axios from 'axios'
import { InstanceContext } from '../context/InstanceContext'
import { GlobalContext } from '../../../context/GlobalContext'

const OneIns = ({ key, data, i }) => {
    const [load, setLoad] = React.useState(false)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const G = React.useContext(GlobalContext)
    const CON = React.useContext(InstanceContext)
    const [isActive, setIsActive] = React.useState(false)

    async function getList() {
        const res = await G.hitAxios({
            path: "/api/sessions/get-users-instances",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            CON.setData({ ...CON.data, instanceList: res.data.data })
        }
    }

    async function delSession(e) {
        if (window.confirm(data.AUS)) {
            setLoad(true)
            axios.delete(`${process.env.REACT_APP_BASE_URL}/api/sessions/delete/${e}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then((res) => {
                console.log(res.data)
                if (res.data.success || res.data.success === undefined) {
                    alert("success")
                }
                alert(res.data.msg == undefined ? "success" : res.data.msg)
                getList()
                setLoad(false)
            }).catch((err) => {
                setLoad(false)
                console.log(err)
            })
        }
    }

    async function getStatus() {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/sessions/status/${i.client_id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            if (res.data.success) {
                setIsActive(true)
            }
        }).catch((err) => {
            setIsActive(false)
        })
    }

    React.useEffect(() => {
        if (!i.client_id) return
        getStatus()
    }, [i.client_id])


    return (
        <Box
            key={key}
            borderRadius={4}
            sx={{ bgcolor: 'action.hover' }}
            p={2}>
            <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                <Typography fontWeight={'bold'} >{i.name}</Typography>
                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                    {load ?
                        <CircularProgress size={20} color='error' />
                        :
                        <IconButton
                            onClick={() => delSession(i.client_id)}
                            color='error'>
                            <Delete />
                        </IconButton>}
                    {isActive ?
                        <CheckCircle sx={{ color: 'green' }} /> :
                        <CheckCircle sx={{ color: 'orange' }} />}
                </Stack>
            </Stack>
        </Box>
    )
}

export default OneIns