import React from 'react'
import { InstanceProvider } from './context/InstanceContext'
import { Box, Typography, Stack, Button, Divider } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import InstanceList from './components/InstanceList'
import AddInsDialog from './components/AddInsDialog'
import Header from './components/Header'

const ManageInstance = () => {
    const { data } = React.useContext(TranslateContext)
    return (
        <InstanceProvider>
            <Box mt={4}>
                <Header data={data} />

                <AddInsDialog data={data} />

                <Stack spacing={3} mt={5}>
                    <InstanceList data={data} />
                </Stack>

            </Box>
        </InstanceProvider>
    )
}

export default ManageInstance