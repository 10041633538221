import React from 'react'

export const AiChatbotContext = React.createContext(null)

export const AiChatbotProvider = (props) => {
    const [data, setData] = React.useState({
        categoryData: [],
        chatting: false,
        chattingBotData: {}
    })

    return (
        <AiChatbotContext.Provider value={{ data, setData }}>
            {props.children}
        </AiChatbotContext.Provider>
    )
}

