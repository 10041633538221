import { LoadingButton } from '@mui/lab'
import { Grid, Box, TextField, CardMedia, Snackbar, SnackbarContent, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import GetWebPublic from '../utils/GetWebPublic'
import { TranslateContext } from '../context/TranslateContext'


const PasswordRecoveryUser = (props) => {
    const { web } = GetWebPublic()
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        password: ""
    })
    const history = useHistory()

    function modifyAdmin() {
        setState({ ...state, loading: true })
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/modify_user`, {
            email: state.email,
            password: state.password
        }, {
            headers: {
                Authorization: "Bearer " + props.match.params.id
            }
        }).then((res) => {
            console.log(res.data)
            if (!res.data.success) {
                return setState({ ...state, snack: true, snack_msg: "Ops. it seems your recovery link has been expired, Please send email again.", loading: false, snack_clr: "red" })
            }
            setState({ ...state, snack: true, snack_msg: "User has been updated you are now redirecting to the login page...", loading: false, snack_clr: 'green' })
            setTimeout(() => {
                history.push('/user/login')
            }, 3000);
        }).catch((err) => console.log(err))
    }

    return (
        <Box
            sx={{ backgroundImage: 'url("/assets/bg2.png")', backgroundSize: 'cover', backgroundPosition: 'center' }}
            minHeight={'100vh'} >
            <Snackbar open={state.snack} onClose={() => setState({ ...state, snack: false })} >
                <SnackbarContent message={state.snack_msg} autoHideDuration={5000} />
            </Snackbar>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item >
                    <Box minWidth={'50vh'}>
                        <Grid container spacing={2} direction='column' >
                            <Grid item>
                                {
                                    web && (
                                        <CardMedia component={'img'} sx={{ height: 100, width: 100, borderRadius: 2 }} image={`/images/${web.logo}`} />
                                    )
                                }
                            </Grid>
                            <Grid item>
                                <Typography >Congo here you can add your new password.</Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    InputProps={{
                                        style: { borderRadius: 10 }
                                    }}
                                    size='small' onChange={(e) => setState({ ...state, password: e.target.value })} label="Password" helperText={'required'} fullWidth />
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    sx={{ textTransform: "none", borderRadius: 2 }}
                                    disabled={state.password.length < 8 ? true : false} loading={state.loading} onClick={modifyAdmin} variant='contained' fullWidth >Go</LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}

export default PasswordRecoveryUser