import { AppBar, Box, CardActionArea, Chip, Container, Dialog, Divider, IconButton, Skeleton, Stack, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { ManageUserContext } from '../context/ManageUserContext'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { GlobalContext } from '../../../context/GlobalContext'
import GetPlansPublic from '../../../utils/GetPlansPublic'

const ManageUserDialog = ({ data }) => {
    const MU = React.useContext(ManageUserContext)
    const G = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const { plansPublic } = GetPlansPublic()
    const inputProps = {
        style: { borderRadius: 10 }
    }
    const [user, setUser] = React.useState({})

    React.useEffect(() => {
        if (!MU.data.editUserData) return
        setUser(MU.data.editUserData)
    }, [MU.data.editUserData])

    async function getUsers() {
        const users = await G.hitAxios({
            path: "/api/admin/get-users",
            post: false,
            admin: true,
            token_user: token
        })
        console.log(users.data.data)
        MU.setData({ ...MU.data, userData: users.data.data, manageUserDialog: false })
    }

    async function updateUser() {
        const res = await G.hitAxios({
            path: "/api/admin/edit-user",
            admin: true,
            post: true,
            obj: user
        })
        if (res.data.success) {
            getUsers()
        }
    }

    async function delUser(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/admin/del-user",
                admin: true,
                post: true,
                obj: { id: e }
            })
            if (res.data.success) {
                getUsers()
            }
        }
    }

    async function updatePlan() {
        const res = await G.hitAxios({
            path: "/api/admin/update-user-plan",
            admin: true,
            post: true,
            obj: { plan: JSON.parse(user.plan)?.id ? user.plan : null, uid: user.uid }
        })
        if (res.data.success) {
            getUsers()
        }
    }

    return (
        <Dialog open={MU.data.manageUserDialog} fullScreen >
            <AppBar position="static">
                <Toolbar>
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => MU.setData({ ...MU.data, manageUserDialog: false })} >
                            <Close />
                        </IconButton>
                        <Typography fontWeight={'bold'} >{data.manageUser}</Typography>
                    </Stack>
                </Toolbar>
            </AppBar>

            <Box mt={2}>
                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={4}>
                        <Stack direction={'column'} spacing={2}>
                            <TextField
                                onChange={(e) => setUser({ ...user, name: e.target.value })}
                                value={user.name} size='small' label={data.name} InputProps={inputProps} fullWidth />
                            <TextField
                                onChange={(e) => setUser({ ...user, email: e.target.value })}
                                value={user.email} size='small' label={data.email} InputProps={inputProps} fullWidth />
                            <TextField
                                onChange={(e) => setUser({ ...user, newpass: e.target.value })}
                                helperText={data.changePwDes}
                                size='small' label={data.password} InputProps={inputProps} fullWidth />
                            <LoadingButton
                                onClick={updateUser}
                                loading={G.data.loading}
                                variant='contained'
                                sx={{ borderRadius: 2, textTransform: 'none' }}
                            >{data.submit}</LoadingButton>

                            <LoadingButton
                                onClick={() => delUser(user.id)}
                                loading={G.data.loading}
                                variant='outlined'
                                color='error'
                                sx={{ borderRadius: 2, textTransform: 'none' }}
                            >{data.delUser}</LoadingButton>
                        </Stack>

                        <Divider />

                        {plansPublic ?
                            <Stack direction={'column'} spacing={2}>
                                <Stack spacing={2} direction={'row'}>
                                    {plansPublic?.map((i, key) => {
                                        return (
                                            <CardActionArea
                                                onClick={() => {
                                                    setUser({ ...user, plan: JSON.stringify(i) })
                                                }}
                                                key={key} sx={{ borderRadius: 4 }}>
                                                <Chip
                                                    color='info'
                                                    variant={user.plan && JSON.parse(user.plan).plan_name === i.plan_name ? 'filled' : 'outlined'}
                                                    sx={{ width: "100%" }} label={i.plan_name} />
                                            </CardActionArea>
                                        )
                                    })}
                                </Stack>
                                <LoadingButton
                                    onClick={updatePlan}
                                    loading={G.data.loading}
                                    variant='contained'
                                    color='info'
                                    sx={{ borderRadius: 2, textTransform: 'none' }}
                                >{data.submit}</LoadingButton>
                            </Stack>
                            :
                            <Skeleton />}

                    </Stack>
                </Container>
            </Box>

        </Dialog>
    )
}

export default ManageUserDialog