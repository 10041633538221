import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { Box, Stack, TextField, Typography, IconButton, InputAdornment, Snackbar } from '@mui/material'
import { Face, Send } from '@mui/icons-material'
import './style.css'
import { GlobalContext } from '../../context/GlobalContext'
import GetsUserToken from '../../utils/GetsUserToken'
import SpeechToText from '../ai-chatbot/components/SpeechToText'
import ChatBubble from './ChatBubble'
import axios from 'axios'

const ChatWithBard = () => {
    const { data } = React.useContext(TranslateContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const { userData } = GetsUserToken()
    const G = React.useContext(GlobalContext)
    const [typing, setTyping] = React.useState(false)
    const [que, setQue] = React.useState("")
    const [load, setLoad] = React.useState(false)
    const [snack, setSnack] = React.useState(false)
    const [snackMsg, setSnackMsg] = React.useState("")

    const [messages, setMessages] = React.useState([
        { role: 'user', content: "Hello me user" },
        { role: 'assistant', content: "My ai" }
    ]);

    async function getReply() {
        if (!que) return
        setTyping(true);
        setMessages((prevMessages) => [
            ...prevMessages,
            { role: "user", content: que },
        ]);
        setQue("");

        const res = await G.hitAxios({
            path: "/api/bard/get_reply",
            admin: false,
            post: true,
            obj: { que }
        })

        setTyping(false);
        if (res.data.success) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { role: "assistant", content: res.data.data },
            ]);
        }
    }

    function getMessage() {
        setLoad(true)
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/bard/get_chat`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            setLoad(false)
            if (res.data.success) {
                setMessages(res.data.data)
            } else {
                setSnack(true)
                setSnackMsg(res.data.msg)
            }
        })
    }

    React.useEffect(() => {
        if (!token) return
        getMessage()
    }, [token])


    return (
        <Box mt={4} >
            <Snackbar open={snack} message={snackMsg} autoHideDuration={4000} />
            <Stack direction={'column'}>
                <Stack direction={'row'} spacing={1}>
                    <Typography variant='h5' fontWeight={'bold'}>{data.chatWithBardAi}</Typography>
                    <Typography variant='caption' color={'orange'} >{data.experiment}</Typography>
                </Stack>
            </Stack>


            <Stack overflow={'auto'} direction={'column'} justifyContent={'space-between'} height={'70vh'} mt={2} >
                <Box>
                    <Stack direction={'column'}>
                        {messages.map((message, index) => (
                            <ChatBubble loading key={index} content={message.content} role={message.role} />
                        ))}
                    </Stack>
                </Box>

                <Box mt={2} mb={2} >
                    <TextField
                        // multiline
                        variant='filled'
                        value={que}
                        onChange={(e) => setQue(e.target.value)}
                        InputProps={{
                            style: { borderRadius: 20 },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Stack direction={'row'} spacing={1}>
                                        <SpeechToText onChange={(e) => {
                                            setQue(e)
                                        }} />
                                        <IconButton
                                            disabled={typing}
                                            onClick={getReply}
                                        >
                                            <Send />
                                        </IconButton>

                                    </Stack>
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                getReply();
                            }
                        }}
                        label="Enter message" fullWidth />
                </Box>
            </Stack>



        </Box>
    )
}

export default ChatWithBard