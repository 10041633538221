import React from 'react'
import { Box, Button, Dialog, IconButton, Stack, Typography, Divider, TextField, Grid, Chip, CardActionArea } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { Add, Close } from '@mui/icons-material'
import { GlobalContext } from '../../context/GlobalContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const PingUser = () => {
    const { data } = React.useContext(TranslateContext)
    const G = React.useContext(GlobalContext)
    const [dialog, setDialog] = React.useState(false)
    const [ping, setPing] = React.useState([])
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [state, setState] = React.useState({})

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item>
                            <Typography variant='h5' >{data.allPings}</Typography>
                        </Grid>
                        <Grid item>
                            <GridToolbarExport />
                        </Grid>
                    </Grid>
                </Box>
            </GridToolbarContainer>
        );
    }


    async function sendMsg() {
        const res = await G.hitAxios({
            path: "/api/user/send-new-ping",
            post: true,
            admin: false,
            obj: { msg: state.msg }
        })
        if (res.data.success) {
            setDialog(false)
            getPings()
        }
    }

    async function getPings() {
        const res = await G.hitAxios({
            path: "/api/user/get-my-ping",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.success) {
            setPing(res.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) return
        getPings()
    }, [token])

    return (
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.ping}</Typography>
                <Button
                    onClick={() => setDialog(true)}
                    startIcon={<Add />}
                    variant='contained' sx={{ textTransform: 'none', borderRadius: 2 }} >{data.sendNew}</Button>
            </Stack>
            <Dialog
                fullWidth
                onClose={(e) => setDialog(false)}
                open={dialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => setDialog(false)} >
                            <Close />
                        </IconButton>
                        <Typography>{data.sendNew}</Typography>
                    </Stack>
                    <Divider />
                    <Stack direction={'column'} spacing={2} mt={2}>
                        <TextField
                            onChange={(e) => setState({ ...state, msg: e.target.value })}
                            label={data.enterYourMsg} fullWidth size='small' InputProps={{
                                style: { borderRadius: 10 }
                            }} />
                        <Button
                            disabled={state.msg ? false : true}
                            onClick={sendMsg}
                            variant='contained' sx={{ textTransform: 'none', borderRadius: 2 }} >{data.submit}</Button>
                    </Stack>
                </Box>
            </Dialog>

            <Box mt={4}>
                <Box borderRadius={4} bgcolor={'action.hover'} >
                    {ping ?
                        <Box height='80vh'>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={[...ping].reverse()}
                                columns={[
                                    { headerName: data.ID, field: 'id', flex: 1 },
                                    { headerName: data.message, field: 'user_msg', flex: 1 },
                                    {
                                        headerName: data.reply, field: 'admin_reply', flex: 1, renderCell: dataa => dataa.row.admin_reply ? <Box border={1}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => alert(dataa.row.admin_reply)}
                                            borderColor={'rgba(11, 255, 0, 1)'} bgcolor={'rgba(11, 255, 0, 0.27)'} p={0.5} borderRadius={4}>
                                            <Typography variant='caption'>{data.replied}</Typography>
                                        </Box>
                                            : <Box border={1} borderColor={'rgba(255, 202, 0, 1)'} bgcolor={'rgba(255, 202, 0, 0.24)'} p={0.5} borderRadius={4}>
                                                <Typography variant='caption'>{data.pending}</Typography>
                                            </Box>
                                    },
                                ]}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection={false}
                                components={{
                                    Toolbar: CustomToolbar
                                }}
                                sx={{
                                    boxShadow: 0,
                                    borderRadius: 4,
                                    border: 0,
                                    fontSize: 13,
                                    padding: 2
                                }}
                            />
                        </Box> : ""
                    }
                </Box>
            </Box>
        </Box >
    )
}

export default PingUser