import React from 'react'

export const InstanceContext = React.createContext(null)

export const InstanceProvider = (props) => {
    const [data, setData] = React.useState({
        addDialog: false,
        instanceList: []
    })

    return (
        <InstanceContext.Provider value={{ data, setData }}>
            {props.children}
        </InstanceContext.Provider>
    )
}

