import { Button, CardMedia, Grid, TextField, Typography, Dialog, IconButton, InputAdornment } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../../context/GlobalContext'
import Header from '../../frontend/home/components/Header'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { TranslateContext } from '../../context/TranslateContext'

const AdminLogin = () => {
    const { data } = React.useContext(TranslateContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        dialog: false,
        show_pass: false
    })
    const history = useHistory()

    async function login() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/login", post: true, obj: {
                email: state.email,
                password: state.password
            }, admin: true
        })

        if (resp.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_admin", resp.data.token)
            history.push('/admin')
            return
        }
    }

    async function sendRecovery() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/send_recovery",
            admin: true,
            post: true,
            obj: { recovery_email: state.recover_email }
        })
        setState({ ...state, dialog: false })
    }

    return (
        <Box
            minHeight={'100vh'}
            sx={{ backgroundImage: 'url("/assets/bg2.png")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Header />


            <Dialog PaperProps={{
                style: {
                    borderRadius: 10
                }
            }}
                fullWidth open={state.dialog} onClose={() => setState({ ...state, dialog: false })}>
                <Stack direction='row' spacing={2} justifyContent={'space-between'} alignItems='center' p={2}>
                    <IconButton onClick={() => setState({ ...state, dialog: false })}>
                        <Close />
                    </IconButton>
                    <Typography>Reset your passwrod</Typography>
                </Stack>
                <Stack direction='column' spacing={2} p={2}>
                    <TextField
                        onChange={(e) => setState({ ...state, recover_email: e.target.value })}
                        label={data.email} fullWidth InputProps={{
                            style: {
                                borderRadius: 10
                            }
                        }} size='small' />

                    <Button
                        onClick={sendRecovery}
                        disabled={state.recover_email ? false : true}
                        sx={{ borderRadius: 2, boxShadow: 0 }}
                        variant='contained'
                        size='small'
                    >{data.sendLink}</Button>
                </Stack>
            </Dialog>

            <Box pt={12}>
                <Container maxWidth='lg'>
                    <Box minHeight={'100%'} bgcolor={'action.hover'}>
                        <Grid alignItems={'center'} justifyContent='center' container minHeight={'80vh'} >
                            <Grid item xs={12} sm={12} lg={6}>
                                <Box p={4}>
                                    <Stack direction={'row'} justifyContent='space-between' >
                                        <Typography variant='h6' fontWeight={'bold'} >Login</Typography>
                                        {/* <Typography sx={{ color: (t) => t.palette.primary.main }} variant='body2' fontWeight={'bold'} >SignUp</Typography> */}
                                    </Stack>

                                    <Stack mt={4} direction={'column'} spacing={2}>
                                        <TextField InputProps={{
                                            style: {
                                                borderRadius: 10
                                            }
                                        }} onChange={(e) => setState({ ...state, email: e.target.value })} size='small' label={data.email} fullWidth />
                                        <TextField
                                            type={state.show_pass ? 'text' : 'password'}
                                            InputProps={{
                                                style: {
                                                    borderRadius: 10
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setState({ ...state, show_pass: !state.show_pass })} >
                                                            {state.show_pass ?
                                                                <VisibilityOff /> :
                                                                <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }} onChange={(e) => setState({ ...state, password: e.target.value })} size='small' label="Password" fullWidth />
                                    </Stack>

                                    <Stack mt={2} direction={'row'} justifyContent='space-between'>
                                        <Typography
                                            onClick={() => setState({ ...state, dialog: true })}
                                            sx={{ color: (t) => t.palette.primary.main, cursor: 'pointer' }} fontWeight={'bold'} >Forgot Password?</Typography>
                                    </Stack>

                                    <Stack mt={4} >
                                        <Button onClick={login} fullWidth variant='contained' sx={{ textTransform: 'none' }} >Sign In</Button>
                                    </Stack>

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <Stack bgcolor={'#f5f8fe'} minHeight='80vh' alignItems={'center'} justifyContent={'center'} >
                                    <Stack alignItems={'center'} direction={'column'} >
                                        <CardMedia component={'img'} src='/assets/login.png' />
                                        <Typography variant='h6' color={'black'} align='center' fontWeight='bold' >{data.appName}</Typography>
                                        <Box width={'70%'}>
                                            <Typography variant='body2' color={'gray'} align='center' >{data.userLoginDes}</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </Box >
    )
}

export default AdminLogin