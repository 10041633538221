import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, Typography, Grid, LinearProgress, Tooltip, } from '@mui/material'
import moment from 'moment';

const SpeechList = ({ data, G }) => {
    const [txtArr, setTxtArr] = React.useState('');
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + '_user');

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item>
                            <Typography variant='h5' >{data.allUsers}</Typography>
                        </Grid>
                        <Grid item>
                            <GridToolbarExport />
                        </Grid>
                    </Grid>
                </Box>
            </GridToolbarContainer>
        );
    }


    async function getMyText() {
        const res = await G.hitAxios({
            path: '/api/stt/get_my_text',
            admin: false,
            post: false,
            token_user: token
        });
        if (res.data.success) {
            setTxtArr(res.data.data);
        }
    }

    React.useEffect(() => {
        if (!token) return
        getMyText()
    }, [token])

    return (
        <div>
            <Box borderRadius={4} bgcolor={'action.hover'} >
                {txtArr ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...txtArr].reverse()}
                            columns={[
                                { headerName: data.id, field: 'id' },
                                {
                                    headerName: data.enterText, field: 'text', flex: 1, renderCell: dataa => {
                                        return (
                                            <Tooltip title={dataa.row.text}>
                                                <a>{dataa.row.text}</a>
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.date, field: 'createdAt', renderCell: dataa => {
                                        return (
                                            moment(dataa.row.createdAt).fromNow()
                                        )
                                    }
                                },
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            components={{
                                Toolbar: CustomToolbar
                            }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : <LinearProgress />
                }
            </Box>
        </div>
    )
}

export default SpeechList