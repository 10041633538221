import React from 'react'
import { AiBotContext } from '../contetxt/AiBotContext'
import { GlobalContext } from '../../../context/GlobalContext'
import { Accordion, AccordionSummary, IconButton, Switch, Typography } from '@mui/material'
import { AccessTime, Delete, ExpandMore, SmartToy } from '@mui/icons-material'
import { Box, Stack, borderRadius, style } from '@mui/system'
import moment from 'moment'

const BotList = ({ data }) => {
    const CON = React.useContext(AiBotContext)
    const G = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")

    async function getBots() {
        const res = await G.hitAxios({
            path: "/api/bot/get-bots",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.success) {
            CON.setData({ ...CON, botData: res.data.data })
        }
    }

    async function delBot(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/bot/del-bot",
                admin: false,
                post: true,
                obj: { id: e }
            })
            if (res.data.success) {
                getBots()
            }
        }
    }

    async function changeStatus(e) {
        const res = await G.hitAxios({
            path: "/api/bot/change-status",
            admin: false,
            post: true,
            obj: { status: e }
        })
        if (res.data.success) {
            getBots()
        }
    }

    return (
        CON.data.botData?.map((i, key) => {
            return (
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <SmartToy sx={{ height: 20, width: 20, color: (t) => t.palette.primary.main }} />
                            <Typography>{i.name}</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionSummary>
                        <Box width={'100%'} >
                            <Stack direction={'column'} spacing={1}>
                                <Box p={2} bgcolor={'action.hover'} borderRadius={3} >
                                    <Typography fontStyle={'italic'} variant='caption'  >{i.train_data}</Typography>
                                </Box>
                                <Stack alignItems={'center'} spacing={1} direction={'row'} justifyContent={'flex-end'}>
                                    <Stack direction={'row'} spacing={1}>
                                        <AccessTime sx={{ height: 20, width: 20 }} />
                                        <Typography color={'gray'} variant='caption'>
                                            {moment(i.createdAt).fromNow()}
                                        </Typography>
                                    </Stack>

                                    <Switch
                                        onChange={(e) => changeStatus(e.target.checked)}
                                        checked={i.active === 1 ? true : false} />
                                    <IconButton onClick={() => delBot(i.id)} color='error' size='small' >
                                        <Delete sx={{ height: 20, width: 20 }} />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Box>
                    </AccordionSummary>
                </Accordion >
            )
        })
    )
}

export default BotList