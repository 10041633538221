import { Box, FormControl, MenuItem, Select, Stack, TextField, Typography, InputLabel, Button, Tooltip, IconButton } from '@mui/material'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'
import { DataGrid } from '@mui/x-data-grid';
import { Downloading, RecordVoiceOver, AutoFixNormal, Delete } from '@mui/icons-material'
import PlayPause from './PlayPause';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import GetUserToken from '../../utils/GetsUserToken';

const TextToSpeech = () => {
    const { data } = React.useContext(TranslateContext)
    const G = React.useContext(GlobalContext)
    const [langs, setLangs] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [selectedLang, setSelLang] = React.useState("")
    const [selVoice, setSelVoice] = React.useState("")
    const [state, setState] = React.useState({})
    const [genArr, setGenArr] = React.useState("")
    const history = useHistory()
    const { userData } = GetUserToken()

    async function getLangs() {
        const res = await G.hitAxios({
            path: "/api/tts/langs_data",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            console.log(res.data.data)
            setLangs(res.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) return
        getLangs()
        genMyVoice()
    }, [token])

    const getVoicesByLanguage = (languageName) => {
        // Find the language object based on the language name
        const languageObject = Object.values(langs).find(obj => obj.LanguageName === languageName);

        // Return the voices array if the language object is found, otherwise return an empty array
        return languageObject ? languageObject.Voices : [];
    };

    async function genMyVoice() {
        const res = await G.hitAxios({
            path: "/api/tts/my_gen_voices",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.success) {
            setGenArr(res.data.data)

        }
    }

    async function genVoice() {
        const res = await G.hitAxios({
            path: "/api/tts/gen_voice",
            post: true,
            admin: false,
            obj: { ...state, selVoice, selectedLang }
        })
        if (res.data.success) {
            history.go(0)
        }
    }

    async function delEntry(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/tts/del_entry",
                post: true,
                admin: false,
                obj: { id: e }
            })
            if (res.data.success) {
                genMyVoice()
            }
        }
    }

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }


    return (
        langs &&
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Stack direction={'column'} justifyContent='space-between'>
                    <Typography variant='h5' fontWeight={'bold'}>{data.textToSpeech}</Typography>
                    <Typography variant='caption' color='gray' >{data.TextTSDes}</Typography>
                </Stack>
                <Box
                    sx={{ bgcolor: (t) => convertHexToRgba(t.palette.primary.main, 0.1) }}
                    borderRadius={3} p={1} bgcolor={'action.hover'}>
                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                        <AutoFixNormal sx={{ height: 20, width: 20, color: (t) => t.palette.primary.main }} />
                        {userData &&
                            <Typography fontSize={12} >{userData?.tts_words_limit?.toLocaleString()} / {userData.plan ? JSON.parse(userData.plan).tts_words_limit?.toLocaleString() : 0} <a style={{ fontWeight: 'bold' }} >{data.wordsLeft}</a>
                            </Typography>}
                    </Stack>
                </Box>
            </Stack>
            <Box mt={4}>
                <Stack direction={'column'} spacing={2}>
                    <TextField
                        onChange={(e) => setState({ ...state, text: e.target.value })}
                        InputProps={{
                            style: { borderRadius: 10 }
                        }}
                        label={data.enterText} fullWidth size='small' multiline rows={4} />

                    <Stack direction={'row'} spacing={2}>
                        <TextField
                            onChange={(e) => setState({ ...state, title: e.target.value })}
                            InputProps={{
                                style: { borderRadius: 10 }
                            }} label={data.title} fullWidth size='small' />


                        <FormControl size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">{data.languages}</InputLabel>
                            <Select
                                value={selectedLang}
                                onChange={(e) => {
                                    setSelLang(e.target.value)
                                    setSelVoice("")
                                }}
                                sx={{ borderRadius: 2 }}
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={data.languages}
                            >
                                {langs?.map((i, key) => {
                                    return (
                                        <MenuItem key={key} value={i.LanguageName}>{i.LanguageName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <FormControl size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">{data.voice}</InputLabel>
                            <Select
                                value={selVoice}
                                sx={{ borderRadius: 2 }}
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={data.voice}
                                onChange={(e) => setSelVoice(e.target.value)}
                            >
                                {getVoicesByLanguage(selectedLang)?.map((i, key) => {
                                    return (
                                        <MenuItem key={key} value={i}>{i}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Button
                        onClick={genVoice}
                        disabled={state.title && state.text && selVoice && selectedLang ? false : true}
                        startIcon={<RecordVoiceOver />}
                        variant='contained'
                        sx={{ borderRadius: 2, textTransform: 'none' }}>
                        {data.generate}
                    </Button>
                </Stack>
            </Box>

            <Box mt={4} borderRadius={4} bgcolor={'action.hover'} >
                {genArr ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...genArr].reverse()}
                            columns={[
                                {
                                    headerName: data.enterText, field: 'text', flex: 1, renderCell: dataa => {
                                        return (
                                            <Tooltip title={dataa.row.text} >
                                                <a>{dataa.row.text}</a>
                                            </Tooltip>
                                        )
                                    }
                                },
                                { headerName: data.title, field: 'title', flex: 1 },
                                { headerName: data.languages, field: 'language', flex: 1 },
                                { headerName: data.voice, field: 'voice', flex: 1 },
                                {
                                    headerName: data.play, field: 'filename', flex: 1, renderCell: dataa => {
                                        return (
                                            <PlayPause mp3Path={`/speeches/${dataa.row.filename}`} />
                                        )
                                    }
                                },
                                {
                                    headerName: data.download, field: 'download', flex: 1, renderCell: dataa => {
                                        return (
                                            <IconButton onClick={() => window.open(`/speeches/${dataa.row.filename}`)} >
                                                <Downloading />
                                            </IconButton>
                                        )
                                    }
                                },
                                {
                                    headerName: data.date, field: 'date', flex: 1, renderCell: dataa => {
                                        return (
                                            moment(dataa.row.createdAt).fromNow()
                                        )
                                    }
                                },
                                {
                                    headerName: data.del, field: 'del', flex: 1, renderCell: dataa => {
                                        return (
                                            <IconButton onClick={() => delEntry(dataa.row.id)} color='error' >
                                                <Delete />
                                            </IconButton>
                                        )
                                    }
                                },
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>

        </Box>
    )
}

export default TextToSpeech