import React from 'react'

export const CheckOutContext = React.createContext(null)

export const CheckOutProvider = (props) => {
    const [data, setData] = React.useState({
        addDialog: false,
        CheckOutsData: [],
        paymentDone: false
    })

    return (
        <CheckOutContext.Provider value={{ data, setData }}>
            {props.children}
        </CheckOutContext.Provider>
    )
}

