import React, { useEffect, } from 'react'
import { Box, Fade, Grid, Grow, LinearProgress, Stack, Typography } from '@mui/material'
import { BusinessCenterOutlined, Stars } from '@mui/icons-material'
import './font.css'

const Testimonial = ({ data, aalTesti }) => {
    const [checked, setChecked] = React.useState(true);
    const [selected, setSelected] = React.useState(0)
    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setChecked(true)
            setSelected((prevNumber) => (prevNumber + 1) % aalTesti.length);
            setTimeout(() => {
                setChecked(false)
            }, 4500);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (

        <Box pt={4} pb={4}>
            <Grid alignItems={'center'} container spacing={2} justifyContent={'space-between'}>
                <Grid xs={12} sm={6} lg={6} >
                    <Stack ml={2} alignItems={'flex-start'} direction={'column'} spacing={1}>
                        {aalTesti?.map((i, key) => {
                            return (
                                <Box
                                    sx={{
                                        background: selected === key ? 'linear-gradient(rgb(255, 165, 0), rgb(0, 0, 255), rgb(255, 0, 0))' : null,
                                        bgcolor: selected !== key ? 'rgba(147, 147, 147, 0.25)' : null,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setSelected(key)}
                                    key={key} borderRadius={'50%'} p={1} />
                            )
                        })}
                        <Typography variant='h3'  >{data.testiTitle}</Typography>
                    </Stack>
                </Grid>

                <Grid xs={12} sm={6} lg={6} >
                    <Grow in={checked}>
                        <Box mt={2} sx={{ bgcolor: (t) => t.palette.background.default }} p={4} borderRadius={3}>
                            <Stars sx={{ mb: 2, color: (t) => convertHexToRgba(t.palette.primary.main, 1), height: 30, width: 30 }} />
                            <Typography className='grad' variant='h4' >{aalTesti[selected].name},</Typography>
                            <Typography variant='body2' color={'gray'} >{aalTesti[selected].description}</Typography>
                            <Stack mt={2} alignContent={'center'} direction={'row'} spacing={1}>
                                <BusinessCenterOutlined sx={{ color: 'gray', height: 18, width: 18 }} />
                                <Typography color={'gray'} variant='caption'>{aalTesti[selected].position}</Typography>
                            </Stack>
                        </Box>
                    </Grow>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Testimonial