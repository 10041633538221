import React from 'react'
import { WpProvider } from './context/WpContext'
import { TranslateContext } from '../../context/TranslateContext'
import { Box, Typography, Stack, Tabs, Tab } from '@mui/material'
import PropTypes from 'prop-types';
import WpAPI from './components/WpAPI';
import AutoBlog from './components/AutoBlog'
import GetUserToken from '../../utils/GetsUserToken';
import { AutoFixNormal, CropOriginal } from '@mui/icons-material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const WpAutoBlog = () => {
    const { data } = React.useContext(TranslateContext)
    const [value, setValue] = React.useState(0);
    const { userData } = GetUserToken()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    return (
        <WpProvider>
            <Box mt={4} >
                <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                    <Stack direction={'column'}>
                        <Typography variant='h5' fontWeight={'bold'}>{data.autoBloging}</Typography>
                        <Typography variant='caption' color='gray' >{data.autoBlogDes}</Typography>
                    </Stack>

                    <Box
                        sx={{ bgcolor: (t) => convertHexToRgba(t.palette.primary.main, 0.1) }}
                        borderRadius={3} p={1} bgcolor={'action.hover'}>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <AutoFixNormal sx={{ height: 20, width: 20, color: (t) => t.palette.primary.main }} />
                            {userData &&
                                <Typography fontSize={12} >{userData?.gpt_words_limit?.toLocaleString()} / {userData.plan ? JSON.parse(userData.plan).gpt_words_limit?.toLocaleString() : 0} <a style={{ fontWeight: 'bold' }} >{data.wordsLeft}</a> {userData.plan ? JSON.parse(userData.plan).wp_auto_bloging ? null : `| ${data.notAllows}` : null}</Typography>}
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box borderRadius={3} bgcolor={'action.hover'} mt={4}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab sx={{ textTransform: 'none', fontWeight: 'bold' }} label={data.postBlogs} {...a11yProps(0)} />
                        <Tab sx={{ textTransform: 'none', fontWeight: 'bold' }} label={data.wpAPI} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <AutoBlog data={data} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <WpAPI data={data} />
                </TabPanel>
            </Box>
        </WpProvider>
    )
}

export default WpAutoBlog