import React from 'react'
import { Stack, Button, Typography, Box, Tabs, Tab, Grid, Avatar } from '@mui/material'
import { AutoFixNormal, Forum } from '@mui/icons-material'
import PropTypes from 'prop-types';
import { AiChatbotContext } from '../context/AiChatboContext';
import Chatting from './Chatting';
import GetUserToken from '../../../utils/GetsUserToken';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const Header = ({ data, G }) => {
    const CON = React.useContext(AiChatbotContext)
    const [value, setValue] = React.useState(0);
    const [bots, setBots] = React.useState([])
    const [selCate, setSelCate] = React.useState("")
    const { userData } = GetUserToken()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        getCateg()
    }, [])

    async function getCateg() {
        const res = await G.hitAxios({
            path: "/api/chatbot/get_all_category",
            post: false,
            admin: true,
            token_user: ""
        })
        if (res.data.success) {
            CON.setData({ ...CON.data, categoryData: res.data.data, categoryDialog: false })

            if (res.data.data?.length > 0) {
                setSelCate(res.data.data[[res.data.data.length - 1]].name)
                getBotById(res.data.data[[res.data.data.length - 1]].id)
            }
        }
    }

    function getColorCode(text) {
        // Generate a hash code based on the text
        let hash = 0;
        for (let i = 0; i < text.length; i++) {
            hash = text.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Convert the hash code to a positive integer
        hash = Math.abs(hash);

        // Generate the color code by taking a portion of the hash value
        const colorCode = '#' + ('00000' + (hash % 0x1000000).toString(16)).slice(-6);

        return colorCode;
    }

    async function getBotById(e) {
        const res = await G.hitAxios({
            path: "/api/chatbot/get_cahtbot_by_category",
            post: true,
            admin: false,
            obj: { categoryId: e }
        })
        if (res.data.success) {
            setBots(res.data.data)
        }
    }

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    return (
        <Box mt={4}>
            <Stack alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.aiChatBot}</Typography>

                <Box
                    sx={{ bgcolor: (t) => convertHexToRgba(t.palette.primary.main, 0.1) }}
                    borderRadius={3} p={1} bgcolor={'action.hover'}>
                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                        <AutoFixNormal sx={{ height: 20, width: 20, color: (t) => t.palette.primary.main }} />
                        {userData &&
                            <Typography fontSize={12} >{userData?.gpt_words_limit?.toLocaleString()} / {userData.plan ? JSON.parse(userData.plan).gpt_words_limit?.toLocaleString() : 0} <a style={{ fontWeight: 'bold' }} >{data.wordsLeft}</a> {userData.plan ? JSON.parse(userData.plan).chat_in_app ? null : `| ${data.notAllows}` : null}</Typography>}
                    </Stack>
                </Box>
            </Stack>


            <Box borderRadius={4} bgcolor={'action.hover'} mt={4}>
                {CON.data.chatting ?
                    <Chatting selCate={selCate} G={G} data={data} CON={CON} /> :
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                {[...CON.data.categoryData].reverse()?.map((i, key) => {
                                    return (
                                        <Tab
                                            onClick={() => {
                                                getBotById(i.id)
                                                setSelCate(i.name)
                                            }}
                                            key={key} sx={{ textTransform: 'none', fontWeight: 'bold' }} label={<Stack direction={'row'} alignItems={'center'}>
                                                <Typography variant='body2' >{i.name}</Typography>
                                            </Stack>} />
                                    )
                                })}
                            </Tabs>
                        </Box>

                        <Box p={3} >
                            <Grid alignItems={'center'} container spacing={2} >

                                {bots.length > 0 && bots?.map((i, key) => {
                                    return (
                                        <Grid key={key} item xs={12} sm={4} lg={3}>
                                            <Box height={'100%'}>
                                                <Box
                                                    sx={{ borderRadius: 4, p: 1, bgcolor: 'action.hover', height: "100%" }} >
                                                    <Stack alignItems={'center'}>
                                                        <Stack p={4} alignItems={'center'} justifyContent={'center'}>
                                                            <Avatar sx={{ bgcolor: (t) => getColorCode(i.title), height: 50, width: 50 }} >
                                                                {i.title?.slice(0, 1)}
                                                            </Avatar>
                                                        </Stack>
                                                        <Stack direction={'column'} spacing={1} alignItems={'center'}>
                                                            <Typography align='center' >{i.title}</Typography>
                                                            <Button
                                                                onClick={() => {
                                                                    CON.setData({ ...CON.data, chatting: true, chattingBotData: i })
                                                                }}
                                                                endIcon={<Forum />}
                                                                fullWidth
                                                                color='info'
                                                                size='small'
                                                                sx={{ textTransform: 'none', width: "100%" }}
                                                            >{data.startChat}</Button>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>

                    </>}
            </Box>

        </Box>
    )
}

export default Header