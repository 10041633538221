import React from 'react'
import { Box, Typography, Stack, Button, Dialog, IconButton, Divider, TextField, Grid, LinearProgress, CardMedia } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { Add, Close } from '@mui/icons-material'
import { GlobalContext } from '../../context/GlobalContext'

const Features = () => {
    const [dialog, setDialog] = React.useState(false)
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
    })
    const G = React.useContext(GlobalContext)
    const inputProps = { style: { borderRadius: 10 } }
    const [features, setFeatures] = React.useState([])

    async function getAllFeatures() {
        const res = await G.hitAxios({
            path: "/api/admin/get-all-features",
            admin: true,
            post: false
        })
        if (res.data.success) {
            setFeatures(res.data.data)
        }
    }


    async function delFeature(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/admin/del-feature",
                admin: true,
                post: true,
                obj: { id: e }
            })
            console.log(res.data)
            if (res.data.success) {
                getAllFeatures(res.data.data)
            }
        }
    }

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    React.useEffect(() => {
        getAllFeatures()
    }, [])

    async function addFeature(e) {
        e.preventDefault()
        let fd = new FormData()
        fd.append('file', state.file)
        fd.append('title', state.title)
        fd.append('des', state.des)

        const res = await G.hitAxios({
            path: "/api/admin/add-features",
            admin: true,
            post: true,
            obj: fd
        })
        if (res.data.success) {
            setDialog(false)
        }
    }

    return (
        <Box mt={4}>
            <Stack mb={5} alignItems='center' spacing={2} direction={'row'} justifyContent='space-between'>
                <Typography variant='h5' fontWeight={'bold'}>{data.features}</Typography>
                <Button
                    onClick={() => setDialog(true)}
                    startIcon={<Add />}
                    sx={{ textTransform: "none", borderRadius: 2 }} variant='contained' >{data.addNew}</Button>
            </Stack>

            <Dialog
                fullWidth
                onClose={(e) => setDialog(false)}
                open={dialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <form key={1} encType='formdata/multi-part' onSubmit={addFeature} >
                    <Box p={2}  >
                        <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                            <IconButton onClick={() => setDialog(false)} >
                                <Close />
                            </IconButton>
                            <Typography>{data.addNew}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction={'column'} spacing={2} mt={2}>
                            <Stack direction={'row'} spacing={2}>
                                <TextField
                                    onChange={(e) => setState({ ...state, title: e.target.value })}
                                    size='small'
                                    label={data.name} fullWidth InputProps={inputProps} />

                                <Button
                                    component="label"
                                    color={state.file && 'success'}
                                    size='small' variant='outlined' sx={{ borderRadius: 2, textTransform: 'none' }}>
                                    <input
                                        hidden
                                        type="file"
                                        id="fileInput"
                                        onChange={(e) => setState({ ...state, file: e.target.files[0] })}
                                        accept=".png, .jpeg, .jpg" />

                                    {state.file ? data.selected : data.image}</Button>
                            </Stack>

                            <TextField
                                multiline
                                rows={3}
                                onChange={(e) => setState({ ...state, des: e.target.value })}
                                size='small'
                                label={data.description} fullWidth InputProps={inputProps} />

                            <Button
                                type='submit'
                                disabled={state.file && state.title && state.des ? false : true}
                                variant='contained'
                                sx={{ borderRadius: 2, textTransform: 'none' }}
                                fullWidth >{data.submit}</Button>
                        </Stack>

                    </Box>
                </form>
            </Dialog >

            <Grid container spacing={2} sx={{ display: 'flex' }}>
                {features ? (
                    features?.map((i, key) => {
                        return (
                            <Grid key={key} item xs={12} md={6} lg={4} >
                                <Box
                                    sx={{
                                        bgcolor: (e) => convertHexToRgba(e.palette.primary.main, 0.03),
                                        height: '100%', // Ensure each item takes up full height
                                    }}
                                    borderRadius={4}
                                >
                                    <Stack height={'100%'} justifyContent={'space-between'} p={4} direction={'column'} spacing={2}>
                                        <CardMedia
                                            src={`/images/${i.image}`}
                                            component={'img'}
                                            sx={{ height: 50, width: 50 }}
                                        />
                                        <Typography
                                            sx={{ color: (e) => e.palette.primary.main }}
                                            variant='h6'
                                        >
                                            {i.title}
                                        </Typography>
                                        <Typography variant='caption' >
                                            {i.des}
                                        </Typography>

                                        <Button
                                            onClick={() => delFeature(i.id)}
                                            size='small' sx={{ textTransform: 'none', borderRadius: 2 }} color='error' variant='outlined'>{data.del}</Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        );
                    })
                ) : (
                    <LinearProgress />
                )}
            </Grid>

        </Box >
    )
}

export default Features