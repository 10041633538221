import { Box, Button, Stack, TextField } from '@mui/material'
import { GlobalContext } from '../../../context/GlobalContext'
import React from 'react'

const WpAPI = ({ data }) => {
    const inputProps = { style: { borderRadius: 10 } }
    const G = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [user, setUser] = React.useState("")

    async function getUser() {
        const res = await G.hitAxios({
            path: "/api/user/get-user-by-token",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.success) {
            setUser(res.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) return
        getUser()
    }, [token])

    async function handleSave() {
        const res = await G.hitAxios({
            path: "/api/wp/update_wp_token",
            admin: false,
            post: true,
            obj: user
        })
    }

    return (
        user &&
        <Stack direction={'column'} spacing={2}>
            <TextField
                onChange={(e) => setUser({ ...user, wp_domain: e.target.value })}
                value={user.wp_domain}
                label={data.wpDomain} helperText={data.wpDomainHelper} fullWidth size='small' InputProps={inputProps} />
            <TextField
                onChange={(e) => setUser({ ...user, wp_email: e.target.value })}
                value={user.wp_email}
                label={data.wpEmail} fullWidth size='small' InputProps={inputProps} />
            <TextField
                onChange={(e) => setUser({ ...user, wp_token: e.target.value })}
                value={user.wp_token}
                label={data.wpToken} fullWidth size='small' InputProps={inputProps} />
            <Button
                onClick={handleSave}
                disabled={user.wp_token && user.wp_domain ? false : true}
                variant='contained'
                sx={{ textTransform: 'none', borderRadius: 2 }}
            >{data.submit}</Button>
        </Stack>
    )
}

export default WpAPI