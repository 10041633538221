import React from 'react'
import { Box, Typography, Stack, Button } from '@mui/material'
import { AiBotProvider } from './contetxt/AiBotContext'
import { TranslateContext } from '../../context/TranslateContext'
import Header from './components/Header'
import AddDialog from './contetxt/AddDialog'
import BotList from './components/BotList'

const AiBot = () => {
    const { data } = React.useContext(TranslateContext)
    return (
        <AiBotProvider>
            <Header data={data} />
            <AddDialog data={data} />

            <Box mt={4}>
                <BotList data={data} />
            </Box>
        </AiBotProvider>
    )
}

export default AiBot