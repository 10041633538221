import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { CheckOutContext } from '../context/CheckOutContext'
import { CardActionArea, CardMedia, Box } from '@mui/material'



const PayWithRz = ({ data, paymentGateways, plan, web }) => {
    const G = React.useContext(GlobalContext)
    const CON = React.useContext(CheckOutContext)

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src

            script.onload = () => {
                resolve(true)
            }

            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    const payByRz = async (amt) => {
        const resp = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        console.log(resp)
        if (!resp) {
            alert('Internet problem!')
            return
        }
        const finalamt = parseInt(plan.cost) / parseInt(web.exchange_rate) * 80

        const options = {
            key: paymentGateways?.payment_id,
            currency: "INR",
            amount: Math.round(parseInt(finalamt)) * 100,
            name: web.app_name,
            description: "100% secured payment",

            handler: async function (response) {
                const res = await G.hitAxios({
                    path: "/api/plan/pay-with-razorpay",
                    obj: {
                        plan: plan,
                        rz_payment_id: response.razorpay_payment_id,
                        amount: plan.cost
                    },
                    admin: false,
                    post: true
                })
                if (res.data.success) {
                    CON.setData({ ...CON.data, paymentDone: true })
                }
                // console.log(res.data)
            }

        }
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()

    }

    return (
        paymentGateways?.payment_id &&
        <CardActionArea onClick={payByRz}>
            <Box bgcolor={'white'} border={1} borderRadius={1.2} p={1.5} pr={3.3} pl={3.3}>
                <CardMedia
                    height={20}
                    src='/assets/Razorpay_logo.svg' component={'img'} />
            </Box>
        </CardActionArea>
    )
}

export default PayWithRz