import React from 'react'
import axios from 'axios'

const GetUserToken = () => {
    const [userData, setuserData] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    async function getData() {
        const plans = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-by-token`, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
        setuserData(plans.data.data)
    }
    React.useEffect(() => {
        if (!token) return
        getData()
    }, [token])
    return { userData }
}

export default GetUserToken