import React, { useState, useEffect } from 'react';
import './AudioPlayer.css'; // Import the CSS file for styling
import { PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const PlayPause = ({ mp3Path }) => {
    const [audio, setAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setAudio(new Audio(mp3Path));
        setIsLoading(false);
    }, [mp3Path]);

    const handlePlayPause = () => {
        if (isLoading) {
            return; // Do nothing if still loading
        }

        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(prevState => !prevState);
    };

    useEffect(() => {
        if (audio) {
            audio.addEventListener('ended', () => {
                setIsPlaying(false);
            });
        }

        return () => {
            if (audio) {
                audio.removeEventListener('ended', () => {
                    setIsPlaying(false);
                });
            }
        };
    }, [audio]);

    const renderButton = () => {
        if (isLoading) {
            return <span className="loading-indicator">Loading...</span>;
        }

        return (
            <IconButton onClick={handlePlayPause}>
                {isPlaying ? <PauseCircleOutline /> : <PlayCircleOutline />}
            </IconButton>
        );
    };

    return (
        <div className="audio-player">
            <audio src={mp3Path} />
            {renderButton()}
        </div>
    );
};

export default PlayPause;
